import { wrappedGet, wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const getAllusersapi = (params) => {
    return wrappedGet(Url.GET_ALL_USERS_URL, "GET", params);
};
export const GetUserById = (id) => {
    return wrappedGet(Url.GET_USER_BY_ID + id, "GET");
};

export const Edituserbyid = (data, userid) => {
    return wrappedFetch(Url.EDIT_USER_BY_ID + userid, "PATCH", data);
}

export const Getuserbyemailandusername = (useremail, username) => {
    let url = Url.SEARCH_USERBY_EMAIL_USERNAME
    if (useremail) {
        url = Url.SEARCH_USERBY_EMAIL_USERNAME + "email=" + useremail
    }
    if (username) {
        url = Url.SEARCH_USERBY_EMAIL_USERNAME + "userName=" + username
    }
    if (useremail && username) {
        url = Url.SEARCH_USERBY_EMAIL_USERNAME + "email=" + useremail + "&userName=" + username
    }
    return wrappedFetch(url, "GET")
}

export const Searchuserbystatus = (status) => {
    return wrappedGet(Url.SEARCH_USERBY_STATUS + "isActive=" + status, "GET")
};
