import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { Getallcardsbyroom } from '../../../Api/api_calls/adminroomApis';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { useDispatch } from "react-redux";
import { errorHandleService } from "../../../common";
import LogoutEvn from "../../../common/LogoutEvn";
import Divider from '@mui/material/Divider';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

export default function TableRoomDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const [Allcardslist, setallcardslist] = useState([]);


    // Get All cards by room api method
    function Getallcardsbyroommethod() {
        dispatch(loaderStatus(true));
        Getallcardsbyroom().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setallcardslist(res?.data?.data.reverse())
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }
    useEffect(() => {
        Getallcardsbyroommethod();
    }, []);


    return (
        <Grid container>
            <Grid xs={8}>
                <span className='mainHeading themeColorText'>ROOM ACTIVITY&nbsp;&nbsp;</span>
            </Grid>
            <Grid xs={12}><Box pt={1} pb={1}><Divider /></Box></Grid>
            <Box pt={3} />
            <TableContainer component={Paper} className="tableHightRoom">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="tableHeading">Rooms</TableCell>
                            <TableCell align="center" className="tableHeading">Total cards</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Allcardslist && Allcardslist?.map((row) => (
                            <TableRow
                                key={row}>
                                <TableCell>
                                    <span className="tableText">{row?.title.split("v-s")[0]}&nbsp;<span className="title-vs">VS</span>&nbsp;{row?.title.split("v-s")[1]}</span>
                                </TableCell>
                                <TableCell align="center" className="tableText">{row.userCardsCount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    Allcardslist.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No cards and Rooms Available</Box></Grid> : ''
                }
            </TableContainer>
        </Grid>
    );
}
