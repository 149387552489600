import { USER_DETAILS_STORE } from "../Actions/ActionTypes";

  const initialState = {
    email: '',
    userName: '',
    phone: '',
    name: '',
    imgUrl: ''
  };

  const userReducer = (state = initialState, action) => {
    switch (action.type) {  
      case USER_DETAILS_STORE:
        return {
        email: action.payload.email,
        userName: action.payload.userName,
        phone: action.payload.phone,
        name: action.payload.name,
        imgUrl: action.payload.imgUrl
        };
      default:
        return state;
    }
  };
  export default userReducer;
  