import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";

export const updateProfileMethod = (data) => {
    return wrappedFetch(Url.UPDATE_PROFILE_URL, "POST", data);
  };

export const getUserProfile = (userId) => {
    return wrappedGet(Url.GET_PROFILE_URL + userId, "GET");
};

export const uploadImageProfile = (data) => {
    return wrappedFetch(Url.UPLOAD_PROFILE_IMAGE_URL, "PATCH", data);
}

export const changePasswordApi = (data) => {
    return wrappedFetch(Url.CHANGE_PASSWORD_URL, "POST", data);
}

export const changeVenmoApi = (data) => {
    return wrappedFetch(Url.VENMO_CHANGE_URL, "POST", data);
}

// user soft delete api
export const DeleteUser = (Userid) => {
    return wrappedFetch(Url.DELETE_USER_URL + Userid, "DELETE")
}


export const DeleteUserimage = (userId) => {
    return wrappedFetch(Url.DELETE_USER_IMAGE + userId, "DELETE")
}