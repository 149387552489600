// ----------------------------For Users Route-------------------------------------------
import Account from "../module/User/Account/account";
import ChooseGameRoom from "../module/User/Choosegameroom/choosegameroom";
import Userhistory from "../module/User/userhistory/userhistory";
import Cardsinplay from "../module/User/cardsinplay/cardsinplay";
import ProfilePage from "../module/User/ProfilePage/profile";
import CardCreate from "../module/User/createcard/CardCreate";
import Winner from "../module/User/Winner/winner";


import { ROLE } from "../constants/constants";

//------------------------ For admin routes ----------------------------------------------
import Landing from "../module/Admin/Landing/landing";
import AllplayerHoc from "../module/Admin/Player/AllplayerHoc";
import RoomHoc from "../module/Admin/Room/roomHoc";
import AdminProfileHoc from "../module/Admin/AdminProfile/AdminProfileHoc";
import HocEditTable from "../module/Admin/Player/HocEditTable";
import RoomManagmentHoc from "../module/Admin/RoomManagment/roommanagmentHoc";
import AllusersHoc from "../module/Admin/AllUsers/allUsersHoc";
import GamePlayHoc from "../module/Admin/GamePlay/GamePlayHoc";
import VenmomanagmentHoc from "../module/Admin/venmomanagment/venmomanagmentHoc";
import PrivacyPolicyHoc from "../module/Admin/PrivacyPolicy/privacypolicyHoc";
import TermandconditionHoc from "../module/Admin/TermandCondition/termandconditionHoc";
import UserhistoryadminHoc from "../module/Admin/userhistoryadmin/userhistoryadminHoc";
import Userqueryhoc from "../module/Admin/Userquery/userqueryHoc";
import Howtoplay from "../module/User/Howtoplay/howtoplay";

const allRoutesPath = [
    // ---------------------------------User Paths Starts From Here------------------------

    {
        exact: true,
        path: "/account",
        component: Account,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/user_profile",
        component: ProfilePage,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/chooseroom",
        component: ChooseGameRoom,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/userhistory",
        component: Userhistory,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/cards_play/:roomId/:userId",
        component: Cardsinplay,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/winner/:roomId/:userId",
        component: Winner,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/cardcreate",
        component: CardCreate,
        roles: [ROLE[0]]
    },
    {
        exact: true,
        path: "/howtoplay",
        component: Howtoplay,
        roles: [ROLE[0]]
    },
    // ---------------------------------User Paths Ends Here--------------------------------
    // ---------------------------------Admin Paths Starts From Here------------------------
    {
        exact: true,
        path: "/admin_landing",
        component: Landing,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/all_props",
        component: AllplayerHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/all_rooms",
        component: RoomHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/admin_profile",
        component: AdminProfileHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/edit_table",
        component: HocEditTable,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/room_managment",
        component: RoomManagmentHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/all_users",
        component: AllusersHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/adminPlay",
        component: GamePlayHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/manage_venmo",
        component: VenmomanagmentHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/privacy_policy",
        component: PrivacyPolicyHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/terms_conditions",
        component: TermandconditionHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/all_users/userhistory",
        component: UserhistoryadminHoc,
        roles: [ROLE[1]]
    },
    {
        exact: true,
        path: "/userquery",
        component: Userqueryhoc,
        roles: [ROLE[1]]
    }
    // ---------------------------------Admin Paths Ends Here-------------------------------

];

export default allRoutesPath;