import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import LogoutEvn from '../../../common/LogoutEvn';
import { errorHandleService } from '../../../common';
import { Doughnut } from 'react-chartjs-2';
import { getUserDetails } from '../../../Api/api_calls/dashboard';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    plugins: {
        legend: false
    }
};


export default function BarChart() {
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const [threeMonthData, set3MonthData] = useState([])
    const [sixMonthData, set6MonthData] = useState([])
    const [wtxMonthData, set12MonthData] = useState([])
    const [lableD, setLableD] = useState([])
    const [dataD, setDataD] = useState([])
    const [total, setTotalCount] = useState(0)
    const [name, setName] = useState("3months");

    const selectButton = (data) => {
        setName(data)
        let setDataInfo=[]
        if (data === "3months") {
            setDataInfo=threeMonthData
        }
        if (data === "6months") {
            setDataInfo=sixMonthData
        }
        if (data === "12months") {
            setDataInfo=wtxMonthData
        }
        let allData = setDataInfo
        let letLable = [];
        let letData = []
        let totalNumber = 0;
        allData?.map(obj => {
            totalNumber = totalNumber + obj.count;
            letLable.push(obj?.dateDMY)
            letData.push(obj?.count)
        })
        setDataD(letData)
        setLableD(letLable)
        setTotalCount(totalNumber)
    }

    useEffect(() => {
        getMonthWiseUsers();
    }, []);

    function getMonthWiseUsers() {
        dispatch(loaderStatus(true));
        getUserDetails().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if (res?.data?.last3monthsInfo.length > 0) {
                    set3MonthData(res.data.last3monthsInfo)
                    set6MonthData(res.data.last6monthsInfo)
                    set12MonthData(res.data.last12monthsInfo)

                    let allData = res.data.last3monthsInfo
                    let letLable = [];
                    let letData = []
                    let totalNumber = 0;
                    allData?.map(obj => {
                        totalNumber = totalNumber + obj.count;
                        letLable.push(obj?.dateDMY)
                        letData.push(obj?.count)
                    })
                    setDataD(letData)
                    setLableD(letLable)
                    setTotalCount(totalNumber)
                } else {
                    set3MonthData([])
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const data = {
        labels: lableD,
        datasets: [
            {
                data: dataD,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <>
            <div className='header textCenter'>
                <ButtonGroup size="small" className='buttonTheme mb-2'>
                    <Button onClick={() => selectButton("3months")} className={name === "3months" ? 'selectedType' : 'buttonTheme'}>Last 3 months</Button>
                    <Button onClick={() => selectButton("6months")} className={name === "6months" ? 'selectedType' : 'buttonTheme'}>Last 6 months</Button>
                    <Button onClick={() => selectButton("12months")} className={name === "12months" ? 'selectedType' : 'buttonTheme'}>Last 1 year</Button>
                </ButtonGroup>
                <h1 className='mainHeading textCenter'>Users Count&nbsp;&nbsp;{total}</h1>
            </div>
            <div>
                <Doughnut data={data} options={options} />
            </div>
        </>
    )
}
