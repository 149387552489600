import React from 'react'
import io from "socket.io-client";
import { SOCKET_URL } from '../Api/config/index'
let socket;

export const SocketContext = React.createContext();

export const initiateSocket = (room) => {
  socket = io(SOCKET_URL);
  if (socket && room) socket.emit('join_room', room);
}

export const matchUserCardSocket = (cb) => {
  if (!socket) {
    return (true);
  }
  socket.on('matchUserCard', data => {
    return cb(null, data);
  });
}

export const adminCardSend = async (newProps,cb) => {
  if (socket) await socket.emit('adminCard', newProps,data => {
    return cb(null, data);
  });
}

export const matchAdminCardSocket = (cb) => {
  if (!socket) return (true);
  socket.on('matchCard', data => {
    return cb(null, data);
  });
}

// To send detials for leader board-
export const leaderBoardDataEmit = async (leaderBoardInfo) => {
  if (socket) await socket.emit('manageBingo', leaderBoardInfo);
}

// To get details of user leaderboard
export const userLeaderBoardInfo = (cb) => {
  if (!socket){ 
    return (true)}
  socket.on('leaderBoardUser', data => {
    return cb(null, data);
  });
}

// To get details of comman leader board
export const commanLeaderBoardInfo = (cb) => {
  if (!socket) return (true);
  socket.on('generalLeaderBoard', data => {
    return cb(null, data);
  });
}

// To start the game -
export const gameStartDataEmit = async (roomId) => {
  if (socket) await socket.emit('startGame', roomId);
}


// To over the game -
export const gameOverDataEmit = async (roomId) => {
  if (socket) await socket.emit('gameOver', roomId);
}


// To get details of winner
export const winnerInfoSocket = (cb) => {
  if (!socket) return (true);
  socket.on('winnerInfo', data => {
    return cb(null, data);
  });
}

// To get details of room start 
export const roomStartInfoSocket = (cb) => {
  if (!socket) return (true);
  socket.on('gameStart', data => {
    return cb(null, data);
  });
}

export const revertUserCardUser = (cb) => {
  if (!socket) {
    return (true);
  }
  socket.on('revertUserCard', data => {
    return cb(null, data);
  });
}