import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { withRouter } from 'react-router-dom'
// --------- Table code start ---------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dateFormat from 'dateformat';
import LogoutEvn from '../../../common/LogoutEvn';
import { errorHandleService } from '../../../common';
import StreetviewIcon from '@mui/icons-material/Streetview';
import VisibilityIcon from '@mui/icons-material/Visibility';

// time and date -
import { getAllroomApi } from '../../../Api/api_calls/adminroomApis';
const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

function RoomManagment(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()

    // state ,manage for get all room list
    const [Roomlist, setRoomList] = useState([]);


    // rendering for manage room list
    useEffect(() => {
        getAllroomMethod()
        // eslint-disable-next-line
    }, [])


    // Get All room api
    function getAllroomMethod() {
        dispatch(loaderStatus(true));
        getAllroomApi().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setRoomList(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // redirect to playscreen --
    const playGame = (rowdata) => {
        let roomIdGet = rowdata._id
        props.history.push("/adminPlay?id=" + roomIdGet)
    }

    const buttonStatus = (row) => {
        let careatedDate = new Date(row?.startDateTime)
        let getDate = new Date()

        if (careatedDate < getDate) {
            if (row?.isOver) {
                return (
                    <Button variant="contained" className="buttonFaddedTheme buttonFontTheme" size='small' endIcon={<VisibilityIcon />} onClick={() => playGame(row)}>Game Over
                    </Button>
                )
            }else if(row?.isStart == true){
                return (
                    <Button variant="contained" className="buttonTheme buttonFontTheme" size='small' endIcon={<StreetviewIcon />} onClick={() => playGame(row)}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Rejoin&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                )
            }else{
                return (
                    <Button variant="contained" className="buttonFaddedTheme  buttonFontTheme" size='small' onClick={() => playGame(row)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Not Used&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                )
            }
        }
        else {
            if (row?.isOver) {
                return (
                    <Button variant="contained" className="buttonFaddedTheme buttonFontTheme" size='small' endIcon={<VisibilityIcon />} onClick={() => playGame(row)}>Game Over
                    </Button>
                )
            }
            else if(row?.isStart) {
                return (
                    <Button variant="contained" className="buttonTheme buttonFontTheme" size='small' endIcon={<StreetviewIcon />} onClick={() => playGame(row)}>
                      &nbsp;&nbsp;&nbsp;&nbsp;Rejoin&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                )
            }
            else{
                return (
                    <Button variant="contained" className="buttonTheme buttonFontTheme" size='small' endIcon={<StreetviewIcon />} onClick={() => playGame(row)}>
                        Join-Room
                    </Button>
                )
            }
        }
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={9}>
                    <Box component="div" style={{ fontWeight: 700 }} display="inline" mt={3} className="mainHeading" size="small">Room Managment</Box>
                </Grid>
                <Grid item xs={12} className="setPadding "><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Title</TableCell>
                                    {/* <TableCell align="center">Points</TableCell> */}
                                    <TableCell align="center" className="tableHeading">Type</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Date</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Time</TableCell>
                                    <TableCell align="center" className="tableHeading">Entry Fee</TableCell>
                                    <TableCell align="center" className="tableHeading">Room Capacity</TableCell>
                                    <TableCell align="center" className="tableHeading">Room Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Roomlist && Roomlist?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {row?.title.split("v-s")[0]}&nbsp;<span className="title-vs">VS</span>&nbsp;{row?.title.split("v-s")[1]}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.matchType}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row?.startDateTime, "mmmm dS, yyyy")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row?.startDateTime, "h:MM TT")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            $&nbsp;{row?.entryFee}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.maxPlayer}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {
                                                buttonStatus(row)
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            Roomlist.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Rooms Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(RoomManagment)
