import React, { useState, useEffect } from "react";
// Table code start ---------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import LogoutEvn from "../../../common/LogoutEvn";
import { loaderStatus } from "../../../redux/Actions/LoaderAction";
import { errorHandleService, notifyService } from "../../../common";
import { Editofferbalance, Getfreebalance } from "../../../Api/api_calls/dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));


function FreeOffer(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { control } = useForm();
    const logoutEvents = LogoutEvn()
    // state for free balance data store
    const [freebalanceoffer, setfreebalanceoffer] = useState([]);
    // states for update free balance
    const [offerid, setofferid] = useState('')
    const [statusactive, setstatusactive] = useState('')
    const [userofferbalance, setuserofferbalance] = useState('')
    // state for open free balance popup
    const [open, setopen] = React.useState(false);

    const handleClosedisagree = () => {
        setopen(false);
    };

    // function for update free balance offer 
    function addofferbalance() {
        let Offerbalance = {}
        Offerbalance['balance'] = parseInt(userofferbalance)
        Offerbalance['_id'] = offerid
        Offerbalance['isActive'] = statusactive

        if (!Offerbalance.balance) {
            notifyService.info('Please enter offer balance')
            return
        }

        dispatch(loaderStatus(true));
        Editofferbalance(Offerbalance).then(res => {
            dispatch(loaderStatus(false));
            if (res.status === 200) {
                notifyService.success('Offer updated successfully')
                handleClosedisagree()
                Getfreebalanceoffer()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    // Get free balance offer api hit method
    function Getfreebalanceoffer() {
        dispatch(loaderStatus(true));
        Getfreebalance().then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                setfreebalanceoffer(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    // handle for geeting free offer details
    const offerdetailsbyid = (offerdetails) => {
        setuserofferbalance(offerdetails?.balance)
        setofferid(offerdetails?._id)
        let status = (offerdetails?.isActive)
        setstatusactive(status)
        setopen(true);
    }
    const handleChange = (event) => {
        setstatusactive(event.target.value);
    };

    // for get free balance offer
    useEffect(() => {
        Getfreebalanceoffer();
    }, []);

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableHeading">Balance</TableCell>
                                <TableCell align="center" className="tableHeading">Status</TableCell>
                                <TableCell align="center" className="tableHeading">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {freebalanceoffer && freebalanceoffer?.map((row) => (
                                <TableRow key={row}>
                                    <TableCell component="th" scope="row" className="tableText">
                                        $&nbsp;{row?.balance}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        {(() => {
                                            if (row?.isActive === true) {
                                                return (
                                                    <Box style={{ color: 'green' }}>Active</Box>
                                                )
                                            } else {
                                                return (
                                                    <Box style={{ color: 'red' }}>Inactive</Box>
                                                )
                                            }
                                        })()}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        <EditIcon fontSize="small" onClick={() => offerdetailsbyid(row)} />Edit
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {
                        freebalanceoffer.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Offers Avilable</Box></Grid> : ''
                    }
                </TableContainer>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClosedisagree}
                keepMounted
                className="custom-add"
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <Controller
                        name="city"
                        control={control}
                        render={() => (
                            <>
                                <label className={classes.inputLabel} className="normalTextTheme">Balance*</label>
                                <TextField
                                    margin="dense"
                                    type="number"
                                    label="Balance"
                                    fullWidth
                                    variant="outlined"
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    size="small"
                                    value={userofferbalance}
                                    onChange={(e) => setuserofferbalance(e.target.value)}
                                />
                                 <label className={classes.inputLabel} className="normalTextTheme">Status</label>
                                <Box pt={2}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true} size="small">
                                        <InputLabel id="demo-simple-select-outlined-label" className="normalTextTheme">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={statusactive}
                                            onChange={handleChange}
                                            label="Status"
                                        >
                                            <MenuItem value={true}>Active</MenuItem>
                                            <MenuItem value={false}>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosedisagree} className='modalCommonButton'> cancel </Button>
                    <Button autoFocus onClick={addofferbalance} className='modalCommonButton'>Update</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default FreeOffer;
