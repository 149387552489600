import jwtDecode from "jwt-decode";

const initialState = {
  bingotoken: localStorage.getItem("bingotoken"),
  role: null,
  email: null,
  userName: null,
  _id: null,
  phone: null,
  venmoBalance:null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN":
    case "USER_LOADED":
      const user = jwtDecode(action?.allInfo?.token);
      return {
        ...initialState,
        bingotoken: action?.allInfo?.token,
        role: user?.role,
        email: user?.email,
        userName: user?.userName,
        _id: user?._id,
        venmoBalance: user?.venmoBalance
      };
    case "SIGN_OUT":
      localStorage.removeItem("bingotoken");
      return {
        bingotoken: null,
        role: null,
        email: null,
        userName: null,
        _id: null,
        phone: null,
        venmoBalance:null
      };
    default:
      return state;
  }
};

export default authReducer;

