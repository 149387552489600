import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import TableRoomDetails from './Table';
import Today from './today';
import Week from './week';
import Month from './month';
import CommingSoon from '../../../common/CommingSoon';


export default function UserTable(props) {
    const [info, setInfoUser] = useState(false);
    const [name, setName] = useState("Today");

    const selectButton = (data) => {
        setInfoUser(true)
        setName(data)
    }
  

    return (
        <Grid container>
            <Grid xs={8}>
                <span className='mainHeading themeColorText'>USERS ACTIVITY&nbsp;&nbsp;</span>
            </Grid>
            <Grid xs={4} className='textEnd'>
                <ButtonGroup size="small" className='buttonTheme'>
                    <Button onClick={() => selectButton("Today")}  className={name === "Today"? 'selectedType':'buttonTheme'}>Last 24 hours</Button>
                    <Button onClick={() => selectButton("Week")} className={name === "Week"? 'selectedType':'buttonTheme'}>Week</Button>
                    <Button onClick={() => selectButton("Month")} className={name === "Month"? 'selectedType':'buttonTheme'}>Month</Button>
                </ButtonGroup>
            </Grid>
            <Grid xs={12}><Box pt={1} pb={1}><Divider /></Box></Grid>
            <Grid xs={12}>
                <br />
                {
                    (() => {
                        if (name === "Today") {
                            return <Today />
                        } else if (name === "Week") {
                            return <Week />
                        }
                        else if (name === "Month") {
                            return <Month />
                        }
                        else {
                            return <Today />
                        }
                    })()
                }
            </Grid>
        </Grid>
    )
}