import React, { useEffect, useState, } from 'react'
import '../../../../index.css'
import { useDispatch, useSelector } from 'react-redux';
import { errorHandleService } from '../../../common';
import stampchamp from '../../../../Assets/images/stampchamp.png'
import Navbar from '../../../common/headers/Navbar'
import { useParams } from 'react-router-dom'
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { getRoomAndPropsOfUserMethod } from '../../../Api/api_calls/cardApis';
import ball from '../../../../Assets/images/ball.png'
import LogoutEvn from '../../../common/LogoutEvn';
import './card.css'
import Leaderboard from './Leaderboard';
import dateFormat from 'dateformat';
import { balanceAction } from '../../../redux/Actions/BalanceAction';
import { getUserProfile } from '../../../Api/api_calls/profileApi';

export default function Winner(props) {
    const dispatch = useDispatch();
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);
    const userIdNew = useSelector((state) => state?.Auth?._id);
    const { roomId, userId } = useParams();
    const logoutEvents = LogoutEvn()
    const [userCardId, setUserCardId] = useState(null)
    const [storeRoom, setStoreRoomDetails] = useState({})
    const [roomInfo, setRoomInfo] = useState({})

    // Code for start box 
    // row 1
    const [r1c1, set_r1c1] = useState({})
    const [r1c2, set_r1c2] = useState({})
    const [r1c3, set_r1c3] = useState({})
    const [r1c4, set_r1c4] = useState({})
    const [r1c5, set_r1c5] = useState({})
    // row 2
    const [r2c1, set_r2c1] = useState({})
    const [r2c2, set_r2c2] = useState({})
    const [r2c3, set_r2c3] = useState({})
    const [r2c4, set_r2c4] = useState({})
    const [r2c5, set_r2c5] = useState({})
    // row 3
    const [r3c1, set_r3c1] = useState({})
    const [r3c2, set_r3c2] = useState({})
    const [r3c3, set_r3c3] = useState({})
    const [r3c4, set_r3c4] = useState({})
    const [r3c5, set_r3c5] = useState({})
    // row 4
    const [r4c1, set_r4c1] = useState({})
    const [r4c2, set_r4c2] = useState({})
    const [r4c3, set_r4c3] = useState({})
    const [r4c4, set_r4c4] = useState({})
    const [r4c5, set_r4c5] = useState({})
    // row 5
    const [r5c1, set_r5c1] = useState({})
    const [r5c2, set_r5c2] = useState({})
    const [r5c3, set_r5c3] = useState({})
    const [r5c4, set_r5c4] = useState({})
    const [r5c5, set_r5c5] = useState({})
    // Is Combination state -

    useEffect(() => {
        getRoomAndPropsOfUser(userIdNew, roomId)
    }, [])

    // useEffect(() => {
    //     setInterval(async () => {
    //         let pathName = window.location.pathname.split('/')[1]
    //         if (pathName && pathName == "winner") {
    //             window.location.reload();
    //         }
    //     }, 7000);
    // }, []);

    // useEffect(() => {
    //     window.location.reload();
    //     // window.location.reload();
    // }, [])

    // get user created card info for room ----
    function getRoomAndPropsOfUser(userIdGet, roomIdGet) {
        // dispatch(loaderStatus(true));
        getRoomAndPropsOfUserMethod(userIdGet, roomIdGet).then(res => {
            // dispatch(loaderStatus(false));
            if (res?.status == 200) {
                let dataGet = res?.data?.data[0]?.cardDetails
                let cardInfo = res?.data?.data[0]
                setRoomInfo(cardInfo)
                let storeCardInfo = {}
                storeCardInfo['cardDetails'] = cardInfo?.cardDetails
                storeCardInfo['roomId'] = cardInfo?.roomId
                setUserCardId(cardInfo?._id)
                setPlayerInCard(dataGet)
                setStoreRoomDetails(cardInfo?.roomId)
                getUserDetails();
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get user details -
    function getUserDetails() {
        let userIdSend = userId
        // dispatch(loaderStatus(true));
        getUserProfile(userIdSend).then(res => {
            // dispatch(loaderStatus(false));
            let venmoBalance = res.data?.data[0]?.venmoBalance;
            let reduxObject = {}
            if (venmoBalance) {
                reduxObject['venmoBalance'] = venmoBalance
            } else {
                reduxObject['venmoBalance'] = 0
            }
            dispatch(balanceAction(reduxObject))
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    const setPlayerInCard = (dataGet) => {
        set_r1c1(dataGet[0])
        set_r1c2(dataGet[1])
        set_r1c3(dataGet[2])
        set_r1c4(dataGet[3])
        set_r1c5(dataGet[4])
        // row 2
        set_r2c1(dataGet[5])
        set_r2c2(dataGet[6])
        set_r2c3(dataGet[7])
        set_r2c4(dataGet[8])
        set_r2c5(dataGet[9])
        // row 3
        set_r3c1(dataGet[10])
        set_r3c2(dataGet[11])
        set_r3c4(dataGet[12])
        set_r3c5(dataGet[13])
        // row 4
        set_r4c1(dataGet[14])
        set_r4c2(dataGet[15])
        set_r4c3(dataGet[16])
        set_r4c4(dataGet[17])
        set_r4c5(dataGet[18])
        // row 5
        set_r5c1(dataGet[19])
        set_r5c2(dataGet[20])
        set_r5c3(dataGet[21])
        set_r5c4(dataGet[22])
        set_r5c5(dataGet[23])
    }
    // Style code -

    // handle for return to home page 
    const homepage = () => {
        props.history.push("/account")
    }

    // check condition card name is bigger or not
    const checkingTooltipText = (propsName) => {
        if (propsName?.length > 12) {
            return <span class="tooltiptext">{propsName}</span>
        } else {
            return ''
        }
    }


    // check condition card type is bigger or not
    const checkingTooltipTexttype = (propstype) => {
        if (propstype.length > 9) {
            return <span class="tooltiptext">{propstype}</span>
        } else {
            return ''
        }
    }

    return (
        <div class="accounts">
            <Navbar />
            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <img class="img-fluid" onClick={homepage} src={ball} /> WINNER
                            </a>
                        </div>
                        <div class="col">
                            <ul class="accounts-ul">
                                <li class="balance">Balance <span>$&nbsp;{userbalance.toFixed(2)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section class="banner create-card">
                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="history-modal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">
                                                <span class="first-heading">{dateFormat(storeRoom.startDateTime, "dd-M-yyyy hh:mm:ss")}</span>


                                                {
                                                    storeRoom?.title?.split("v-s")[0].length > 8 || storeRoom?.title?.split("v-s")[1].length > 8 ?
                                                        <span class="second-heading bigger-text">
                                                            <span className='lfttitle'>{storeRoom?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'> {storeRoom?.title?.split("v-s")[1]}
                                                            </span>
                                                        </span>
                                                        :
                                                        <span class="second-heading">
                                                            <span className='lfttitle'>{storeRoom?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'>{storeRoom?.title?.split("v-s")[1]} </span>
                                                        </span>
                                                }




                                                <span class="third-heading">PAYOUT: ${roomInfo?.winningAmt}</span>
                                            </h5>
                                        </div>
                                        <div class="modal-body">
                                            <div class="inner-checs ">
                                                <ul>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c1).length != 0 ?
                                                                <div className={r1c1?.isMatch === true ? "modal-box active-join " : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c1?.playerId?.type.length > 9 ? r1c1?.playerId?.type?.substring(0, 9) + '...' : r1c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c1?.playerId?.name?.length > 12 ? r1c1?.playerId?.name.substring(0, 12) + '...' : r1c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c2).length != 0 ?
                                                                <div className={r1c2.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c2?.playerId?.type.length > 9 ? r1c2?.playerId?.type?.substring(0, 9) + '...' : r1c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c2?.playerId?.name?.length > 12 ? r1c2?.playerId?.name.substring(0, 12) + '...' : r1c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c3).length != 0 ?
                                                                <div className={r1c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c3?.playerId?.type.length > 9 ? r1c3?.playerId?.type?.substring(0, 9) + '...' : r1c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c3?.playerId?.name?.length > 12 ? r1c3?.playerId?.name.substring(0, 12) + '...' : r1c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c4).length != 0 ?
                                                                <div className={r1c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c4?.playerId?.type.length > 9 ? r1c4?.playerId?.type?.substring(0, 9) + '...' : r1c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c4?.playerId?.name?.length > 12 ? r1c4?.playerId?.name.substring(0, 12) + '...' : r1c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c5).length != 0 ?
                                                                <div className={r1c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.type.length > 9 ? r1c5?.playerId?.type?.substring(0, 9) + '...' : r1c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        {
                                                                            checkingTooltipText(r1c5?.playerId?.name)
                                                                        }
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.name?.length > 12 ? r1c5?.playerId?.name.substring(0, 12) + '...' : r1c5?.playerId?.name}
                                                                            <span class="tooltiptext">
                                                                                {r1c5?.playerId?.name}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c1).length != 0 ?
                                                                <div className={r2c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c1?.playerId?.type.length > 9 ? r2c1?.playerId?.type?.substring(0, 9) + '...' : r2c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c1?.playerId?.name?.length > 12 ? r2c1?.playerId?.name.substring(0, 12) + '...' : r2c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' >
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c2).length != 0 ?
                                                                <div className={r2c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c2?.playerId?.type.length > 9 ? r2c2?.playerId?.type?.substring(0, 9) + '...' : r2c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c2?.playerId?.name?.length > 12 ? r2c2?.playerId?.name.substring(0, 12) + '...' : r2c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c3).length != 0 ?
                                                                <div className={r2c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c3?.playerId?.type.length > 9 ? r2c3?.playerId?.type?.substring(0, 9) + '...' : r2c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>

                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c3?.playerId?.name?.length > 12 ? r2c3?.playerId?.name.substring(0, 12) + '...' : r2c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c4).length != 0 ?
                                                                <div className={r2c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c4?.playerId?.type.length > 9 ? r2c4?.playerId?.type?.substring(0, 9) + '...' : r2c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c4?.playerId?.points} <span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c4?.playerId?.name?.length > 12 ? r2c4?.playerId?.name.substring(0, 12) + '...' : r2c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c5).length != 0 ?
                                                                <div className={r2c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c5?.playerId?.type.length > 9 ? r2c5?.playerId?.type?.substring(0, 9) + '...' : r2c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c5.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c5?.playerId?.name?.length > 12 ? r2c5?.playerId?.name.substring(0, 12) + '...' : r2c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c1).length != 0 ?
                                                                <div className={r3c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c1?.playerId?.type.length > 9 ? r3c1?.playerId?.type?.substring(0, 9) + '...' : r3c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c1?.playerId?.name?.length > 12 ? r3c1?.playerId?.name.substring(0, 12) + '...' : r3c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c2).length != 0 ?
                                                                <div className={r3c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c2?.playerId?.type.length > 9 ? r3c2?.playerId?.type?.substring(0, 9) + '...' : r3c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c2?.playerId?.name?.length > 12 ? r3c2?.playerId?.name.substring(0, 12) + '...' : r3c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        <div className="modal-box active-join center-bingo" style={{ lineHeight: 'inherit' }}>
                                                            {/* center */}
                                                            <div className="textdiv">
                                                                <div className="middle-img">
                                                                    <h3>1 PTS</h3>
                                                                    <img className="img-fluid" src={stampchamp} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-left"></span> : ''}
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c4).length != 0 ?
                                                                <div className={r3c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c4?.playerId?.type.length > 9 ? r3c4?.playerId?.type?.substring(0, 9) + '...' : r3c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c4?.playerId?.name?.length > 12 ? r3c4?.playerId?.name.substring(0, 12) + '...' : r3c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c5).length != 0 ?
                                                                <div className={r3c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.type.length > 9 ? r1c5?.playerId?.type?.substring(0, 9) + '...' : r1c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c5?.playerId?.name?.length > 12 ? r3c5?.playerId?.name.substring(0, 12) + '...' : r3c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c1).length != 0 ?
                                                                <div className={r4c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c1?.playerId?.type.length > 9 ? r4c1?.playerId?.type?.substring(0, 9) + '...' : r4c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c1?.playerId?.name?.length > 12 ? r4c1?.playerId?.name.substring(0, 12) + '...' : r4c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-righttp-angle"></span> : ''}
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c2).length != 0 ?
                                                                <div className={r4c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c2?.playerId?.type.length > 9 ? r4c2?.playerId?.type?.substring(0, 9) + '...' : r4c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c2?.playerId?.name?.length > 12 ? r4c2?.playerId?.name.substring(0, 12) + '...' : r4c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-top"></span> : ''}
                                                        {
                                                            Object.keys(r4c3).length != 0 ?
                                                                <div className={r4c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c3?.playerId?.type.length > 9 ? r4c3?.playerId?.type?.substring(0, 9) + '...' : r4c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c3?.playerId?.name?.length > 12 ? r4c3?.playerId?.name.substring(0, 12) + '...' : r4c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-lefttp-angle"></span> : ''}
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c4).length != 0 ?
                                                                <div className={r4c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c4?.playerId?.type.length > 9 ? r4c4?.playerId?.type?.substring(0, 9) + '...' : r4c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c4?.playerId?.name?.length > 12 ? r4c4?.playerId?.name.substring(0, 12) + '...' : r4c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c5).length != 0 ?
                                                                <div className={r4c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.type.length > 9 ? r1c5?.playerId?.type?.substring(0, 9) + '...' : r1c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c5?.playerId?.name?.length > 12 ? r4c5?.playerId?.name.substring(0, 12) + '...' : r4c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c1).length != 0 ?
                                                                <div className={r5c1.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c1?.playerId?.type.length > 9 ? r5c1?.playerId?.type?.substring(0, 9) + '...' : r5c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c1?.playerId?.name?.length > 12 ? r5c1?.playerId?.name.substring(0, 12) + '...' : r5c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c2).length != 0 ?
                                                                <div className={r5c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c2?.playerId?.type.length > 9 ? r5c2?.playerId?.type?.substring(0, 9) + '...' : r5c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c2?.playerId?.name?.length > 12 ? r5c2?.playerId?.name.substring(0, 12) + '...' : r5c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c3).length != 0 ?
                                                                <div className={r5c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c3?.playerId?.type.length > 9 ? r5c3?.playerId?.type?.substring(0, 9) + '...' : r5c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c3?.playerId?.points} <span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c3?.playerId?.name?.length > 12 ? r5c3?.playerId?.name.substring(0, 12) + '...' : r5c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c4).length != 0 ?
                                                                <div className={r5c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c4?.playerId?.type.length > 9 ? r5c4?.playerId?.type?.substring(0, 9) + '...' : r5c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c4?.playerId?.name?.length > 12 ? r5c4?.playerId?.name.substring(0, 12) + '...' : r5c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {
                                                            Object.keys(r5c5).length != 0 ?
                                                                <div className={r5c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c5?.playerId?.type.length > 9 ? r5c5?.playerId?.type?.substring(0, 9) + '...' : r5c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c5?.playerId?.name?.length > 12 ? r5c5?.playerId?.name.substring(0, 12) + '...' : r5c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <Leaderboard roomId={roomId} userId={userId} userCardId={userCardId} />
                        </div>
                    </div>
                    <div class="margin"></div>
                </div>
            </section >

        </div >
    );
}

