import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const getDashbaordDetailsUserMethod = (userId) => {
    return wrappedFetch(Url.GET_DASHBOARD_DETAILS_USER + userId, "GET")
}

export const Getalluserandreferrals = () => {
    return wrappedGet(Url.GET_TOTAL_USER_AND_REFERRALS, "GET");
};

export const Getfreebalance = () => {
    return wrappedGet(Url.GET_FREEBALANCE, "GET");
};

export const Editofferbalance=(data)=>{
    return wrappedFetch(Url.EDIT_FREEBALANCE, "POST", data)
}

export const getUserDetails = () => {
    return wrappedGet(Url.GET_USERDETAILSBYDATE, "GET");
};

