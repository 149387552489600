import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// Base setup - 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { errorHandleService, notifyService } from '../../../common';
import LogoutEvn from '../../../common/LogoutEvn';
import { getRoomDetailsById, putPropsDetails } from '../../../Api/api_calls/adminroomApis';
import queryString from "query-string";
import { withRouter } from 'react-router-dom'
import dateFormat from 'dateformat';
import Button from '@mui/material/Button';
import { MessageList } from '../../../constants/MessageConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function EditTable(props) {
    const dispatch = useDispatch();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [roomDetails, setRoomDetails] = useState(null);
    const [, setPropsDetails] = useState(null);
    const logoutEvents = LogoutEvn()

    const [columns,] = useState([
        {
            field: 'name',
            headerName: 'Player/Team',
            width: 250,
            editable: true,
            disableColumnMenu: true
        },
        {
            field: 'period',
            headerName: 'Period',
            width: 250,
            editable: true,
            disableColumnMenu: true
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 250,
            editable: true,
            disableColumnMenu: true
        },
        {
            field: 'points',
            headerName: 'Points',
            width: 250,
            editable: true,
            disableColumnMenu: true
        }
    ]);
    const [rows, setRow] = useState([]);


    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    // getting room and props details by id
    useEffect(() => {
        getRoomAndPropsMethod(queryString.parse(props.location.search).id)
        // eslint-disable-next-line
    }, [])

    //Get All room and props api
    function getRoomAndPropsMethod(roomId) {
        dispatch(loaderStatus(true));
        getRoomDetailsById(roomId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setRoomDetails(res?.data?.data?.room)
                setPropsDetails(res?.data?.data?.props)
                if (res?.data?.data?.props?.length > 0) {
                    let getData = res?.data?.data?.props
                    let finalArray = []
                    getData.map((obj, index) => {
                        let setObj = {}
                        setObj['id'] = index + 1
                        setObj['name'] = obj?.name
                        setObj['period'] = obj?.period
                        setObj['points'] = obj?.points
                        setObj['type'] = obj?.type
                        setObj['roomId'] = obj?.roomId
                        setObj['_id'] = obj?._id
                        finalArray.push(setObj)
                    })
                    setRow(finalArray)
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
        let getObj = model;
        if (!isEmpty(getObj)) {
            for (const key in getObj) {
                let index = key
                let selectObj = getObj[key]
                for (const newkey in selectObj) {
                    let getValue = selectObj[newkey]?.value
                    let newArr = rows.map((item, i) => {
                        if (index - 1 == i) {
                            return { ...item, [newkey]: getValue };
                        } else {
                            return item;
                        }
                    });
                    setRow(newArr);
                }
            }
        }
    });

    // let number = parseInt(yourInput.value, 10);
    // if (!isNaN(number)) {
    // }

    const onSubmitData = () => {
        let updateObj = rows
        let checkRowBlank = false;
        let newUpdateObj = [];
        updateObj.map(obj => {
            let editpoints = parseInt(obj?.points, 10)
            if (obj.name.trim() === "" || obj.period.trim() === "" || (isNaN(editpoints)) === true || obj.type.trim() === "") {
                checkRowBlank = true
            }
        })
        if (checkRowBlank === true) {
            notifyService.info('Blank field is not allowed')
            return
        }
        updateObj.map(obj => {
            let setObj = {}
            setObj['name'] = obj?.name
            setObj['period'] = obj?.period
            setObj['points'] = obj?.points
            setObj['type'] = obj?.type
            setObj['roomId'] = obj?.roomId
            setObj['_id'] = obj?._id
            newUpdateObj.push(setObj)
        })
        dispatch(loaderStatus(true));
        putPropsDetails(newUpdateObj).then(res => {
            dispatch(loaderStatus(false));
            notifyService.success(MessageList.UPDATE_PROPS)
            goBack()
            // getAllPlayerMethod()
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // go back to other page -
    const goBack = () => {
        props.history.push('/all_rooms');
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={9}>
                    <Box component="div" style={{ fontWeight: 700 }} display="inline" mt={3} className="mainHeading">Props</Box>
                </Grid>
                <Grid item xs={3} direction="row" className="textEnd">
                    <Button variant="contained" component="span" className="buttonTheme buttonFontTheme" size='small' endIcon={<ArrowBackIcon />} onClick={goBack}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12} className="paddingTop"><span className='subHeading'>Room Details</span></Grid>

                <Grid item xs={0.2}></Grid>

                <Grid item xs={3} className='subHeading'>
                    <Grid container direction="row" >
                        <Grid item xs={2}>
                            <span>Title</span><span>&nbsp;:&nbsp;</span>

                        </Grid>
                        <Grid item xs={10}>
                            {
                                roomDetails?.title?.split("v-s")[0].length >12 ?
                                    <Grid class="subHeading bigger-text">
                                        <span className='lfttitle'>{roomDetails?.title?.split("v-s")[0]}</span>&nbsp;
                                        <span style={{ paddingLeft: '45px' }} className="title-vs">VS</span>&nbsp;
                                        <span className='rgttitle'> {roomDetails?.title?.split("v-s")[1]}
                                        </span>
                                    </Grid>
                                    :
                                    <Grid class="subHeading">
                                        <span className='lfttitle'>{roomDetails?.title?.split("v-s")[0]}</span>&nbsp;
                                        <span className="title-vs">VS</span>&nbsp;
                                        <span className='rgttitle'>{roomDetails?.title?.split("v-s")[1]} </span>
                                    </Grid>
                            }

                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs={1}></Grid>
                <Grid item xs={3} className='subHeading'><span>Start Time</span><span>&nbsp;:&nbsp; {dateFormat(roomDetails?.startDateTime, "h:MM TT")}</span></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} className='subHeading'><span>Start Date</span><span>&nbsp;:&nbsp;{dateFormat(roomDetails?.startDateTime, "mmmm dS, yyyy")}</span></Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={12} className="paddingTop"><span className='subHeading'>Edit Props</span></Grid>
                <Grid item xs={12} className="paddingTop">
                    <div style={{ height: 360, width: '100%' }}>
                        <DataGrid rows={rows} columns={columns} editRowsModel={editRowsModel}
                            onEditRowsModelChange={handleEditRowsModelChange} />
                    </div>
                </Grid>
                <Grid item xs={12} className="paddingTopLeft">
                    <Button autoFocus onClick={goBack} className='modalCommonButton'>cancel</Button>&nbsp;
                    <Button autoFocus onClick={onSubmitData} className='modalCommonButton'>Update</Button>
                </Grid>
            </Grid>
        </div >
    );
}

export default withRouter(EditTable)
