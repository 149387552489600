import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import dateFormat from 'dateformat';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

export default function Userhistoryadmin(props) {
    const classes = useStyles();
    const location = useLocation();
    let history = useHistory();

    // getting user history via history.push
    const Data = location?.state.details

    // handle back for all user page
    const handleback = () => {
        history.push("/all_users")
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={9}>
                    <Box component="div" style={{ fontWeight: 700 }} display="inline" className="mainHeading">User History</Box>
                </Grid>
                <Grid item xs={3} direction="row" className="textEnd">
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" className="buttonTheme" endIcon={<ArrowBackIcon />} onClick={handleback}>Back</Button>
                    </label>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Room</TableCell>
                                    <TableCell align="center" className="tableHeading">Date</TableCell>
                                    <TableCell align="center" className="tableHeading">Point</TableCell>
                                    <TableCell align="center" className="tableHeading">Bingos</TableCell>
                                    <TableCell align="center" className="tableHeading">Place</TableCell>
                                    <TableCell align="center" className="tableHeading">Win Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Data?.map && Data?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {row?.roomId?.title.split("v-s")[0]}&nbsp;<span className="title-vs">VS</span>&nbsp;{row?.roomId?.title.split("v-s")[1]}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row?.roomId?.startDateTime, "mmmm dS, yyyy")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.points}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.bingoCount}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.winningPotion}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            $&nbsp;{row?.winningAmt}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    )
}