
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import stampchamp from '../../../../Assets/images/stampchamp.png'
import ball from '../../../../Assets/images/ball.png'
import "../../../../index.css"
import Navbar from '../../../common/headers/Navbar'
import './card.css'
import { getRoomDetailsByIdUser, saveUserCard } from '../../../Api/api_calls/cardApis'
import queryString from "query-string";
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { errorHandleService, notifyService } from '../../../common';
import LogoutEvn from '../../../common/LogoutEvn';
import dateFormat from 'dateformat';
import { UserMessage } from '../../../constants/MessageConfig';
import Flip from 'react-reveal/Flip';
import { getUserProfile } from '../../../Api/api_calls/profileApi';
import { balanceAction } from '../../../redux/Actions/BalanceAction';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function CardCreate(props) {
    const [stateChang, setChangeState] = useState(1)

    const [player, setPlayerList] = useState([])
    const [randomize,] = useState(1)
    const [roomDetails, setRoomDetails] = useState([])
    const dispatch = useDispatch();
    const [, selectDragItem] = useState([])
    const [boxAllow, setBoxAllow] = useState(true)
    const [boxValue, setBoxValue] = useState({})
    const [boxId, setBoxId] = useState('')
    const [cardReadyForDb, setCardReadyForDb] = useState({})
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);
    const userId = useSelector((state) => state?.Auth?._id);
    const auth = useSelector((state) => state.Auth);
    const logoutEvents = LogoutEvn()

    // Code for start box 
    // row 1
    const [r1c1, set_r1c1] = useState({})
    const [r1c2, set_r1c2] = useState({})
    const [r1c3, set_r1c3] = useState({})
    const [r1c4, set_r1c4] = useState({})
    const [r1c5, set_r1c5] = useState({})
    // row 2
    const [r2c1, set_r2c1] = useState({})
    const [r2c2, set_r2c2] = useState({})
    const [r2c3, set_r2c3] = useState({})
    const [r2c4, set_r2c4] = useState({})
    const [r2c5, set_r2c5] = useState({})
    // row 3
    const [r3c1, set_r3c1] = useState({})
    const [r3c2, set_r3c2] = useState({})
    const [r3c3, set_r3c3] = useState({})
    const [r3c4, set_r3c4] = useState({})
    const [r3c5, set_r3c5] = useState({})
    // row 4
    const [r4c1, set_r4c1] = useState({})
    const [r4c2, set_r4c2] = useState({})
    const [r4c3, set_r4c3] = useState({})
    const [r4c4, set_r4c4] = useState({})
    const [r4c5, set_r4c5] = useState({})
    // row 5
    const [r5c1, set_r5c1] = useState({})
    const [r5c2, set_r5c2] = useState({})
    const [r5c3, set_r5c3] = useState({})
    const [r5c4, set_r5c4] = useState({})
    const [r5c5, set_r5c5] = useState({})

    // Code for close box
   // Code for task 1
   const [checkOneRand,setCheckOneRand] = useState(true)

   // Code for start box 
    // row 1
    const [r1c1Check, set_r1c1Check] = useState(false)
    const [r1c2Check, set_r1c2Check] = useState(false)
    const [r1c3Check, set_r1c3Check] = useState(false)
    const [r1c4Check, set_r1c4Check] = useState(false)
    const [r1c5Check, set_r1c5Check] = useState(false)
    // row 2
    const [r2c1Check, set_r2c1Check] = useState(false)
    const [r2c2Check, set_r2c2Check] = useState(false)
    const [r2c3Check, set_r2c3Check] = useState(false)
    const [r2c4Check, set_r2c4Check] = useState(false)
    const [r2c5Check, set_r2c5Check] = useState(false)
    // row 3
    const [r3c1Check, set_r3c1Check] = useState(false)
    const [r3c2Check, set_r3c2Check] = useState(false)
    const [r3c4Check, set_r3c4Check] = useState(false)
    const [r3c5Check, set_r3c5Check] = useState(false)
    // row 4
    const [r4c1Check, set_r4c1Check] = useState(false)
    const [r4c2Check, set_r4c2Check] = useState(false)
    const [r4c3Check, set_r4c3Check] = useState(false)
    const [r4c4Check, set_r4c4Check] = useState(false)
    const [r4c5Check, set_r4c5Check] = useState(false)
    // row 5
    const [r5c1Check, set_r5c1Check] = useState(false)
    const [r5c2Check, set_r5c2Check] = useState(false)
    const [r5c3Check, set_r5c3Check] = useState(false)
    const [r5c4Check, set_r5c4Check] = useState(false)
    const [r5c5Check, set_r5c5Check] = useState(false)

    // Box code start --
    // Row 1
    const onDropset_r1c1 = async(e) => {
        if (boxAllow) {
            set_r1c1Check(true)
            if (isEmpty(r1c1)) {
                set_r1c1Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c1(boxValue)
                    checkRemoveMethod()
                }else{
                    set_r1c1(player[id])
                    player.splice(id, 1);
                    setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c1({})
                    await delay(100);
                    set_r1c1(boxValue)
                    suffingValue(r1c1,boxId)
                }else{
                    set_r1c1({})
                    await delay(100);
                    set_r1c1(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r1c1])
                }
            }
        }
    }
    const onDropset_r1c2 = async(e) => {
        if (boxAllow) {
            set_r1c2Check(true)
            if (isEmpty(r1c2)) {
                set_r1c2Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c2(boxValue)
                    checkRemoveMethod()
                }else{
                set_r1c2(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c2({})
                    await delay(100);
                    set_r1c2(boxValue)
                    suffingValue(r1c2,boxId)
                }else{
                    set_r1c2({})
                    await delay(100);
                    set_r1c2(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r1c2])
                }
            }
        }
    }
    const onDropset_r1c3 = async(e) => {
        if (boxAllow) {
            set_r1c3Check(true)
            if (isEmpty(r1c3)) {
                set_r1c3Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c3(boxValue)
                    checkRemoveMethod()
                }else{
                set_r1c3(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c3({})
                    await delay(100);
                    set_r1c3(boxValue)
                    suffingValue(r1c3,boxId)
                }else{
                    set_r1c3({})
                    await delay(100);
                    set_r1c3(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r1c3])
                }
            }
        }
    }
    const onDropset_r1c4 = async(e) => {
        if (boxAllow) {
            set_r1c4Check(true)
            if (isEmpty(r1c4)) {
                set_r1c4Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c4(boxValue)
                    checkRemoveMethod()
                }else{
                set_r1c4(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c4({})
                    await delay(100);
                    set_r1c4(boxValue)
                    suffingValue(r1c4,boxId)
                }else{
                    set_r1c4({})
                    await delay(100);
                    set_r1c4(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r1c4])
                }
            }
        }
    }
    const onDropset_r1c5 = async(e) => {
        if (boxAllow) {
            set_r1c5Check(true)
            if (isEmpty(r1c5)) {
                set_r1c5Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c5(boxValue)
                    checkRemoveMethod()
                }else{
                set_r1c5(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r1c5({})
                    await delay(100);
                    set_r1c5(boxValue)
                    suffingValue(r1c5,boxId)
                }else{
                    set_r1c5({})
                    await delay(100);
                    set_r1c5(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r1c5])
                }
            }
        }
    }
    // Row 2
    const onDropset_r2c1 = async(e) => {
        if (boxAllow) {
            set_r2c1Check(true)
            if (isEmpty(r2c1)) {
                set_r2c1Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c1(boxValue)
                    checkRemoveMethod()
                }else{
                set_r2c1(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c1({})
                    await delay(100);
                    set_r2c1(boxValue)
                    suffingValue(r2c1,boxId)
                }else{
                    set_r2c1({})
                    await delay(100);
                    set_r2c1(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r2c1])
                }
            }
        }
    }
    const onDropset_r2c2 = async(e) => {
        if (boxAllow) {
            set_r2c2Check(true)
            if (isEmpty(r2c2)) {
                set_r2c2Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c2(boxValue)
                    checkRemoveMethod()
                }else{
                set_r2c2(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c2({})
                    await delay(100);
                    set_r2c2(boxValue)
                    suffingValue(r2c2,boxId)
                }else{
                    set_r2c2({})
                    await delay(100);
                    set_r2c2(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r2c2])
                }
            }
        }
    }
    const onDropset_r2c3 = async(e) => {
        if (boxAllow) {
            set_r2c3Check(true)
            if (isEmpty(r2c3)) {
                set_r2c3Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c3(boxValue)
                    checkRemoveMethod()
                }else{
                set_r2c3(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c3({})
                    await delay(100);
                    set_r2c3(boxValue)
                    suffingValue(r2c3,boxId)
                }else{
                    set_r2c3({})
                    await delay(100);
                    set_r2c3(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r2c3])
                }
            }
        }
    }
    const onDropset_r2c4 = async(e) => {
        if (boxAllow) {
            set_r2c4Check(true)
            if (isEmpty(r2c4)) {
                set_r2c4Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c4(boxValue)
                    checkRemoveMethod()
                }else{
                set_r2c4(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c4({})
                    await delay(100);
                    set_r2c4(boxValue)
                    suffingValue(r2c4,boxId)
                }else{
                    set_r2c4({})
                    await delay(100);
                    set_r2c4(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r2c4])
                }
            }
        }
    }
    const onDropset_r2c5 = async(e) => {
        if (boxAllow) {
            set_r2c5Check(true)
            if (isEmpty(r2c5)) {
                set_r2c5Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c5(boxValue)
                    checkRemoveMethod()
                }else{
                set_r2c5(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r2c5({})
                    await delay(100);
                    set_r2c5(boxValue)
                    suffingValue(r2c5,boxId)
                }else{
                    set_r2c5({})
                    await delay(100);
                    set_r2c5(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r2c5])
                }
            }
        }
    }
    // Row 3
    const onDropset_r3c1 = async(e) => {
        if (boxAllow) {
            set_r3c1Check(true)
            if (isEmpty(r3c1)) {
                set_r3c1Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c1(boxValue)
                    checkRemoveMethod()
                }else{
                set_r3c1(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c1({})
                    await delay(100);
                    set_r3c1(boxValue)
                    suffingValue(r3c1,boxId)
                }else{
                    set_r3c1({})
                    await delay(100);
                    set_r3c1(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r3c1])
                }
            }
        }
    }
    const onDropset_r3c2 = async(e) => {
        if (boxAllow) {
            set_r3c2Check(true)
            if (isEmpty(r3c2)) {
                set_r3c2Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c2(boxValue)
                    checkRemoveMethod()
                }else{
                set_r3c2(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c2({})
                    await delay(100);
                    set_r3c2(boxValue)
                    suffingValue(r3c2,boxId)
                }else{
                    set_r3c2({})
                    await delay(100);
                    set_r3c2(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r3c2])
                }
            }
        }
    }

    const onDropset_r3c4 = async(e) => {
        if (boxAllow) {
            set_r3c4Check(true)
            if (isEmpty(r3c4)) {
                set_r3c4Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c4(boxValue)
                    checkRemoveMethod()
                }else{
                set_r3c4(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c4({})
                    await delay(100);
                    set_r3c4(boxValue)
                    suffingValue(r3c4,boxId)
                }else{
                    set_r3c4({})
                    await delay(100);
                    set_r3c4(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r3c4])
                }
            }
        }
    }
    const onDropset_r3c5 = async(e) => {
        if (boxAllow) {
            set_r3c5Check(true)
            if (isEmpty(r3c5)) {
                set_r3c5Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c5(boxValue)
                    checkRemoveMethod()
                }else{
                set_r3c5(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r3c5({})
                    await delay(100);
                    set_r3c5(boxValue)
                    suffingValue(r3c5,boxId)
                }else{
                    set_r3c5({})
                    await delay(100);
                    set_r3c5(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r3c5])
                }
            }
        }
    }
    // Row 4
    const onDropset_r4c1 = async(e) => {
        if (boxAllow) {
            set_r4c1Check(true)
            if (isEmpty(r4c1)) {
                set_r4c1Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c1(boxValue)
                    checkRemoveMethod()
                }else{
                set_r4c1(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c1({})
                    await delay(100);
                    set_r4c1(boxValue)
                    suffingValue(r4c1,boxId)
                }else{
                    set_r4c1({})
                    await delay(100);
                    set_r4c1(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r4c1])
                }
            }
        }
    }
    const onDropset_r4c2 = async(e) => {
        if (boxAllow) {
            set_r4c2Check(true)
            if (isEmpty(r4c2)) {
                set_r4c2Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c2(boxValue)
                    checkRemoveMethod()
                }else{
                set_r4c2(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c2({})
                    await delay(100);
                    set_r4c2(boxValue)
                    suffingValue(r4c2,boxId)
                }else{
                    set_r4c2({})
                    await delay(100);
                    set_r4c2(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r4c2])
                }
            }
        }
    }
    const onDropset_r4c3 = async(e) => {
        if (boxAllow) {
            set_r4c3Check(true)
            if (isEmpty(r4c3)) {
                set_r4c3Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c3(boxValue)
                    checkRemoveMethod()
                }else{
                set_r4c3(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c3({})
                    await delay(100);
                    set_r4c3(boxValue)
                    suffingValue(r4c3,boxId)
                }else{
                    set_r4c3({})
                    await delay(100);
                    set_r4c3(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r4c3])
                }
            }
        }
    }
    const onDropset_r4c4 = async(e) => {
        if (boxAllow) {
            set_r4c4Check(true)
            if (isEmpty(r4c4)) {
                set_r4c4Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c4(boxValue)
                    checkRemoveMethod()
                }else{
                set_r4c4(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c4({})
                    await delay(100);
                    set_r4c4(boxValue)
                    suffingValue(r4c4,boxId)
                }else{
                    set_r4c4({})
                    await delay(100);
                    set_r4c4(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r4c4])
                }
            }
        }
    }
    const onDropset_r4c5 = async(e) => {
        if (boxAllow) {
            set_r4c5Check(true)
            if (isEmpty(r4c5)) {
                set_r4c5Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c5(boxValue)
                    checkRemoveMethod()
                }else{
                set_r4c5(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r4c5({})
                    await delay(100);
                    set_r4c5(boxValue)
                    suffingValue(r4c5,boxId)
                }else{
                    set_r4c5({})
                    await delay(100);
                    set_r4c5(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r4c5])
                }
            }
        }
    }
    // Row 5
    const onDropset_r5c1 = async(e) => {
        if (boxAllow) {
            set_r5c1Check(true)
            if (isEmpty(r5c1)) {
                set_r5c1Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c1(boxValue)
                    checkRemoveMethod()
                }else{
                set_r5c1(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c1({})
                    await delay(100);
                    set_r5c1(boxValue)
                    suffingValue(r5c1,boxId)
                }else{
                    set_r5c1({})
                    await delay(100);
                    set_r5c1(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r5c1])
                }
            }
        }
    }
    const onDropset_r5c2 = async(e) => {
        if (boxAllow) {
            set_r5c2Check(true)
            if (isEmpty(r5c2)) {
                set_r5c2Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c2(boxValue)
                    checkRemoveMethod()
                }else{
                set_r5c2(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c2({})
                    await delay(100);
                    set_r5c2(boxValue)
                    suffingValue(r5c2,boxId)
                }else{
                    set_r5c2({})
                    await delay(100);
                    set_r5c2(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r5c2])
                }
            }
        }
    }
    const onDropset_r5c3 = async(e) => {
        if (boxAllow) {
            set_r5c3Check(true)
            if (isEmpty(r5c3)) {
                set_r5c3Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c3(boxValue)
                    checkRemoveMethod()
                }else{
                set_r5c3(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c3({})
                    await delay(100);
                    set_r5c3(boxValue)
                    suffingValue(r5c3,boxId)
                }else{
                    set_r5c3({})
                    await delay(100);
                    set_r5c3(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r5c3])
                }
            }
        }
    }
    const onDropset_r5c4 = async(e) => {
        if (boxAllow) {
            set_r5c4Check(true)
            if (isEmpty(r5c4)) {
                set_r5c4Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c4(boxValue)
                    checkRemoveMethod()
                }else{
                set_r5c4(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c4({})
                    await delay(100);
                    set_r5c4(boxValue)
                    suffingValue(r5c4,boxId)
                }else{
                    set_r5c4({})
                    await delay(100);
                    set_r5c4(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r5c4])
                }
            }
        }
    }
   
    const onDropset_r5c5 = async (e) => {
        if (boxAllow) {
            set_r5c5Check(true)
            if (isEmpty(r5c5)) {
                set_r5c5Check(true)
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c5(boxValue)
                    checkRemoveMethod()
                }else{
                set_r5c5(player[id])
                player.splice(id, 1);
                setPlayerList(player);
                }
            }else{
                let id = e.dataTransfer.getData("id");
                if(id === ""){
                    set_r5c5({})
                    await delay(100);
                    set_r5c5(boxValue)
                    suffingValue(r5c5,boxId)
                }else{
                    set_r5c5({})
                    await delay(100);
                    set_r5c5(player[id])
                    player.splice(id, 1);
                    setPlayerList([...player, r5c5])
                }
            }
        }
    }


    // Box code close --


    const checkRemoveMethod = () =>{
    // row 1
    if (boxId === 'r1c1') set_r1c1({})
    if (boxId === 'r1c2') set_r1c2({})
    if (boxId === 'r1c3') set_r1c3({})
    if (boxId === 'r1c4') set_r1c4({})
    if (boxId === 'r1c5') set_r1c5({})
    // row 2
    if (boxId === 'r2c1') set_r2c1({})
    if (boxId === 'r2c2') set_r2c2({})
    if (boxId === 'r2c3') set_r2c3({})
    if (boxId === 'r2c4') set_r2c4({})
    if (boxId === 'r2c5') set_r2c5({})
    // row 3
    if (boxId === 'r3c1') set_r3c1({})
    if (boxId === 'r3c2') set_r3c2({})
    if (boxId === 'r3c3') set_r3c3({})
    if (boxId === 'r3c4') set_r3c4({})
    if (boxId === 'r3c5') set_r3c5({})
    // row 4
    if (boxId === 'r4c1') set_r4c1({})
    if (boxId === 'r4c2') set_r4c2({})
    if (boxId === 'r4c3') set_r4c3({})
    if (boxId === 'r4c4') set_r4c4({})
    if (boxId === 'r4c5') set_r4c5({})
    // row 5
    if (boxId === 'r5c1') set_r5c1({})
    if (boxId === 'r5c2') set_r5c2({})
    if (boxId === 'r5c3') set_r5c3({})
    if (boxId === 'r5c4') set_r5c4({})
    if (boxId === 'r5c5') set_r5c5({})
    }
    // ---------------------------------
    const onDragStart = (event, dragData, id) => {
        setBoxAllow(true)
        selectDragItem(dragData)
        event.dataTransfer.setData("id", id);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const suffingValue = async (boxValue,boxId) =>{
        // row 1
        if (boxId === 'r1c1'){
            set_r1c1({})
            await delay(100);
            set_r1c1(boxValue)
            set_r1c1Check(true)
        } 
        if (boxId === 'r1c2'){
            set_r1c2({})
            await delay(100);
            set_r1c2(boxValue)
            set_r1c2Check(true)
        } 
        if (boxId === 'r1c3'){
            set_r1c3({})
            await delay(100);
            set_r1c3(boxValue)
            set_r1c3Check(true)
        } 
        if (boxId === 'r1c4'){
            set_r1c4({})
            await delay(100);
            set_r1c4(boxValue)
            set_r1c4Check(true)
        }
        if (boxId === 'r1c5'){
            set_r1c5({})
            await delay(100);
            set_r1c5(boxValue)
            set_r1c5Check(true)
        } 
        // row 2
        if (boxId === 'r2c1'){
            set_r2c1({})
            await delay(100);
            set_r2c1(boxValue)
            set_r2c1Check(true)
        } 
        if (boxId === 'r2c2'){
            set_r2c2({})
            await delay(100);
            set_r2c2(boxValue)
            set_r2c2Check(true)
        } 
        if (boxId === 'r2c3'){
            set_r2c3({})
            await delay(100);
            set_r2c3(boxValue)
            set_r2c3Check(true)
        } 
        if (boxId === 'r2c4'){
            set_r2c4({})
            await delay(100);
            set_r2c4(boxValue)
            set_r2c4Check(true)
        } 
        if (boxId === 'r2c5'){
            set_r2c5({})
            await delay(100);
            set_r2c5(boxValue)
            set_r2c5Check(true)
        } 
        // row 3
        if (boxId === 'r3c1'){
            set_r3c1({})
            await delay(100);
            set_r3c1(boxValue)
            set_r3c1Check(true)
        } 
        if (boxId === 'r3c2'){
            set_r3c2({})
            await delay(100);
            set_r3c2(boxValue)
            set_r3c2Check(true)
        } 
        if (boxId === 'r3c3'){
            set_r3c3({})
            await delay(100);
            set_r3c3(boxValue)
            set_r3c2Check(true)
        } 
        if (boxId === 'r3c4'){
            set_r3c4({})
            await delay(100);
            set_r3c4(boxValue)
            set_r3c4Check(true)
        }
        if (boxId === 'r3c5'){
            set_r3c5({})
            await delay(100);
            set_r3c5(boxValue)
            set_r3c5Check(true)
        } 
        // row 4
        if (boxId === 'r4c1'){
            set_r4c1({})
            await delay(100);
            set_r4c1(boxValue)
            set_r4c1Check(true)
        } 
        if (boxId === 'r4c2'){
            set_r4c2({})
            await delay(100);
            set_r4c2(boxValue)
            set_r4c2Check(true)
        } 
        if (boxId === 'r4c3'){
            set_r4c3({})
            await delay(100);
            set_r4c3(boxValue)
            set_r4c3Check(true)
        } 
        if (boxId === 'r4c4'){
            set_r4c4({})
            await delay(100);
            set_r4c4(boxValue)
            set_r4c4Check(true)
        } 
        if (boxId === 'r4c5'){
            set_r4c5({})
            await delay(100);
            set_r4c5(boxValue)
            set_r4c5Check(true)
        } 
        // row 5
        if (boxId === 'r5c1'){
            set_r5c1({})
            await delay(100);
            set_r5c1(boxValue)
            set_r5c1Check(true)
        } 
        if (boxId === 'r5c2'){
            set_r5c2({})
            await delay(100);
            set_r5c2(boxValue)
            set_r5c2Check(true)
        } 
        if (boxId === 'r5c3'){
            set_r5c3({})
            await delay(100);
            set_r5c3(boxValue)
            set_r5c3Check(true)
        } 
        if (boxId === 'r5c4'){
            set_r5c4({})
            await delay(100);
            set_r5c4(boxValue)
            set_r5c4Check(true)
        } 
        if (boxId === 'r5c5'){
            set_r5c5({})
            await delay(100);
            set_r5c5(boxValue)
            set_r5c5Check(true)
        } 
        }

    const onDragStartBox = (event, boxVal, boxIdGet) => {
        setBoxAllow(true)
        setBoxValue(boxVal)
        setBoxId(boxIdGet)
        if(boxIdGet === 'r1c1'){
            set_r1c1Check(false)
        }
        if(boxIdGet === 'r1c2'){
            set_r1c2Check(false)
        }
        if(boxIdGet === 'r1c3'){
            set_r1c3Check(false)
        }
        if(boxIdGet === 'r1c4'){
            set_r1c4Check(false)
        }
        if(boxIdGet === 'r1c5'){
            set_r1c5Check(false)
        }
        //------------------- 
        if(boxIdGet === 'r2c1'){
            set_r2c1Check(false)
        }
        if(boxIdGet === 'r2c2'){
            set_r2c2Check(false)
        }
        if(boxIdGet === 'r2c3'){
            set_r2c3Check(false)
        }
        if(boxIdGet === 'r2c4'){
            set_r2c4Check(false)
        }
        if(boxIdGet === 'r2c5'){
            set_r2c5Check(false)
        }
        //------------------------------
        if(boxIdGet === 'r3c1'){
            set_r3c1Check(false)
        }
        if(boxIdGet === 'r3c2'){
            set_r3c2Check(false)
        }
        if(boxIdGet === 'r3c4'){
            set_r3c4Check(false)
        }
        if(boxIdGet === 'r3c5'){
            set_r3c5Check(false)
        }
        // ----------------------------------
        if(boxIdGet === 'r4c1'){
            set_r4c1Check(false)
        }
        if(boxIdGet === 'r4c2'){
            set_r4c2Check(false)
        }
        if(boxIdGet === 'r4c3'){
            set_r4c3Check(false)
        }
        if(boxIdGet === 'r4c4'){
            set_r4c4Check(false)
        }
        if(boxIdGet === 'r4c5'){
            set_r4c5Check(false)
        }
         // ----------------------------------
         if(boxIdGet === 'r5c1'){
            set_r5c1Check(false)
        }
        if(boxIdGet === 'r5c2'){
            set_r5c2Check(false)
        }
        if(boxIdGet === 'r5c3'){
            set_r5c3Check(false)
        }
        if(boxIdGet === 'r5c4'){
            set_r5c4Check(false)
        }
        if(boxIdGet === 'r5c5'){
            set_r5c5Check(false)
        }
    }


    function checkAvailability(arr, val) {
        return arr.some(function (arrVal) {
            return val === arrVal?._id;
        });
    }

    function isEmptyNew(obj) {
        return Object.keys(obj).length === 0;
    }
    const onDropList = (e) => {
        if (!checkAvailability(player, boxValue?._id)) {
            if(!isEmptyNew(boxValue)){
                setPlayerList([...player, boxValue])
            }
            // row 1
            if (boxId === 'r1c1') set_r1c1({})
            if (boxId === 'r1c2') set_r1c2({})
            if (boxId === 'r1c3') set_r1c3({})
            if (boxId === 'r1c4') set_r1c4({})
            if (boxId === 'r1c5') set_r1c5({})
            // row 2
            if (boxId === 'r2c1') set_r2c1({})
            if (boxId === 'r2c2') set_r2c2({})
            if (boxId === 'r2c3') set_r2c3({})
            if (boxId === 'r2c4') set_r2c4({})
            if (boxId === 'r2c5') set_r2c5({})
            // row 3
            if (boxId === 'r3c1') set_r3c1({})
            if (boxId === 'r3c2') set_r3c2({})
            if (boxId === 'r3c3') set_r3c3({})
            if (boxId === 'r3c4') set_r3c4({})
            if (boxId === 'r3c5') set_r3c5({})
            // row 4
            if (boxId === 'r4c1') set_r4c1({})
            if (boxId === 'r4c2') set_r4c2({})
            if (boxId === 'r4c3') set_r4c3({})
            if (boxId === 'r4c4') set_r4c4({})
            if (boxId === 'r4c5') set_r4c5({})
            // row 5
            if (boxId === 'r5c1') set_r5c1({})
            if (boxId === 'r5c2') set_r5c2({})
            if (boxId === 'r5c3') set_r5c3({})
            if (boxId === 'r5c4') set_r5c4({})
            if (boxId === 'r5c5') set_r5c5({})
        }
    }
    // ---------------------------------
    const onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }

    // getting room and props details by id
    useEffect(() => {
        getRoomAndPropsUserMethod(queryString.parse(props.location.search).id)
        // eslint-disable-next-line
    }, [])

    //Get room and props by room id -
    function getRoomAndPropsUserMethod(roomId) {
        dispatch(loaderStatus(true));
        getRoomDetailsByIdUser(roomId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setPlayerList(res?.data?.data?.props)
                setRoomDetails(res?.data?.data?.room)

            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // room payout formula
    const payoutCount = (entryFee, maxPlayer) => {
        if (entryFee && maxPlayer) {
            return (entryFee * maxPlayer) * 0.9
        } else {
            return 0
        }
    }


    // check object is emplty -
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    // On submit card section -
    const cardSubmit = () => {
        let finalCardObj = {}
        let cardBox = []
        let checkForNow = false
        // row 1
        if (isEmpty(r1c1)) { checkForNow = true } else { cardBox.push({ boxId: 'r1c1', playerId: r1c1._id, points: r1c1.points }) }
        if (isEmpty(r1c2)) { checkForNow = true } else { cardBox.push({ boxId: 'r1c2', playerId: r1c2._id, points: r1c2.points }) }
        if (isEmpty(r1c3)) { checkForNow = true } else { cardBox.push({ boxId: 'r1c3', playerId: r1c3._id, points: r1c3.points }) }
        if (isEmpty(r1c4)) { checkForNow = true } else { cardBox.push({ boxId: 'r1c4', playerId: r1c4._id, points: r1c4.points }) }
        if (isEmpty(r1c5)) { checkForNow = true } else { cardBox.push({ boxId: 'r1c5', playerId: r1c5._id, points: r1c5.points }) }
        // // row 2
        if (isEmpty(r2c1)) { checkForNow = true } else { cardBox.push({ boxId: 'r2c1', playerId: r2c1._id, points: r2c1.points }) }
        if (isEmpty(r2c2)) { checkForNow = true } else { cardBox.push({ boxId: 'r2c2', playerId: r2c2._id, points: r2c2.points }) }
        if (isEmpty(r2c3)) { checkForNow = true } else { cardBox.push({ boxId: 'r2c3', playerId: r2c3._id, points: r2c3.points }) }
        if (isEmpty(r2c4)) { checkForNow = true } else { cardBox.push({ boxId: 'r2c4', playerId: r2c4._id, points: r2c4.points }) }
        if (isEmpty(r2c5)) { checkForNow = true } else { cardBox.push({ boxId: 'r2c5', playerId: r2c5._id, points: r2c5.points }) }
        // // row 3
        if (isEmpty(r3c1)) { checkForNow = true } else { cardBox.push({ boxId: 'r3c1', playerId: r3c1._id, points: r3c1.points }) }
        if (isEmpty(r3c2)) { checkForNow = true } else { cardBox.push({ boxId: 'r3c2', playerId: r3c2._id, points: r3c2.points }) }
        if (isEmpty(r3c4)) { checkForNow = true } else { cardBox.push({ boxId: 'r3c4', playerId: r3c4._id, points: r3c4.points }) }
        if (isEmpty(r3c5)) { checkForNow = true } else { cardBox.push({ boxId: 'r3c5', playerId: r3c5._id, points: r3c5.points }) }
        // // row 4
        if (isEmpty(r4c1)) { checkForNow = true } else { cardBox.push({ boxId: 'r4c1', playerId: r4c1._id, points: r4c1.points }) }
        if (isEmpty(r4c2)) { checkForNow = true } else { cardBox.push({ boxId: 'r4c2', playerId: r4c2._id, points: r4c2.points }) }
        if (isEmpty(r4c3)) { checkForNow = true } else { cardBox.push({ boxId: 'r4c3', playerId: r4c3._id, points: r4c3.points }) }
        if (isEmpty(r4c4)) { checkForNow = true } else { cardBox.push({ boxId: 'r4c4', playerId: r4c4._id, points: r4c4.points }) }
        if (isEmpty(r4c5)) { checkForNow = true } else { cardBox.push({ boxId: 'r4c5', playerId: r4c5._id, points: r4c5.points }) }
        // // row 5
        if (isEmpty(r5c1)) { checkForNow = true } else { cardBox.push({ boxId: 'r5c1', playerId: r5c1._id, points: r5c1.points }) }
        if (isEmpty(r5c2)) { checkForNow = true } else { cardBox.push({ boxId: 'r5c2', playerId: r5c2._id, points: r5c2.points }) }
        if (isEmpty(r5c3)) { checkForNow = true } else { cardBox.push({ boxId: 'r5c3', playerId: r5c3._id, points: r5c3.points }) }
        if (isEmpty(r5c4)) { checkForNow = true } else { cardBox.push({ boxId: 'r5c4', playerId: r5c4._id, points: r5c4.points }) }
        if (isEmpty(r5c5)) { checkForNow = true } else { cardBox.push({ boxId: 'r5c5', playerId: r5c5._id, points: r5c5.points }) }
        if (checkForNow) {
            notifyService.success(UserMessage.BLANK_CARD)
            return
        }
        if (!roomDetails?._id) {
            notifyService.success(UserMessage.ROOM_ID_NOT)
            return
        } else {
            finalCardObj['roomId'] = roomDetails?._id
        }
        if (!auth._id) {
            notifyService.success(UserMessage.USER_ID_NOT)
            return
        } else {
            finalCardObj['uid'] = auth?._id
        }
        finalCardObj['cardDetails'] = cardBox
        setCardReadyForDb(finalCardObj)
        document.querySelector('#askSubmit').click()
    }

    function handleRandomization() {
        let roomIdGet = queryString.parse(props.location.search).id
        randomizationApiGet(roomIdGet)
    };


    //Get room and props by room id -
    const randomizationApiGet = (roomId) =>{
        dispatch(loaderStatus(true));
        getRoomDetailsByIdUser(roomId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                randomizationMethod(res?.data?.data?.props)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const handleReset = () =>{
        dispatch(loaderStatus(true));
        window.location.reload();
    }

    // randomization the card  -
    const randomizationMethod = (getDatat) => {
        if (getDatat.length > 0) {
            let shuffleArray = shuffle(getDatat);
                // row 1
                let checkAllManual = 0;
                if(r1c1Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r1c1._id)
                }
                if(r1c2Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r1c2._id)
                }
                if(r1c3Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r1c3._id)
                }
                if(r1c4Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r1c4._id)
                }
                if(r1c5Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r1c5._id)
                }
                // row 2
                if(r2c1Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r2c1._id)
                }
                if(r2c2Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r2c2._id)
                }
                if(r2c3Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r2c3._id)
                }
                if(r2c4Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r2c4._id)
                }
                if(r2c5Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r2c5._id)
                }
                // row 3
                if(r3c1Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r3c1._id)
                }
                if(r3c2Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r3c2._id)
                }
                if(r3c4Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r3c4._id)
                }
                if(r3c5Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r3c5._id)
                }
                // row 4
                if(r4c1Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r4c1._id)
                }
                if(r4c2Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r4c2._id)
                }
                if(r4c3Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r4c3._id)
                }
                if(r4c4Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r4c4._id)
                }
                if(r4c5Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r4c5._id)
                }
                // row 5
                if(r5c1Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r5c1._id)
                }
                if(r5c2Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r5c2._id)
                }
                if(r5c3Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r5c3._id)
                }
                if(r5c4Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r5c4._id)
                }
                if(r5c5Check){
                    checkAllManual=checkAllManual+1;
                    shuffleArray = shuffleArray.filter(obj=>obj._id != r5c5._id)
                }
            
            
            // --------------------------------------------------------------------------
            // row 1
            if(!r1c1Check ){
                set_r1c1({})
                set_r1c1(shuffleArray[0])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[0]._id)
            }
            if(!r1c2Check){
                set_r1c2({})
                set_r1c2(shuffleArray[1])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[1]._id)
            }
            if(!r1c3Check){
                set_r1c3({})
                set_r1c3(shuffleArray[2])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[2]._id)
            }
            if(!r1c4Check){
                set_r1c4({})
                set_r1c4(shuffleArray[3])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[3]._id)
            }
            if(!r1c5Check){
                set_r1c5({})
                set_r1c5(shuffleArray[4])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[4]._id)
            }
            // row 2
            if(!r2c1Check){
                set_r2c1({})
                set_r2c1(shuffleArray[5])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[5]._id)
            }
            if(!r2c2Check){
                set_r2c2({})
                set_r2c2(shuffleArray[6])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[6]._id)
            }
            if(!r2c3Check){
                set_r2c3({})
                set_r2c3(shuffleArray[7])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[7]._id)
            }
            if(!r2c4Check){
                set_r2c4({})
                set_r2c4(shuffleArray[8])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[8]._id)
            }
            if(!r2c5Check){
                set_r2c5({})
                set_r2c5(shuffleArray[9])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[9]._id)
            }
            // row 3
            if(!r3c1Check){
                set_r3c1({})
                set_r3c1(shuffleArray[10])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[10]._id)
            }
            if(!r3c2Check){
                set_r3c2({})
                set_r3c2(shuffleArray[11])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[11]._id)
            }
            if(!r3c4Check){
                set_r3c4({})
                set_r3c4(shuffleArray[12])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[12]._id)
            }
            if(!r3c5Check){
                set_r3c5({})
                set_r3c5(shuffleArray[13])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[13]._id)
            }
            // row 4
            if(!r4c1Check){
                set_r4c1({})
                set_r4c1(shuffleArray[14])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[14]._id)
            }
            if(!r4c2Check){
                set_r4c2({})
                set_r4c2(shuffleArray[15])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[15]._id)
            }
            if(!r4c3Check){
                set_r4c3({})
                set_r4c3(shuffleArray[16])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[16]._id)
            }
            if(!r4c4Check){
                set_r4c4({})
                set_r4c4(shuffleArray[17])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[17]._id)
            }
            if(!r4c5Check){
                set_r4c5({})
                set_r4c5(shuffleArray[18])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[18]._id)
            }
            // row 5
            if(!r5c1Check){
                set_r5c1({})
                set_r5c1(shuffleArray[19])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[19]._id)
            }
            if(!r5c2Check){
                set_r5c2({})
                set_r5c2(shuffleArray[20])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[20]._id)
            }
            if(!r5c3Check){
                set_r5c3({})
                set_r5c3(shuffleArray[21])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[21]._id)
            }
            if(!r5c4Check){
                set_r5c4({})
                set_r5c4(shuffleArray[22])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[22]._id)
            }
            if(!r5c5Check){
                set_r5c5({})
                set_r5c5(shuffleArray[23])
                shuffleArray = shuffleArray.filter(obj=>obj._id != shuffleArray[23]._id)
            }
            if(checkAllManual===24){
                notifyService.info('Please reset to randomize')
            }
            // let getnewArray = shuffleArray.slice(24);
            setPlayerList(shuffleArray)
        }
        // handleRandomization()
        dispatch(loaderStatus(false));
    }

    function shuffle(arr) {
        let len = arr.length;
        let d = len;
        let array = [];
        let k, i;
        for (i = 0; i < d; i++) {
            k = Math.floor(Math.random() * len);
            array.push(arr[k]);
            arr.splice(k, 1);
            len = arr.length;
        }
        for (i = 0; i < d; i++) {
            arr[i] = array[i];
        }
        return arr;
    }

    // on submit of yes modal for card -
    const submitCardYes = () => {
        document.querySelector('#closeModalHidden').click()
        let finalObj = cardReadyForDb
        dispatch(loaderStatus(true));
        saveUserCard(finalObj).then(res => {
            dispatch(loaderStatus(false));
            getUserDetails()
            if (res?.data?.code === 205) {
                notifyService.error(res?.data?.message)
            } else {
                notifyService.success(UserMessage.CARD_ADDED_SUCCESS)
                props.history.push("/cards_play/" + roomDetails?._id + "/" + auth?._id)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get user details -
    function getUserDetails() {
        let userIdSend = userId
        dispatch(loaderStatus(true));
        getUserProfile(userIdSend).then(res => {
            dispatch(loaderStatus(false));
            let venmoBalance = res.data?.data[0]?.venmoBalance;
            let reduxObject = {}
            if (venmoBalance) {
                reduxObject['venmoBalance'] = venmoBalance
            } else {
                reduxObject['venmoBalance'] = 0
            }
            dispatch(balanceAction(reduxObject))
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    // handle for return to home page 
    const homepage = () => {
        props.history.push("/account")
    }

    const maxToLowMethod = () => {
        let lowToMaxValue = player.sort((a, b) => a.points > b.points ? 1 : -1).reverse();
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const lowToMaxMethod = () => {
        let lowToMaxValue = player.sort((a, b) => a.points > b.points ? 1 : -1);
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const maxToLowMethodName = () => {
        let lowToMaxValue = player.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).reverse();
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const lowToMaxMethodName = () => {
        let lowToMaxValue = player.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const maxToLowMethodType = () => {
        let lowToMaxValue = player.sort((a, b) => a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1).reverse();
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const lowToMaxMethodType = () => {
        let lowToMaxValue = player.sort((a, b) => a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1);
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const maxToLowMethodPeriod = () => {
        let lowToMaxValue = player.sort((a, b) => a.period.toLowerCase() > b.period.toLowerCase() ? 1 : -1).reverse();
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }

    const lowToMaxMethodPeriod = () => {
        let lowToMaxValue = player.sort((a, b) => a.period.toLowerCase() > b.period.toLowerCase() ? 1 : -1);
        setChangeState(stateChang + 1)
        setPlayerList(lowToMaxValue)
    }


    // check condition card name is bigger or not
    const checkingTooltipText = (propsName) => {
        if (propsName.length > 12) {
            return <span class="tooltiptext">{propsName}</span>
        } else {
            return ''
        }
    }

    // check condition card type is bigger or not
    const checkingTooltipTexttype = (propstype) => {
        if (propstype.length > 9) {
            return <span class="tooltiptext">{propstype}</span>
        } else {
            return ''
        }
    }


    return (
        <div className="accounts">
            <Navbar />
            <div className="accounts-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <a href="#" className="accounts-heading">
                                <img className="img-fluid" onClick={homepage} src={ball} />Create Your Card
                                <span className='displaynothing'>{randomize}</span>
                            </a>
                        </div>
                        <div className="col">
                            <ul className="accounts-ul">
                                <li className="balance">Balance <span>$&nbsp;{userbalance.toFixed(2)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="banner create-card">
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-7 col-md-12 leftb">
                            <div className="history-modal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">
                                                <span class="first-heading">
                                                    {dateFormat(roomDetails?.startDateTime, "mmmm dS, yyyy")}&nbsp;
                                                    {dateFormat(roomDetails.startDateTime, "h:MM TT")}
                                                </span>
                                                {
                                                    roomDetails?.title?.split("v-s")[0].length > 8 ?
                                                        <span class="second-heading bigger-text">
                                                            <span className='lfttitle'>{roomDetails?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'> {roomDetails?.title?.split("v-s")[1]}
                                                            </span>
                                                        </span>
                                                        :
                                                        <span class="second-heading">
                                                            <span className='lfttitle'>{roomDetails?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'>{roomDetails?.title?.split("v-s")[1]} </span>
                                                        </span>
                                                }


                                                <span class="third-heading">MAX PAYOUT<p title="Max payout vary according to total players in room" className='ptagDispayInline'><InfoIcon className='infoicon' /></p>: ${payoutCount(roomDetails?.entryFee, roomDetails?.maxPlayer).toFixed(2)}</span>
                                            </h5>
                                        </div>
                                        <div className="modal-body delete">
                                            <div className="inner-checs">
                                                <ul>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r1c1(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r1c1).length != 0 ?
                                                                <Flip >
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r1c1, 'r1c1')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r1c1?.type?.length > 9 ? r1c1?.type?.substring(0, 9) + '...' : r1c1?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r1c1?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r1c1?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r1c1.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r1c1?.name?.length > 12 ? r1c1?.name?.substring(0, 12) + '...' : r1c1?.name}
                                                                                {
                                                                                    checkingTooltipText(r1c1?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r1c2(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r1c2).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r1c2, 'r1c2')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r1c2?.type?.length > 9 ? r1c2?.type?.substring(0, 9) + '...' : r1c2?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r1c2?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r1c2?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r1c2.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r1c2?.name?.length > 12 ? r1c2?.name?.substring(0, 12) + '...' : r1c2?.name}
                                                                                {
                                                                                    checkingTooltipText(r1c2?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r1c3(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r1c3).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r1c3, 'r1c3')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r1c3?.type?.length > 9 ? r1c3?.type?.substring(0, 9) + '...' : r1c3?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r1c3?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r1c3?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r1c3.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r1c3?.name?.length > 12 ? r1c3?.name?.substring(0, 12) + '...' : r1c3?.name}
                                                                                {
                                                                                    checkingTooltipText(r1c3?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r1c4(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r1c4).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r1c4, 'r1c4')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r1c4?.type?.length > 9 ? r1c4?.type?.substring(0, 9) + '...' : r1c4?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r1c4?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r1c4?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">
                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r1c4.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r1c4?.name?.length > 12 ? r1c4?.name?.substring(0, 12) + '...' : r1c4?.name}
                                                                                {
                                                                                    checkingTooltipText(r1c4?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r1c5(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r1c5).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r1c5, 'r1c5')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r1c5?.type?.length > 9 ? r1c5?.type?.substring(0, 9) + '...' : r1c5?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r1c5?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r1c5?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r1c5.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r1c5?.name?.length > 12 ? r1c5?.name?.substring(0, 12) + '...' : r1c5?.name}
                                                                                {
                                                                                    checkingTooltipText(r1c5?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r2c1(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r2c1).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r2c1, 'r2c1')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r2c1?.type?.length > 9 ? r2c1?.type?.substring(0, 9) + '...' : r2c1?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r2c1?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r2c1?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r2c1.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r2c1?.name?.length > 12 ? r2c1?.name?.substring(0, 12) + '...' : r2c1?.name}
                                                                                {
                                                                                    checkingTooltipText(r2c1?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r2c2(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r2c2).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r2c2, 'r2c2')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r2c2?.type?.length > 9 ? r2c2?.type?.substring(0, 9) + '...' : r2c2?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r2c2?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r2c2?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r2c2.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r2c2?.name?.length > 12 ? r2c2?.name?.substring(0, 12) + '...' : r2c2?.name}
                                                                                {
                                                                                    checkingTooltipText(r2c2?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r2c3(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r2c3).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r2c3, 'r2c3')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r2c3?.type?.length > 9 ? r2c3?.type?.substring(0, 9) + '...' : r2c3?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r2c3?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r2c3?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r2c3.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r2c3?.name?.length > 12 ? r2c3?.name?.substring(0, 12) + '...' : r2c3?.name}
                                                                                {
                                                                                    checkingTooltipText(r2c3?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r2c4(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r2c4).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r2c4, 'r2c4')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r2c4?.type?.length > 9 ? r2c4?.type?.substring(0, 9) + '...' : r2c4?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r2c4?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r2c4?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r2c4.points} <span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r2c4?.name?.length > 12 ? r2c4?.name?.substring(0, 12) + '...' : r2c4?.name}
                                                                                {
                                                                                    checkingTooltipText(r2c4?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r2c5(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r2c5).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r2c5, 'r2c5')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r2c5?.type?.length > 9 ? r2c5?.type?.substring(0, 9) + '...' : r2c5?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r2c5?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r2c5?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r2c5?.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r2c5?.name?.length > 12 ? r2c5?.name?.substring(0, 12) + '...' : r2c5?.name}
                                                                                {
                                                                                    checkingTooltipText(r2c5?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r3c1(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r3c1).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r3c1, 'r3c1')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r3c1?.type?.length > 9 ? r3c1?.type?.substring(0, 9) + '...' : r3c1?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r3c1?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r3c1?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r3c1?.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r3c1?.name?.length > 12 ? r3c1?.name?.substring(0, 12) + '...' : r3c1?.name}
                                                                                {
                                                                                    checkingTooltipText(r3c1?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r3c2(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r3c2).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r3c2, 'r3c2')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r3c2?.type?.length > 9 ? r3c2?.type?.substring(0, 9) + '...' : r3c2?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r3c2?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r3c2?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r3c2.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r3c2?.name?.length > 12 ? r3c2?.name?.substring(0, 12) + '...' : r3c2?.name}
                                                                                {
                                                                                    checkingTooltipText(r3c2?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <Flip>
                                                        <li className='cardTest'>
                                                            <div className="modal-box active-join center-bingo" style={{ lineHeight: 'inherit' }}>
                                                                {/* center */}
                                                                <div className="textdiv">
                                                                    <div className="middle-img">
                                                                        <h3>1 PTS</h3>
                                                                        <img className="img-fluid" src={stampchamp} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </Flip>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r3c4(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r3c4).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r3c4, 'r3c4')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r3c4?.type?.length > 9 ? r3c4?.type?.substring(0, 9) + '...' : r3c4?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r3c4?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r3c4?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r3c4.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r3c4?.name?.length > 12 ? r3c4?.name?.substring(0, 12) + '...' : r3c4?.name}
                                                                                {
                                                                                    checkingTooltipText(r3c4?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r3c5(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r3c5).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r3c5, 'r3c5')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r3c5?.type?.length > 9 ? r3c5?.type?.substring(0, 9) + '...' : r3c5?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r3c5?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r3c5?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r3c5.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r3c5?.name?.length > 12 ? r3c5?.name?.substring(0, 12) + '...' : r3c5?.name}
                                                                                {
                                                                                    checkingTooltipText(r3c5?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r4c1(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r4c1).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r4c1, 'r4c1')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r4c1?.type?.length > 9 ? r4c1?.type?.substring(0, 9) + '...' : r4c1?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r4c1?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r4c1?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r4c1.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r4c1?.name?.length > 12 ? r4c1?.name?.substring(0, 12) + '...' : r4c1?.name}
                                                                                {
                                                                                    checkingTooltipText(r4c1?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r4c2(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r4c2).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r4c2, 'r4c2')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r4c2?.type?.length > 9 ? r4c2?.type?.substring(0, 9) + '...' : r4c2?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r4c2?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r4c2?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r4c2.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r4c2?.name?.length > 12 ? r4c2?.name?.substring(0, 12) + '...' : r4c2?.name}
                                                                                {
                                                                                    checkingTooltipText(r4c2?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r4c3(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r4c3).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r4c3, 'r4c3')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r4c3?.type?.length > 9 ? r4c3?.type?.substring(0, 9) + '...' : r4c3?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r4c3?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r4c3?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r4c3.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r4c3?.name?.length > 12 ? r4c3?.name?.substring(0, 12) + '...' : r4c3?.name}
                                                                                {
                                                                                    checkingTooltipText(r4c3?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r4c4(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r4c4).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r4c4, 'r4c4')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r4c4?.type?.length > 9 ? r4c4?.type?.substring(0, 9) + '...' : r4c4?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r4c4?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r4c4?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r4c4.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r4c4?.name?.length > 12 ? r4c4?.name?.substring(0, 12) + '...' : r4c4?.name}
                                                                                {
                                                                                    checkingTooltipText(r4c4?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r4c5(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r4c5).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r4c5, 'r4c5')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r4c5?.type?.length > 9 ? r4c5?.type?.substring(0, 9) + '...' : r4c5?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r4c5?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r4c5?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r4c5.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r4c5?.name?.length > 12 ? r4c5?.name?.substring(0, 12) + '...' : r4c5?.name}
                                                                                {
                                                                                    checkingTooltipText(r4c5?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r5c1(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r5c1).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r5c1, 'r5c1')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r5c1?.type?.length > 9 ? r5c1?.type?.substring(0, 9) + '...' : r5c1?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r5c1?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r5c1?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r5c1.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r5c1?.name?.length > 12 ? r5c1?.name?.substring(0, 12) + '...' : r5c1?.name}
                                                                                {
                                                                                    checkingTooltipText(r5c1?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r5c2(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r5c2).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r5c2, 'r5c2')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r5c2?.type?.length > 9 ? r5c2?.type?.substring(0, 9) + '...' : r5c2?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r5c2?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r5c2?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r5c2.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r5c2?.name?.length > 12 ? r5c2?.name?.substring(0, 12) + '...' : r5c2?.name}
                                                                                {
                                                                                    checkingTooltipText(r5c2?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r5c3(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r5c3).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r5c3, 'r5c3')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r5c3?.type?.length > 9 ? r5c3?.type?.substring(0, 9) + '...' : r5c3?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r5c3?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r5c3?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r5c3.points} <span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r5c3?.name?.length > 12 ? r5c3?.name?.substring(0, 12) + '...' : r5c3?.name}
                                                                                {
                                                                                    checkingTooltipText(r5c3?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r5c4(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r5c4).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r5c4, 'r5c4')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r5c4?.type?.length > 9 ? r5c4?.type?.substring(0, 9) + '...' : r5c4?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r5c4?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r5c4?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r5c4.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r5c4?.name?.length > 12 ? r5c4?.name?.substring(0, 12) + '...' : r5c4?.name}
                                                                                {
                                                                                    checkingTooltipText(r5c4?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' dropzone onDrop={(e) => onDropset_r5c5(e)} onDragOver={(e) => onDragOver(e)}>
                                                        {
                                                            Object.keys(r5c5).length != 0 ?
                                                                <Flip>
                                                                    <div className="modal-box" draggable="true" onDragStart={(e) => onDragStartBox(e, r5c5, 'r5c5')}>
                                                                        <h5>
                                                                            <div class="tooltipp">
                                                                                {r5c5?.type?.length > 9 ? r5c5?.type?.substring(0, 9) + '...' : r5c5?.type}
                                                                                {
                                                                                    checkingTooltipTexttype(r5c5?.type)
                                                                                }
                                                                            </div>
                                                                        </h5>
                                                                        <div className="textdiv">
                                                                            <div className="irst-text">
                                                                                <h6>{r5c5?.period}</h6>
                                                                            </div>
                                                                            <div className="middle-img">

                                                                            </div>
                                                                            <div className="last-text">
                                                                                {r5c5.points}<span>PTS</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bootom-text redbox">
                                                                            <div class="tooltipp">
                                                                                {r5c5?.name?.length > 12 ? r5c5?.name?.substring(0, 12) + '...' : r5c5?.name}
                                                                                {
                                                                                    checkingTooltipText(r5c5?.name)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Flip> : null
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 rightb" dropzone onDrop={(e) => onDropList(e)} onDragOver={(e) => onDragOver(e)}>
                            <div className="player-table">
                                {/* <div className="table-responsive scroll"> */}
                                <div>
                                    <table className="table header-fixed">
                                        {/* <span className='displaywalet'>{stateChang}</span> */}
                                        <thead>
                                            <tr>
                                                <th scope="col" className='type'>Type <ArrowUpwardIcon className='ArrowFun' onClick={maxToLowMethodType} /><ArrowDownwardIcon className='ArrowFun' onClick={lowToMaxMethodType} /></th>
                                                <th scope="col" style={{ width: '170px' }} className='teamp'><span className='mnone'>Player/Team</span> <span className='mdisplay'>Player<br/>Team</span> <ArrowUpwardIcon className='ArrowFun' onClick={maxToLowMethodName} /><ArrowDownwardIcon className='ArrowFun' onClick={lowToMaxMethodName} /></th>
                                                <th scope="col" style={{ width: '120px' }} className='periodt'>Period <ArrowUpwardIcon className='ArrowFun' onClick={maxToLowMethodPeriod} /><ArrowDownwardIcon className='ArrowFun' onClick={lowToMaxMethodPeriod} /></th>
                                                <th scope="col" style={{ width: '90px' }}>PTS <ArrowUpwardIcon className='ArrowFun' onClick={maxToLowMethod} /><ArrowDownwardIcon className='ArrowFun' onClick={lowToMaxMethod} /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='scroll'>
                                            {
                                                player && player?.map((obj, index) =>
                                                    <tr draggable="true" onDragStart={(e) => onDragStart(e, obj, index)}>
                                                        <td className="filterable-cell">{obj?.type}</td>
                                                        <td className="filterable-cell teamp" style={{ width: '170px' }}>
                                                            {obj?.name}
                                                        </td>
                                                        <td className="filterable-cell" style={{ width: '120px' }}>{obj?.period}</td>
                                                        <td style={{ width: '50px', textAlign: "center" }}>{obj?.points}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                        <tfoot>
                                            <tr className='threeButton'>
                                                <td><button id="RandomizeCard" className="buttonRandomize RandomizeCard" onClick={handleRandomization}>Randomize</button>
                                                <button className="buttonRandomize" onClick={handleReset}>Reset</button> 
                                                <button  className="buttonRandomize" onClick={() => cardSubmit()}>SUBMIT</button><button className="buttonHide" data-bs-toggle="modal" data-bs-target="#exampleModalConfirm" id="askSubmit"></button></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="margin"></div>
                </div>
            </section>

            {/* <!-- Modal --> */}
            <div className="history-modal modal fade" id="exampleModalConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        </div>
                        <div className="modal-body">
                            <h3>Confirm</h3>
                            <p>Are you sure you want to submit this card to play? You cannot edit your card once submitted.</p>
                            <button id="CardCreateSubmit" className="yes-submit CardCreateSubmit" onClick={submitCardYes}>YES, SUBMIT</button>
                            <button className="no-submit" data-bs-dismiss="modal" id="closeModalHidden">NO, GO BACK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
