import { wrappedGet, wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";



export const addPrivacypolicy = (data) => {
    return wrappedFetch(Url.ADD_PRIVACY_POLICY, "POST", data);
}

export const getPrivacypolicy = () => {
    return wrappedGet(Url.GET_PRIVACY_POLICY, "GET");
};
