import React from 'react'
import '../../../../index.css'

import Ball from '../../../../Assets/images/ball.png'
import Navbar from '../../../common/headers/Navbar'
import './howtoplay.css'

export default function Howtoplay(props) {
    const homepage = () => {
        props.history.push('/account')
    }

    return (
        <div className="accounts">
            <Navbar />
            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <img class="img-fluid" src={Ball} onClick={homepage} /> HOW TO PLAY
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-7 col-md-12">
                            <div class="terms-page">

                                <h1>Choosing a game room</h1>
                                <p>
                                    1. Game rooms are created using live sporting events. A player can only participate in a game room during the live sporting event.<br />
                                    2. “Entry": The upfront fee the player will debit from their 'wallet' to compete in the room.<br />
                                    3. "Payout": The monetary reward that will be credited to the player's wallet if they achieve 1st place in the game room.<br />
                                    4. <strong>"Players":</strong><br />
                                    (a). Numerator: The current number of players who have submitted a card for entry into the game room.<br />
                                    (b). Denominator: The maximum players allowed to enter the game room.
                                </p>
                                <h1>Creating/Submitting a card</h1>
                                <p>
                                    1. Each player is allowed only one 5X5 card submission per game room.<br />
                                    2. Propositions: Refers to the cumulation of the Type + Player/Team + Period.<br />
                                    (a). Each game room has a universal set of 100 unique propositions associated with each sporting event.<br />
                                    (b). All players who enter the game room are given the same set of 100 individual propositions.<br />
                                    (c). "Type"- Refers to any action measurable within the sporting event.<br />
                                    (d). "Player/Team" - Refers to the athlete (OR) team in the sporting event that must achieve the outcome of the proposition. <br />
                                    (e). "Period" - Refers to the time period within the sporting event during which the proposition must occur.<br />
                                    (f). "PTS" (points) - Refers to the number of points the player will earn from the specific proposition if this proposition occurs in the sporting event (AND) is a part of a sequence of 5 other achieved propositions in a vertical/diagonal/horizontal row (bingo).<br />
                                    *See RULES section below for more information on how player's scores are calculated
                                    3. Players must fill-out their blank 5x5 card by selecting any combination of the 100 propositions and dragging-and-dropping it into a game card square.<br />
                                    (a). Each proposition can only be used once within 5x5 card.
                                    (b). The middle tile of the 5x5 card automatically comes pre-stamped with the value of 1-point.<br />
                                    4. "Randomize” - Selecting this button will randomly assign propositions into the empty tiles on the player's 5x5 card.<br />
                                    5."Submit” - The player cannot submit a card into the game room until every tile is filled with a proposition. The submit button will activate once the player has filled in all of the empty tiles on the 5x5 card. The player must select this button to finalizes the submission of the completed card into the game room to be used in competition against other players.<br />
                                    (a). Once a card is submitted into the game room, a player cannot add, edit, or delete any propositions on the card.
                                    6. "Cards In Play" - Signifies that the player has submitted a completed card and entered the virtual room with all players who will be competing to achieve the highest total points.<br />
                                </p>
                                <h1>Game Status’s </h1>
                                <p>
                                    1. “Awaiting Start of Game” - The sporting event has not started.<br />
                                    2. “Game-in-Progress” - The sporting event is currently at play. Player's tiles will be stamped when those propositions occur in the sporting event.<br />
                                    (a). "Points" - Refers to the total points the player has accumulated within the progression of gameplay.<br />
                                    (b). "Bingos" - Refers to the total bingos the player has earned within the progression of gameplay.<br />
                                    (c). "Filled" - Refers to the total number of tiles that have been stamped on the player's card.<br />
                                    3. ""Game Over" - The sporting event is completed (or over). All players' total points are final.<br />
                                    (a). “Place" - Refers to the final standing the player has achieved within the room compared to the other players.<br />
                                    (b). "Won" - Refers to the amount the player has earned from their final standing within that room (OR) any other winnings that may be designated under certain rules unique to that game room.<br />
                                    4. Leaderboard – A live view of all players who are entered into the game room and their individual statistics within the progress of that game.<br />
                                    (a). "Place" - Denotes the standing of the player as compared to all other players within the room.<br />
                                    (b). "Name" - Displays the name of all players within the room.<br />
                                    (c). "Bingos" - The total bingos the player has within the progress of the game.<br />
                                    (d). "PTS" (points) - The total points the player has earned within the progress of the game.

                                </p>
                                <h1>RULES</h1>
                                <h4>How to Win </h4>
                                <p>The player who scores the most points in the game room is awarded the payout associated with the room</p>
                                <h4>Tie </h4>
                                <p>If there is a tie for 1st place within the game room, the payout is distributed equally between all 1st place players </p>
                                <h4>How to earn points </h4>
                                <p>1. Players earn points only when they achieve a bingo  <br />
                                    2. "Bingo" - Refers to 5 sequential propositions stamped in a vertical/horizontal/diagonal row <br />
                                    3. ingo point calculation   : Calculated as the cumulative sum of the individual point values associated with the 5 propositions within that bingo. <br />
                                </p>
                                <table className='setColor'>
                                    <thead>
                                        <tr>
                                            <th scope="col2">Propositions within bingo</th>
                                            <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <th scope="col">Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Prop #1</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <th>Prop #2</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>4</td>
                                        </tr>
                                        <tr>
                                            <th>Prop #3</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>1</td>
                                        </tr>
                                        <tr>
                                            <th>Prop #4</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>2</td>
                                        </tr>
                                        <tr>
                                            <th>Prop #5</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total points of the bingo</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>11</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <p>4.Players then sum the point values of each bingo they have achieved to calculate their total points at the end of the game<br />
                                    5. Total Points calculation   : Calculated as the cumulative sum of all bingos
                                    the player achieves within the game
                                </p>
                                <table className='setColor'>
                                    <thead>
                                        <tr>
                                            <th scope="col2">Bingo achieved</th>
                                            <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <th scope="col">Points earned</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Bingo #1</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>11</td>
                                        </tr>
                                        <tr>
                                            <th>Bingo #2</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>5</td>
                                        </tr>
                                        <tr>
                                            <th>Bingo #3</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>14</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total points</th>
                                            <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            <td>30</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="margin"></div>
                </div>
            </section >
        </div >

    );
}





