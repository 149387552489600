import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const AddroomApi = (data) => {
    return wrappedFetch(Url.ADD_ROOM_URL, "POST", data);
};

export const getAllroomApi = () => {
    return wrappedGet(Url.GET_ROOM_URL, "GET");
};

export const DeleteRoomApi = (roomId) => {
    return wrappedFetch(Url.DELETE_ROOM_URL + roomId, "DELETE")
}

export const getRoomDetailsById = (roomId) => {
    return wrappedFetch(Url.GET_ROOM_BY_ID + roomId, "GET")
}

export const putPropsDetails = (data) => {
    return wrappedFetch(Url.UPDATE_PROPS, "POST",data)
}

// get total cards by room api 
export const Getallcardsbyroom = () => {
    return wrappedGet(Url.GET_TOTAL_CARDSBYROOMS, "GET");
};



