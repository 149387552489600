export const ADMIN_DASHBOARD_PATH = "/Admin_Management";
export const USER_DASHBOARD_PATH = "/User_Management";

export const ROLE = ['User','Admin'];

export const FIXCSV = ['Type','Player/Team','Period','Point']

// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MGM5ZjlkM2JjYTE2NTM5YTFkOTQ1OTciLCJpYXQiOjE2MjQ3ODg2ODcsImV4cCI6MTYyNDg3NTA4N30.6M1Evh4nVzEDu5BFFehmA9Hu9U44X5wOgOUtCq334AE";

// export const HEADERS = {
//     'x-access-token': token
// }