import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Base setup - 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import Chip from '@mui/material/Chip';
// Upload csv code start here -------
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
// Upload csv code end here -------
// ---------------- Modal and Form Code Start --------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import { errorHandleService, notifyService } from '../../../common';
// ---------------- Modal and Form Code Close --------------------
import Addplayer from '../Player/Addplayer';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
// Table code start ---------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import DeleteIcon from '@mui/icons-material/Delete';
import dateFormat from 'dateformat';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';
// time and date -
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { MessageList } from '../../../constants/MessageConfig';
import Alert from '@mui/material/Alert';
import LogoutEvn from '../../../common/LogoutEvn';
import AddBoxIcon from '@mui/icons-material/AddBox';
// Code start for edit section -----
import { AddroomApi, DeleteRoomApi, getAllroomApi } from '../../../Api/api_calls/adminroomApis';
import { FIXCSV } from '../../../constants/constants';
import { CreatePlayerApi } from '../../../Api/api_calls/playerApi';
import { Link } from "react-router-dom"
import DateTimePicker from '@mui/lab/DateTimePicker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Code close for edit section -----
// Modal cose close --------------------
const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function Room(props) {
    const classes = useStyles();
    const { control } = useForm();
    const dispatch = useDispatch();
    const today = new Date();

    // state manage for modals (dialoge boxes)
    const [open, setOpen] = React.useState(false);
    const [Addopen, setAddopen] = React.useState(false);
    const [editStatus, setEditStatus] = React.useState(false);

    // state manage for Add room dialog box
    const [type, settype] = useState("");
    const [startdate, setstartdate] = useState(null);
    const [entryFee, setEntryFee] = useState('');
    const [roomCapacity, setRoomCapacity] = useState('');

    const [titleone, Settitleone] = useState('');
    const [titletwo, Settitletwo] = useState('');
    const [dateflag, setDateflag] = useState(false);
    console.log('startdate===', typeof(startdate))


    // state ,anage for get all room
    const [Roomlist, setRoomList] = useState([]);

    // state manage for delete particular room id
    const [roomId, setroomId] = useState('');

    const [editRoomObj, setEditRoomObj] = useState(null);

    // ----------------------------------------- Upload props code start ----------------------------------------
    const [fileName, setFileName] = useState('');
    const [selectRoom, setSelectRoom] = useState(null);
    const [csvStatus, setCsvStatus] = useState(true)
    const [csvErrorMesssage, setCsvErrorMesssage] = useState('')
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    // Modal code start ------
    const [openPropsModal, setOpenPropsModal] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    // ----------------------------------------- Upload props code close ----------------------------------------

    const logoutEvents = LogoutEvn()

    const handleChangeDate = (newValue) => {
        setstartdate(newValue);
        setDateflag(true);
    };


    // handle for modal (add room function fire on click addroom)l
    const handleAddOpen = () => {
        resetAll()
        setAddopen(true);
        setDateflag(false);
    };

    // handle for close room popup
    const handleClosedisagree = () => {
        setAddopen(false);
        setEditStatus(false);
        resetAll();
        setDateflag(false);
    };

    const resetAll = () => {
        Settitleone('')
        Settitletwo('')
        setstartdate(null)
        setEntryFee('')
        setRoomCapacity('')
        settype('')
    }

    // Api hit for add romm
    const AddPlayerroom = () => {
        let AddRoomdata = {}
        let roomtitleone = titleone.trim()
        let roomtitletwo = titletwo.trim()
        let titlefinal = roomtitleone.trim() + 'v-s' + roomtitletwo.trim()
        AddRoomdata['title'] = titlefinal.trim()
        AddRoomdata['matchType'] = type.trim()
        let newDATE = new Date(startdate).toISOString();
        let chaha = new Date(newDATE)
        console.log(chaha)
        // console.log(newDate)
        AddRoomdata['startDateTime'] = startdate
        AddRoomdata['entryFee'] = parseInt(entryFee)
        AddRoomdata['maxPlayer'] = parseInt(roomCapacity)

        if (!startdate) {
            notifyService.info('Please choose date and time')
            return
        }
        if (startdate < today) {
            notifyService.info('You are not allowed to select past time')
            return
        }
        if (!AddRoomdata.maxPlayer) {
            notifyService.info('Please choose room capacity')
            return
        }
        if (AddRoomdata.maxPlayer === '0') {
            notifyService.info('Room capacity should be greater than zero')
            return
        }
        if (AddRoomdata.entryFee < 0) {
            notifyService.info('Please choose entry fee')
            return
        }
        if (!roomtitleone.trim()) {
            notifyService.info('Please choose first title')
            return
        }
        if (!roomtitletwo.trim()) {
            notifyService.info('Please choose second title')
            return
        }




        if (!AddRoomdata.title.trim()) {
            notifyService.info('Please choose Title')
            return
        }
        if (!AddRoomdata.matchType.trim()) {
            notifyService.info('Please choose type')
            return
        }

        let alphaExp = /^[a-zA-Z\s]*$/
        if (!AddRoomdata.matchType.match(alphaExp)) {
            notifyService.info('Only alphabets allowed in type')
            return
        }

        if (!entryFee) {
            notifyService.info('Please choose entry fee')
            return
        }

        handleClosedisagree()
        dispatch(loaderStatus(true));
        AddroomApi(AddRoomdata).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.code === 400) {
                handleClosedisagree()
                notifyService.info(res?.data?.message)
            } else {
                notifyService.success(MessageList.ROOM_SUCCESS)
                handleClosedisagree()
                getAllroomMethod()
            }

        }).catch(err => {

            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })

    }

    // Api hit for edit romm
    const EditRoomUpdate = () => {
        let AddRoomdata = {}
        let roomtitleone = titleone
        let roomtitletwo = titletwo
        let titlefinal = roomtitleone.trim() + 'v-s' + roomtitletwo.trim()


        AddRoomdata['title'] = titlefinal.trim()
        AddRoomdata['matchType'] = type.trim()
        AddRoomdata['startDateTime'] = startdate
        if (entryFee === 0) {
            AddRoomdata['entryFee'] = 0
        } else {
            AddRoomdata['entryFee'] = parseInt(entryFee)
        }
        AddRoomdata['maxPlayer'] = parseInt(roomCapacity)
        AddRoomdata['_id'] = editRoomObj._id

        if (!startdate) {
            notifyService.info('Please choose date and time')
            return
        }
        if (startdate < today) {
            notifyService.info('You are not allowed to select past time')
            return
        }


        if (AddRoomdata.entryFee < 0) {
            notifyService.info('Please choose entry fee')
            return
        }
        if (!AddRoomdata.maxPlayer) {
            notifyService.info('Please choose room capacity')
            return
        }
        if (AddRoomdata.maxPlayer === '0') {
            notifyService.info('Room capacity should be greater than zero')
            return
        }
        if (!roomtitleone.trim()) {
            notifyService.info('Please choose first title')
            return
        }
        if (!roomtitletwo.trim()) {
            notifyService.info('Please choose second title')
            return
        }

        if (!AddRoomdata.matchType.trim()) {
            notifyService.info('Please choose type')
            return
        }
        let alphaExp = /^[a-zA-Z\s]*$/
        if (!AddRoomdata.matchType.match(alphaExp)) {
            notifyService.info('Only alphabets allowed in type')
            return
        }

        handleClosedisagree()
        dispatch(loaderStatus(true));
        AddroomApi(AddRoomdata).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.code === 400) {
                handleClosedisagree()
                notifyService.info(res?.data?.message)
            } else {
                notifyService.success(MessageList.ROOM_UPDATE_SUCCESS)
                handleClosedisagree()
                getAllroomMethod()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    // rendering for getall room list
    useEffect(() => {
        getAllroomMethod()
        // eslint-disable-next-line
    }, [])


    // Get All room api
    function getAllroomMethod() {
        dispatch(loaderStatus(true));
        getAllroomApi().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setRoomList(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            // logoutEvents()
        })
    }

    // // handle for delete room
    // function handleRoomDelete(data) {
    //     let roomId = data._id
    //     setroomId(roomId)
    //     setOpen(true);
    // }

    // handle for delete popup agree button 
    const deleteAgree = () => {
        if (roomId !== '') {
            setOpen(false);
            dispatch(loaderStatus(true));
            DeleteRoomApi(roomId).then(res => {
                if (res.status === 200) {
                    notifyService.success('Room deleted successfully')
                    getAllroomMethod();
                }
            }).catch(err => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
                logoutEvents()
            })
        } else {
            notifyService.info('Room id not found')
        }
    }

    const editRoom = (editData) => {
        setEditRoomObj(editData)
        Settitleone(editData?.title?.split("v-s")[0])
        Settitletwo(editData?.title?.split("v-s")[1])
        settype(editData?.matchType)
        setRoomCapacity(editData?.maxPlayer)
        setstartdate(new Date(editData?.startDateTime))
        setEntryFee(editData?.entryFee)
        setAddopen(true);
        setEditStatus(true)
    }



    // ----------------------------------------- Upload props code start ----------------------------------------
    // process CSV data
    const processData = dataString => {
        dispatch(loaderStatus(true));
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));
        setCsvStatus(true)
        setCsvErrorMesssage('')
        if (list.length === 0) {
            setCsvErrorMesssage(MessageList?.CSV_LENGTH)
            setCsvStatus(false)
        }
        if (columns[0]?.name != FIXCSV[0]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[1]?.name != FIXCSV[1]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[2]?.name != FIXCSV[2]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[3]?.name != FIXCSV[3]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        setData(list);
        setColumns(columns);
        dispatch(loaderStatus(false));
        handleClickOpenPropsModal()
    }

    // handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        // setSelectRoom(selectData)
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const adddata = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(adddata);
        };
        reader.readAsBinaryString(file);
    }

    // modal code start --------
    const handleClickOpenPropsModal = () => {
        setOpenPropsModal(true);
    };
    const reloadClose = () => {
        window.location.reload()
        setOpenPropsModal(false);
        resetEverything()
    }
    const resetEverything = () => {
        setOpenPropsModal(false)
        setAddopen(false)
        setOpen(false)
        setColumns([])
        setData([])
        setFileName('')
    }

    const restEvery = () => {
        window.location.reload()
    }

    const handleUploadCsv = async () => {
        let getData = await data
        let roomId = selectRoom?._id
        if (!roomId) {
            notifyService.error('Room id not found !')
            return
        }
        if (getData.length < 50) {
            notifyService.error('Minimum 50 props are required')
        } else {
            setOpenPropsModal(false);
            let sendData = getData.map(item => {
                return {
                    type: item['Type'],
                    name: item['Player/Team'],
                    period: item['Period'],
                    points: item['Point'],
                    roomId: roomId
                };
            });
            dispatch(loaderStatus(true));
            CreatePlayerApi(sendData, roomId).then(res => {
                dispatch(loaderStatus(false));
                notifyService.success(MessageList.CSV_UPLOAD_SUCCESS)
                reloadClose()
            }).catch(err => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
                logoutEvents()
            })
        }
    }

    const selectRoomFile = (selectData) => {
        setSelectRoom(null)
        setSelectRoom(selectData)
    }

    const checkcuurentdate = (row) => {
        const todayDate = new Date();
        const checkDate = new Date(row?.startDateTime);
        if (checkDate < todayDate) {
            return (
                <EditIcon className="notAllow" />
            )
        }
        else {
            if (row.isStart) {
                return (<EditIcon className="notAllow" />)
            } else if (row.isOver == true) {
                return (<EditIcon className="notAllow" />)
            }
            else {
                return (
                    <EditIcon className="cursorPoint"
                        onClick={() => editRoom(row)}
                    />
                )
            }
        }
    }

    const checkcuurentdateEdit = (row) => {
        const todayDate = new Date();
        const checkDate = new Date(row?.startDateTime);

        if (checkDate < todayDate) {
            return (
                <Link variant="outlined" size="small" className="notAllow">
                    <EditIcon fontSize="small" />Edit
                </Link>
            )
        }
        else {
            if (row.isStart) {
                return (<Link variant="outlined" size="small" className="notAllow">
                    <EditIcon fontSize="small" />Edit
                </Link>)
            } else if (row.isOver == true) {
                return (
                    <Link variant="outlined" size="small" className="notAllow"><EditIcon fontSize="small" />Edit
                    </Link>
                )
            }
            else {
                return (
                    <Link variant="outlined" size="small" className="tableText" to={"/edit_table?id=" + row._id}>
                        <EditIcon fontSize="small" />Edit
                    </Link>
                )
            }
        }

    }

    // ----------------------------------------- Upload props code close ----------------------------------------
    return (
        <div >
            <Grid container spacing={3} direction="row">
                <Grid item xs={9} >
                    <Box component="div" display="inline" mt={2} className="mainHeading" size="small">Rooms</Box>
                </Grid>
                <Grid item xs={3} direction="row" className="textEnd">
                    <Button variant="contained" onClick={handleAddOpen} className="buttonTheme buttonFontTheme" size='small' endIcon={<AddCircleIcon />}>Add room</Button>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Title</TableCell>
                                    {/* <TableCell align="center">Points</TableCell> */}
                                    <TableCell align="center" className="tableHeading">Type</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Date</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Time</TableCell>
                                    <TableCell align="center" className="tableHeading">Room Action</TableCell>
                                    <TableCell align="center" className="tableHeading">Props Action</TableCell>
                                    <TableCell align="center" className="tableHeading">Props Uploaded</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Roomlist && Roomlist?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {/* {row?.title.split("v-s")[0]}&nbsp;<span className="title-vs">VS</span>&nbsp;{row?.title.split("v-s")[1]} */}
                                            {
                                                row?.title?.split("v-s")[0].length > 20 || row?.title?.split("v-s")[1].length > 20 ?
                                                    <Grid class="tableText bigger-text">
                                                        <span className='lfttitle'>{row?.title?.split("v-s")[0]}</span>&nbsp;
                                                        <span style={{ paddingLeft: '45px' }} className="title-vs">VS</span>&nbsp;
                                                        <span className='rgttitle'> {row?.title?.split("v-s")[1]}
                                                        </span>
                                                    </Grid>
                                                    :
                                                    <Grid class="tableText">
                                                        <span className='lfttitle'>{row?.title?.split("v-s")[0]}</span>&nbsp;
                                                        <span className="title-vs">VS</span>&nbsp;
                                                        <span className='rgttitle'>{row?.title?.split("v-s")[1]} </span>
                                                    </Grid>
                                            }

                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row.matchType}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row.startDateTime, "mmmm dS, yyyy")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row.startDateTime, "h:MM TT")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            <Box component="div" display="inline">
                                                {checkcuurentdate(row)}
                                            </Box>
                                            {/* <Box component="div" display="inline">
                                                <DeleteIcon className="deleteIcon"
                                                    onClick={() => handleRoomDelete(row)}
                                                />
                                            </Box> */}
                                        </TableCell>
                                        <TableCell align="center">
                                            <label htmlFor="contained-button-file">
                                                <Input accept=".csv" id="contained-button-file" multiple type="file" onChange={(e) => handleFileUpload(e)} />
                                                {row?.isProps ? '' : <Button variant="outlined" className="buttonTheme" component="span" size="small" onClick={() => selectRoomFile(row)} >
                                                    Add
                                                </Button>
                                                }

                                            </label>&nbsp;&nbsp;
                                            {
                                                row?.isProps ? <>
                                                    {checkcuurentdateEdit(row)}
                                                </>
                                                    : <Link variant="outlined" size="small" className="notAllow">
                                                        <EditIcon fontSize="small" />Edit
                                                    </Link>
                                            }
                                            {/* <Button variant="outlined" size="small"  to={"/edit_table?id=" + row._id} startIcon={<EditIcon />}>
                                                Edit
                                            </Button> */}
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                row?.isProps ? <CheckBoxIcon className='buttonTheme'></CheckBoxIcon> : <span>❌</span>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            Roomlist.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Rooms Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={Addopen}
                onClose={handleClosedisagree}
                TransitionComponent={Transition}
                keepMounted
                className="custom-add"
                aria-labelledby="form-dialog-title"
            >{
                    editStatus ? <DialogTitle id="responsive-dialog-title" className="mainHeading">
                        {"Edit Room"}
                    </DialogTitle> :
                        <DialogTitle id="responsive-dialog-title" className="mainHeading">
                            {"Add Room"}
                        </DialogTitle>
                }
                <DialogContent>
                    <Controller
                        name="city"
                        control={control}
                        render={() => (
                            <>
                                <Grid item xs={12}>
                                    <Box pb={1}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={1}>
                                                <label className={classes.inputLabel} className="normalTextTheme">Start Date/Time*</label>
                                                <DateTimePicker
                                                    label="Date & Time"
                                                    disablePast={true}
                                                    minDate={today}
                                                    value={dateFormat(startdate, "mm/dd/yyyy, h:MM TT")}
                                                    onChange={handleChangeDate}
                                                    renderInput={(params) => <TextField {...params}
                                                        inputProps={{
                                                            min: 0,
                                                            max: 100,
                                                            step: 2,
                                                            onKeyDown: (e) => {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        value={startdate !== null ? dateFormat(startdate, "mm/dd/yyyy, h:MM TT") : ""}
                                                        disabled={true}
                                                    />
                                                    }
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <label className={classes.inputLabel} className="normalTextTheme">Entry Fee*</label>
                                <TextField
                                    margin="dense"
                                    type="number"
                                    label="Entry Fee"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        inputProps: { min: 0 }
                                    }}
                                    value={entryFee}
                                    onKeyPress={(event) => {
                                        if (event?.key === '-' || event?.key === '+') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => setEntryFee(e.target.value)}
                                />
                                <label className={classes.inputLabel} className="normalTextTheme">Room Capacity*</label>
                                <TextField
                                    margin="dense"
                                    type="number"
                                    label="Room Capacity"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={roomCapacity}
                                    onKeyPress={(value) => {
                                        if (value?.key === '-' || value?.key === '+') {
                                            value.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => setRoomCapacity(e.target.value)}
                                />
                                <label className={classes.inputLabel} className="normalTextTheme">Title*</label><br />

                                <Grid container>
                                    <Grid xs={5} style={{ marginLeft: -8 }} className='textCenter'>
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            variant="outlined"
                                            label="First"
                                            size="small"
                                            value={titleone}
                                            onChange={(e) => Settitleone(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid xs={2} className='textCenter'>
                                        <Box pt={2}>
                                            <span className="table-vs">VS</span>
                                        </Box>
                                    </Grid>
                                    <Grid xs={5} className='textCenter'>
                                        <TextField
                                            style={{ marginRight: -30 }}
                                            margin="dense"
                                            type="text"
                                            variant="outlined"
                                            label="Second"
                                            size="small"
                                            value={titletwo}
                                            onChange={(e) => Settitletwo(e.target.value)}
                                        /><br />
                                    </Grid>
                                </Grid>
                                <label className={classes.inputLabel} className="normalTextTheme">Type*</label>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    type="text"
                                    size="small"
                                    label="Type"
                                    variant="outlined"
                                    value={type}
                                    onChange={(e) => settype(e.target.value)}
                                />
                            </>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosedisagree} className='modalCommonButton'>
                        cancel
                    </Button>
                    {
                        editStatus ? <Button onClick={EditRoomUpdate} autoFocus className='modalCommonButton'>
                            Update
                        </Button> :
                            <Button onClick={AddPlayerroom} autoFocus className='modalCommonButton'>
                                Save
                            </Button>
                    }

                </DialogActions>
            </Dialog>
            <Dialog onClose={resetEverything} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" className="mainHeading">
                    Delete Room
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        <Alert severity="warning" className="normalTextTheme">Are you sure you want to delete the room ?</Alert>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetEverything} className='modalCommonButton'>Disagree</Button>
                    <Button onClick={deleteAgree} autoFocus className='modalCommonButton'>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                className="modalHight"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openPropsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="mainHeading">{"Props preview"}</DialogTitle>
                <DialogContent>
                    <Box p={1}>
                        <span className="setFile normalTextTheme">&nbsp;File&nbsp;&nbsp;</span><Chip label={fileName} className='normalTextTheme' />
                    </Box>
                    <Addplayer data={data} dataHeader={columns} csvStatusSend={csvStatus} statusMessage={csvErrorMesssage} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={restEvery} className='modalCommonButton'>Cancel</Button>
                    {
                        (() => {
                            if (csvStatus) {
                                return <Button onClick={handleUploadCsv} className='modalCommonButton'>Upload</Button>
                            }
                        })()
                    }
                </DialogActions>
            </Dialog>

        </div>
    )
}
