
//
// (-- Api Config Constants --)
//
const API_URL = "https://stampchampbingo.com:8000/api/v1/";   

const AUTHTOKEN = "bingotoken"
const AUTHORIZATION_HEADER = "x-access-token"
const STAGINGURL = "https://stampchampbingo.com:8000"

// const LOCALURL = "http://localhost"

export const ApiConfig = { API_URL, AUTHTOKEN, AUTHORIZATION_HEADER };

// (-- URL Constants --)

// ------------------ User Side Api Calls Start --------------------------

//Auth Api Urls
const SIGNUP_URL = STAGINGURL + "/api/v1/users/createAccount"
const LOGIN_URL = STAGINGURL + "/api/v1/users/login"

// send otp Api Url
const SEND_OTP_URL = STAGINGURL + "/api/v1/users/otp"

// match otp Api Url
const MATCH_OTP_URL = STAGINGURL + "/api/v1/users/matchOtp"

// forgot pssword Api url
const FORGOT_PASSWORD_URL = STAGINGURL + "/api/v1/users/forgotPassword"
const FORGOT_PASSWORD_UPDATE_URL = STAGINGURL + "/api/v1/users/newPassword"

// chooseroom Api url
const GET_CURRENTROOM_URL = STAGINGURL + "/api/v1/dashboard/getEnterRoom?todayDate="

// Profile image upload -
const UPLOAD_PROFILE_IMAGE_URL = STAGINGURL + "/api/v1/users/profileImage"
const GET_PROFILE_URL = STAGINGURL + "/api/v1/users/"
const UPDATE_PROFILE_URL = STAGINGURL + "/api/v1/users/createAccount"
const CHANGE_PASSWORD_URL = STAGINGURL + "/api/v1/users/changePassword"
const VENMO_CHANGE_URL = STAGINGURL + "/api/v1/users/changeVenmo"
const DELETE_USER_URL = STAGINGURL + "/api/v1/users/inActiveAccount/"
const DELETE_USER_IMAGE = STAGINGURL + "/api/v1/users/deleteProfileImage/"

// Card Apis --
const GET_ROOM_DETAILS_USER_URL = STAGINGURL + "/api/v1/userCard/getPropsByRoomId/"
const UPDATE_PROPS = STAGINGURL + "/api/v1/admin/editPlayer"

// user history api 
const GET_USER_BINGO_HISTORY = STAGINGURL + "/api/v1/admin/getUserCardHistory/"

// user history api 
const GENERATE_REFFERAL = STAGINGURL + "/api/v1/users/generateReferal"


// ------------------ User Side Api Calls End   --------------------------




// ------------------ Admin Side Api Calls Start --------------------------
// Add Team Urls
const ADD_TEAM_URL = STAGINGURL + "/api/v1/admin/team/newTeam"
const GET_TEAM_URL = STAGINGURL + "/api/v1/admin/team/getData"

const ADD_PLAYER_URL = STAGINGURL + "/api/v1/admin/newPlayer/"
const GET_PLAYER_URL = STAGINGURL + "/api/v1/admin/getAllPlayers"
const UPDATE_PLAYER_URL = STAGINGURL + "/api/v1/admin//editPlayer"
const DELETE_PLAYER_URL = STAGINGURL + "/api/v1/admin/"

// Add room Apis Urls
const ADD_ROOM_URL = STAGINGURL + "/api/v1/admin/room"
const GET_ROOM_URL = STAGINGURL + "/api/v1/admin/getAllRooms"
const DELETE_ROOM_URL = STAGINGURL + "/api/v1/admin/deleteRomm/"
const GET_ROOM_BY_ID = STAGINGURL + "/api/v1/admin/getRoomById/"

// All users api Urls   
const GET_ALL_USERS_URL = STAGINGURL + "/api/v1/admin/getAllUsers"
const GET_USER_BY_ID = STAGINGURL + "/api/v1/admin/getUserById/"
const EDIT_USER_BY_ID = STAGINGURL + "/api/v1/admin/editUser/"

const SAVE_USER_CARD = STAGINGURL + "/api/v1/userCard/submitCard"
const GET_USER_ROOM_DETAILS = STAGINGURL + "/api/v1/userCard/getUserCardByRoomIdAndUserId?uid="
const SEARCH_USERBY_EMAIL_USERNAME = STAGINGURL + "/api/v1/admin/userSearch?"
const SEARCH_USERBY_STATUS = STAGINGURL + "/api/v1/admin/userByStatus?"




// user venmo managment api urls
const USER_BALANCE_ADD = STAGINGURL + "/api/v1/admin/addBalance?venmo="
const GET_USERNAME_AND_VENMO = STAGINGURL + "/api/v1/admin/searchVenmo?"

// LeaderBoard info -
const MY_LEADERBOARD_INFO = STAGINGURL + "/api/v1/leaderBoard/getUserLeaderBoard?userCardId="
const COMMON_LEADERBOARD_INFO = STAGINGURL + "/api/v1/leaderBoard/generalLeaderBoard/"

// Add privacy policy Urls   
const ADD_PRIVACY_POLICY = STAGINGURL + "/api/v1/admin/appDetails/addPrivacyPolicy"
const GET_PRIVACY_POLICY = STAGINGURL + "/api/v1/admin/appDetails/getPrivacyPolicy"

// Add term and condition Urls
const ADD_TERMCONDITION = STAGINGURL + "/api/v1/admin/appDetails/addTermAndCondition"
const GET_TERMSANDCONDITION = STAGINGURL + "/api/v1/admin/appDetails/getTermAndCondition"
const GET_DASHBOARD_DETAILS_USER = STAGINGURL + "/api/v1/dashboard/getLastUserCardHistory/"

// Total cards by room Url 
const GET_TOTAL_CARDSBYROOMS = STAGINGURL + "/api/v1/admin/geCardsByRoom"
const GET_TOTAL_USER_AND_REFERRALS = STAGINGURL + "/api/v1/admin/getAdminDashboardInfo"

// contact us Api urls 
const CONTACT_US = STAGINGURL + "/api/v1/users/contactUs"     
const GET_CONTACT_US = STAGINGURL + "/api/v1/admin/getUserQuery"     
const GET_FREEBALANCE = STAGINGURL + "/api/v1/admin/getFreeBalance"  
const EDIT_FREEBALANCE = STAGINGURL + "/api/v1/admin/freeBalance"  
// ------------------ Admin Side Api Calls End   --------------------------

// Pre-login api -
const OTP_SEND_API  =  STAGINGURL + '/api/v1/users/otp'
const OTP_MATCH  =  STAGINGURL + '/api/v1/users/matchOtp'
const NEW_PASSWORD_API  =  STAGINGURL + '/api/v1/users/newPassword'

const GET_USERDETAILSBYDATE  =  STAGINGURL +'/api/v1/admin/analatics'

// All Urls Exporting function
export const Url = {
  SIGNUP_URL,
  LOGIN_URL,
  SEND_OTP_URL,
  MATCH_OTP_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_UPDATE_URL,
  GET_CURRENTROOM_URL,
  GET_ROOM_DETAILS_USER_URL,
  SAVE_USER_CARD,
  GET_USER_ROOM_DETAILS,
  UPLOAD_PROFILE_IMAGE_URL,
  GET_PROFILE_URL,
  UPDATE_PROFILE_URL,
  CHANGE_PASSWORD_URL,
  VENMO_CHANGE_URL,
  GET_ROOM_BY_ID,
  DELETE_USER_URL,
  MY_LEADERBOARD_INFO,
  COMMON_LEADERBOARD_INFO,
  GET_DASHBOARD_DETAILS_USER,
  GET_USER_BINGO_HISTORY,
  GENERATE_REFFERAL,
  DELETE_USER_IMAGE,
  // -------------------Admin Side Api Urls Start---------------------------------
  ADD_TEAM_URL,
  GET_TEAM_URL,
  ADD_PLAYER_URL,
  GET_PLAYER_URL,
  UPDATE_PLAYER_URL,
  DELETE_PLAYER_URL,
  ADD_ROOM_URL,
  GET_ROOM_URL,
  DELETE_ROOM_URL,
  UPDATE_PROPS,
  GET_ALL_USERS_URL,
  GET_USER_BY_ID,
  EDIT_USER_BY_ID,
  USER_BALANCE_ADD,
  GET_USERNAME_AND_VENMO,
  ADD_PRIVACY_POLICY,
  GET_PRIVACY_POLICY,
  ADD_TERMCONDITION,
  GET_TERMSANDCONDITION,
  GET_TOTAL_CARDSBYROOMS,
  GET_TOTAL_USER_AND_REFERRALS,
  SEARCH_USERBY_EMAIL_USERNAME,
  SEARCH_USERBY_STATUS,
  GET_CONTACT_US,
  CONTACT_US,
  GET_FREEBALANCE,
  EDIT_FREEBALANCE,
  // -------------------Admin Side Api Urls End---------------------------------
  OTP_SEND_API,
  OTP_MATCH,
  NEW_PASSWORD_API,
  GET_USERDETAILSBYDATE
}