import { wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";

//--------------- Login api -------------------------
export const loginUser = (data) => {
    return wrappedFetch(Url.LOGIN_URL, "POST", data);
};

//--------------- signUp api -------------------------
export const signUp = (data) => {
    return wrappedFetch(Url.SIGNUP_URL, "POST", data);
};

