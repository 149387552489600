import { wrappedGet, wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const AddTermAndCondition = (data) => {
    return wrappedFetch(Url.ADD_TERMCONDITION, "POST", data);
}

export const GetTermAndCondition = () => {
    return wrappedGet(Url.GET_TERMSANDCONDITION, "GET");
};
