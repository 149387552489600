import { USER_BALANCE } from "../Actions/ActionTypes";

  const initialState = {
    venmoBalance: 0
  };

  const balanceReducer = (state = initialState, action) => {
    switch (action.type) {  
      case USER_BALANCE:
        return {
          venmoBalance: action.payload.venmoBalance
        };
      default:
        return state;
    }
  };
  export default balanceReducer;
  