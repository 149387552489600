import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
// --------- Table code start ---------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogoutEvn from '../../../common/LogoutEvn';
import { errorHandleService } from '../../../common';
import { GetContactus } from '../../../Api/api_calls/contactusApi';
const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));


export default function Userquery(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    // state ,manage for get all room list
    const [userqueries, setuserqueries] = useState([]);

    const logoutEvents = LogoutEvn()

    // rendering for get all users query
    useEffect(() => {
        Getallqueriesmethod()
        // eslint-disable-next-line
    }, [])

    // Get All room api
    function Getallqueriesmethod() {
        dispatch(loaderStatus(true));
        GetContactus().then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                setuserqueries(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={9}>
                    <Box component="div" style={{ fontWeight: 700 }} display="inline" mt={3} className="mainHeading" size="small">User Queries</Box>
                </Grid>

                <Grid item xs={12} className="setPadding "><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Email</TableCell>
                                    <TableCell align="center" className="tableHeading">Username</TableCell>
                                    <TableCell align="center" className="tableHeading"></TableCell>

                                    <TableCell align="start" className="tableHeading">Query</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userqueries && userqueries?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {row?.email}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.userName}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                        </TableCell>
                                        <TableCell align="start" className="tableText">
                                            {row?.query}
                                        </TableCell>
                                    <TableCell align="center" className="tableText"></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            userqueries.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Queries Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

