import {
    LOADER_STATUS
} from "../Actions/ActionTypes";

const initialState = {
    status: false
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER_STATUS:
            return { status: action.payload };
        default:
            return state;
    }
};

export default loaderReducer;