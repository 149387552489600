export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOADER_STATUS = 'LOADER_STATUS'


//User-Details 
export const USER_DETAILS_STORE = "USER_DETAILS_STORE";

//User-card-room 
export const CARD_R00M_DETAILS_STORE = "CARD_R00M_DETAILS_STORE";

// To Store balance -
export const USER_BALANCE = "USER_BALANCE";
