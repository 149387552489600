import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Base setup - 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
//--------- Table code start ---------
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dateFormat from 'dateformat';
import LogoutEvn from '../../../common/LogoutEvn';
import { notifyService, errorHandleService } from '../../../common';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router-dom"
import { Edituserbyid, getAllusersapi, Getuserbyemailandusername, Searchuserbystatus } from '../../../Api/api_calls/adminAllusersApi';
import { MessageList } from '../../../constants/MessageConfig';
import { Getuserbingohistory } from '../../../Api/api_calls/userhistoryApi';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
}));

export default function AllUsers(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { control } = useForm();
    const logoutEvents = LogoutEvn()
    let history = useHistory();
    // state manage for get all Users
    const [Userslist, setUsersList] = useState([]);
    const [pageCount, setPageCound] = useState(1)
    const [defpage, setDefpage] = useState(1)
    // state manage for popup
    const [open, setopen] = React.useState(false);
    // state manage for edit user data
    const [userdata, setusereditdata] = useState(null)
    const [email, setemail] = useState('')
    const [name, setname] = useState('')
    const [username, setusername] = useState('')
    const [venomhandle, setvenmohandle] = useState('')
    const [number, setnumber] = useState('')
    const [statusactive, setstatusactive] = useState('')
    // state manage for search user by email
    const [searchemail, setsearchemail] = useState('')
    const [searchusername, setsearchusername] = useState('')
    // statmanage for user status
    const [userstatus, setuserstatus] = useState('')
    const [apilength, setapilength] = useState('')


    // handle for active & inactive textfield
    const handleChange = (event) => {
        setstatusactive(event.target.value);
    };

    // Binding single user data 
    const UsereditbyId = (edituser) => {
        setusereditdata(edituser?._id)
        setemail(edituser?.email)
        setname(edituser?.name)
        setusername(edituser?.userName)
        setvenmohandle(edituser?.venmo)
        let status = (edituser?.isActive)
        setstatusactive(status)
        setnumber(edituser?.phone)
        setopen(true);
    }

    // Edit user by id api hit
    const updateuserById = () => {
        let edituserdata = {}
        edituserdata['email'] = email
        edituserdata['name'] = name
        edituserdata['userName'] = username
        edituserdata['venmo'] = venomhandle
        edituserdata['phone'] = number
        edituserdata['isActive'] = statusactive
        edituserdata['_id'] = userdata
        edituserdata['isVerfied'] = true

        // validation for email field
        if (!edituserdata.name) {
            notifyService.info(MessageList.ALL_USER_NAME)
            return
        }

        let alphaExp = /^[a-zA-Z\s]*$/
        if (!edituserdata.name.match(alphaExp)) {
            notifyService.info(MessageList.ALL_USER_ALPHABAT)
            return
        }
        // validation for username field
        if (!edituserdata.userName) {
            notifyService.info(MessageList.ALL_USER_USERNAME)
            return
        }

        // venmo handle validations
        if (!edituserdata.venmo) {
            notifyService.info(MessageList.ALL_USER_VENMO)
            return
        }
        if (edituserdata.venmo.length < 5) {
            notifyService.info(MessageList.ALL_USER_VENMO_VALIDATION)
            return
        }
        if (edituserdata.venmo.length > 16) {
            notifyService.info(MessageList.ALL_USER_VENMO_VALIDATION)
            return
        }

        if (!edituserdata.venmo.match(/^[a-zA-Z0-9_@-]+$/)) {
            notifyService.info(MessageList.ALL_USER_VENMO_CHARACTRS)
            return
        }

        // phone number validations
        if (edituserdata.phone) {
            if ((edituserdata.phone.length < 10) || (edituserdata.phone.length > 10)) {
                notifyService.info(MessageList.ALL_USER_PHONE_ERROR)
                return
            }
        }
        dispatch(loaderStatus(true));
        Edituserbyid(edituserdata, edituserdata?._id).then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) {
                notifyService.success(MessageList.ALL_USER_SUCCESS)
                resetPagination()
                handleClosedisagree()
                getAllusersMethod(10, 1, 0)
            } else {
                notifyService.error(res.data.message)
            }

        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    //   handle for close popup
    const handleClosedisagree = () => {
        setopen(false);

    };


    const resetPagination = () => {
        setPageCound(10)
        setDefpage(1)
    }


    // get all users api hit 
    function getAllusersMethod(limitSet, nppSet, pageSet) {
        let userparams = {}
        userparams['limit'] = limitSet
        userparams['npp'] = limitSet
        userparams['page'] = pageSet
        dispatch(loaderStatus(true));
        getAllusersapi(userparams).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status === 200) {
                setapilength(res?.data?.data?.results?.length)
                if (res?.data?.data?.results?.length == 10) {
                    setPageCound(nppSet + 1)
                } else {
                    setPageCound(nppSet)
                }
                setUsersList(res?.data?.data?.results)
                setuserstatus('')
            } else {
                setUsersList([]);
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const Onpagechanges = (event, value) => {
        let valueSet = 0;
        valueSet = value - 1
        setDefpage(value)
        getAllusersMethod(10, value, valueSet)

    }
    const resetPage = () => {
        // setPage(1)
        setDefpage(1)
        getAllusersMethod(10, 1, 0)
    }

    // api method for getting single user bingo history 
    const userbingohistory = (data) => {
        let Singleuserid = data?._id
        dispatch(loaderStatus(true));
        Getuserbingohistory(Singleuserid).then(res => {
            if (res?.data?.data?.length > 0) {
                dispatch(loaderStatus(false));
                history.push({
                    pathname: "/all_users/userhistory",
                    state: { details: res?.data?.data }
                });
            }
            else {
                dispatch(loaderStatus(false));
                notifyService.info(MessageList.ALL_USER_NO_CARD)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })

    }


    const searchbyemail = () => {
        if (searchemail || searchusername) {
            searchbyemailandusername(searchemail, searchusername)
        }
    }


    // handle for search user by username and venmo
    function searchbyemailandusername(Getsearchemail, Getsearchusername) {
        dispatch(loaderStatus(true));
        Getuserbyemailandusername(Getsearchemail, Getsearchusername).then(res => {
            dispatch(loaderStatus(false));
            if (res.status === 200) {
                setUsersList(res?.data?.data)
                setsearchemail('')
                setsearchusername('')
            } else {
                notifyService.info(MessageList.ALL_USER_NO_FOUND)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const handleChangestatus = (event) => {
        setuserstatus(event.target.value);
        let statusvalue = event.target.value
        let valuecheck = true;
        if (statusvalue === 'false') {
            valuecheck = false
        }
        if (statusvalue === 'true') {
            valuecheck = true
        }
        getsearchuserbystatus(valuecheck)
    }


    // rendering for getall Users list
    useEffect(() => {
        getAllusersMethod(10, 1, 0)
        // eslint-disable-next-line
    }, [])


    //Search user by status
    function getsearchuserbystatus(valuecheck) {
        dispatch(loaderStatus(true));
        Searchuserbystatus(valuecheck).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setUsersList(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            // logoutEvents()
        })
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={2}>
                    <Box component="div" display="inline" mt={3} className="mainHeading" size="small">All Users</Box>
                </Grid>
                <Grid item xs={2}>
                    <FormControl className={classes.formControl} fullWidth={true} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={userstatus}
                            onChange={handleChangestatus}
                            label="Status"
                        >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8} container justifyContent="center"  >

                    <Box pr={2}>
                        <input type="search" class="form-control rounded" placeholder="Search by Email" aria-label="Search"
                            value={searchemail} onChange={(e) => setsearchemail(e.target.value)}
                            aria-describedby="search-addon" />
                    </Box>
                    <Box pr={2}>
                        <input type="search" class="form-control rounded" placeholder="Search by User id" aria-label="Search"
                            value={searchusername} onChange={(e) => setsearchusername(e.target.value)}
                            aria-describedby="search-addon" />
                    </Box>
                    <Button variant="contained" className="buttonTheme" size='small' onClick={searchbyemail} endIcon={<SavedSearchIcon />}>Search</Button>&nbsp;&nbsp;
                    <Button variant="contained" className="buttonTheme" size='small' onClick={resetPage} endIcon={<RestartAltIcon />}>Reset</Button>
                </Grid>

                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Email</TableCell>
                                    <TableCell align="center" className="tableHeading">Name</TableCell>
                                    <TableCell align="center" className="tableHeadingwithoutransform" >User id</TableCell>
                                    <TableCell align="center" className="tableHeading">Venmo</TableCell>
                                    <TableCell align="center" className="tableHeading">Phone No</TableCell>
                                    <TableCell align="center" className="tableHeading">Status</TableCell>
                                    <TableCell align="center" className="tableHeading">Date</TableCell>
                                    <TableCell align="center" className="tableHeading">Action</TableCell>
                                    <TableCell align="center" className="tableHeading">History</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Userslist?.map && Userslist?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {row?.email} &nbsp;&nbsp;&nbsp;
                                            {(() => {
                                                if (row?.isVerfied === true) {
                                                    return (
                                                        <span>✅</span>
                                                    )
                                                } else {
                                                    return ''

                                                }
                                            })()}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.name}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.userName}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.venmo}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.phone}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {(() => {
                                                if (row?.isActive === true) {
                                                    return (
                                                        <Box style={{ color: 'green' }}>Active</Box>
                                                    )
                                                } else {
                                                    return (
                                                        <Box style={{ color: 'red' }}>Inactive</Box>
                                                    )
                                                }
                                            })()}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {dateFormat(row?.createdAt, "m/d/yy")}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            <Box component="div" display="inline">
                                                <EditIcon className="cursorPoint"
                                                    onClick={() => UsereditbyId(row)}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            <Box>
                                                <Button variant="contained" className="buttonTheme" size='small' endIcon={<VisibilityIcon />}
                                                    onClick={() => userbingohistory(row)}
                                                >
                                                    Show
                                                </Button>
                                            </Box>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            Userslist.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Users Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
                <Grid container justifyContent="right">
                    <Box pt={1}>
                        {
                            Userslist.length > 9 ? <Pagination count={pageCount} defaultPage={1} page={defpage} onChange={Onpagechanges} /> :
                                <Pagination defaultPage={1} page={defpage} onChange={Onpagechanges} hideNextButton={true} />
                        }

                    </Box>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClosedisagree}
                    keepMounted
                    className="custom-add"
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title" className="mainHeading">Edit User</DialogTitle>
                    <DialogContent>
                        <Controller
                            name="city"
                            control={control}
                            render={() => (
                                <>
                                    <label className={classes.inputLabel} className="normalTextTheme">Email*</label>
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        label="Email"
                                        disabled={true}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                    <label className={classes.inputLabel} className="normalTextTheme">Name*</label>
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={name}
                                        onChange={(e) => setname(e.target.value)}
                                    />
                                    <label className={classes.inputLabel} className="normalTextThemewithouttransform">User id*</label>
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        label="User id"
                                        disabled={true}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={username}
                                        onChange={(e) => setusername(e.target.value)}
                                    />
                                    <label className={classes.inputLabel} className="normalTextTheme">Venmo Handle*</label>
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        label="Venmo Handle"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={venomhandle}
                                        onChange={(e) => setvenmohandle(e.target.value)}
                                    />
                                    <label className={classes.inputLabel} className="normalTextTheme">Phone Number</label>
                                    <TextField
                                        margin="dense"
                                        type="number"
                                        label="Phone Number"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={number}
                                        onChange={(e) => setnumber(e.target.value)}
                                    />
                                    <label className={classes.inputLabel} className="normalTextTheme">Status</label>
                                    <Box pt={2}>
                                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true} size="small">
                                            <InputLabel id="demo-simple-select-outlined-label" className="normalTextTheme">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={statusactive}
                                                onChange={handleChange}
                                                label="Status"
                                            >
                                                <MenuItem value={true}>Active</MenuItem>
                                                <MenuItem value={false}>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClosedisagree} className='modalCommonButton'>
                            cancel
                        </Button>
                        <Button autoFocus onClick={updateuserById} className='modalCommonButton'>
                            Update
                        </Button>

                    </DialogActions>
                </Dialog>
            </Grid>
        </div>
    )
}
