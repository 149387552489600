import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Base setup - 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
//--------- Table code start ---------
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogoutEvn from '../../../common/LogoutEvn';
import { notifyService, errorHandleService } from '../../../common';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import { getAllusersapi } from '../../../Api/api_calls/adminAllusersApi';
import { MessageList } from '../../../constants/MessageConfig';
import { AddUserBalance, Getuserbyvenmoandusername } from '../../../Api/api_calls/venmohandleApi';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({

}));


export default function Venmomanagment(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { control } = useForm();
    const logoutEvents = LogoutEvn()

    // state manage for get all Users
    const [Userslist, setUsersList] = useState([]);
    const [, setPage] = useState('')
    const [pageCount, setPageCound] = useState(2)
    const [defpage, setDefpage] = useState(1)

    const [open, setopen] = React.useState(false);

    // state manage for edit user data
    const [addbalance, setaddbalance] = useState('')
    const [uservenmo, setuservenmo] = useState('')
    const [userid, setuserid] = useState('')
    const [searchvenmo, setsearchvenmo] = useState('')
    const [searchusername, setsearchusername] = useState('')
    const [apilength, setapilength] = useState('')

    // handle for close popup
    const handleClosedisagree = () => {
        setopen(false);
        setaddbalance('')
    };

    // rendering for getall Users list
    useEffect(() => {
        getAllusersMethod(10, 1, 0)
        // eslint-disable-next-line
    }, [])

    // handle for search by venmo and username
    const searchbyvenmo = () => {
        if (searchvenmo || searchusername) {
            searchbyvenmoandusername(searchvenmo, searchusername)
        }
    }

    // api method for search user by username and venmo
    function searchbyvenmoandusername(Getsearchvenmo, Getsearchusername) {
        dispatch(loaderStatus(true));
        Getuserbyvenmoandusername(Getsearchvenmo, Getsearchusername).then(res => {
            dispatch(loaderStatus(false));
            if (res.status === 200) {
                setUsersList(res?.data?.data)
                setsearchvenmo('')
                setsearchusername('')
            } else {
                notifyService.info('No user found')
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get all users api hit for show user vanmo handle 
    function getAllusersMethod(limitSet, nppSet, pageSet) {
        let userparams = {}
        userparams['limit'] = limitSet
        userparams['npp'] = limitSet
        userparams['page'] = pageSet
        dispatch(loaderStatus(true));
        getAllusersapi(userparams).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status === 200) {
                setapilength(res?.data?.data?.results?.length)
                if (res?.data?.data?.results?.length == 10) {
                    setPageCound(nppSet + 1)
                } else {
                    setPageCound(nppSet)
                }
                setUsersList(res?.data?.data?.results)
            } else {
                setUsersList([]);
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // handle for pagination on page change
    const Onpagechanges = (event, value) => {
        let valueSet = 0;
        valueSet = value - 1
        setPage(value)
        setDefpage(value)
        getAllusersMethod(10, value, valueSet)
        
    }

    const resetPagination = () => {
        setPageCound(10)
        setDefpage(1)
    }

    // single user details for add balance 
    const userdetailsbyid = (userdetails) => {
        setuserid(userdetails?._id)
        setuservenmo(userdetails?.venmo)
        setopen(true);
    }

    const resetPage = () => {
        setPage(1)
        setDefpage(1)
        getAllusersMethod(10, 1, 0)
    }
    // api handle for addbalance in user venmo
    function addvenmobalance() {
        let userbalance = {}
        userbalance['balance'] = parseInt(addbalance)
        userbalance['_id'] = userid
        userbalance['venmo'] = uservenmo

        if (!userbalance.balance) {
            notifyService.info('Please enter venmo balance')
            return
        }

        dispatch(loaderStatus(true));
        AddUserBalance(userbalance?.venmo, userbalance?._id, userbalance).then(res => {
            dispatch(loaderStatus(false));
            if (res.status === 200) {
                notifyService.success(MessageList.WALLET_MANAGE_UPDATE)
                resetPagination()
                handleClosedisagree()
                getAllusersMethod(10, 1, 0)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={3}>
                    <Box component="div" display="inline" mt={3} className="mainHeading" size="small">Manage Venmo</Box>
                </Grid>
                <Grid item xs={9} container justifyContent="end"  >
                    <Box pr={2}>
                        <input type="search" class="form-control rounded" placeholder="Search by Venmo" aria-label="Search" value={searchvenmo}
                            onChange={(e) => setsearchvenmo(e.target.value)}
                            aria-describedby="search-addon" />
                    </Box>
                    <Box pr={2}>
                        <input type="search" class="form-control rounded" placeholder="Search by User id" aria-label="Search" value={searchusername}
                            onChange={(e) => setsearchusername(e.target.value)}
                            aria-describedby="search-addon" />
                    </Box>
                    <Button variant="contained" className="buttonTheme" size='small' onClick={searchbyvenmo} endIcon={<SavedSearchIcon />}>Search</Button>&nbsp;&nbsp;
                    <Button variant="contained" className="buttonTheme" size='small' onClick={() => resetPage()} endIcon={<RestartAltIcon />}>Reset</Button>
                </Grid>

                <Grid item xs={12} className="setPadding "><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeadingwithoutransform">User id</TableCell>
                                    <TableCell align="center" className="tableHeading">Venmo Handle</TableCell>
                                    <TableCell align="center" className="tableHeading">Venmo Balance</TableCell>
                                    <TableCell align="center" className="tableHeading">Add Balance</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Userslist?.map && Userslist?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell className="tableText">
                                            {row?.userName}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.venmo}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            $&nbsp;{row?.venmoBalance.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            <Box component="div" display="inline">
                                                <Button variant="outlined" component="span" className="buttonTheme" size='small' endIcon={<AddCircleIcon />}
                                                    onClick={() => userdetailsbyid(row)}>
                                                    Add
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                        {
                            Userslist.length <= 0 ? <Grid item xs={12} className='tableHeading textCenter'><Box p={2}>No Users Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
                <Grid container justifyContent="right">
                    <Box pt={1}>
                        {
                            Userslist.length > 9 ? <Pagination count={pageCount} defaultPage={1} page={defpage} onChange={Onpagechanges} /> :
                                <Pagination defaultPage={1} page={defpage} onChange={Onpagechanges} hideNextButton={true} />
                        }
                    </Box>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClosedisagree}
                    keepMounted
                    className="custom-add"
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <Controller
                            name="city"
                            control={control}
                            render={() => (
                                <>
                                    <label className={classes.inputLabel} className="mainHeading">Add Balance*</label>
                                    <TextField
                                        margin="dense"
                                        type="number"
                                        label="Balance"
                                        fullWidth
                                        variant="outlined"
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                            }
                                        }}
                                        size="small"
                                        value={addbalance}
                                        onChange={(e) => setaddbalance(e.target.value)}
                                    />
                                </>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClosedisagree} className='modalCommonButton'>
                            cancel
                        </Button>
                        <Button autoFocus onClick={addvenmobalance} className='modalCommonButton'>
                            Add
                        </Button>

                    </DialogActions>
                </Dialog>




            </Grid>
        </div>
    )
}
