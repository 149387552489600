import React from 'react'
import HightOrderCom from '../Hoc/HightOrderCom'
import AdminProfile from './AdminProfile'
export default function AdminProfileHoc() {
    return (
        <div>
            <HightOrderCom cmp={AdminProfile}/>
        </div>
    )
}
