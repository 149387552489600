import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from '../../../redux/Actions/AuthAction'
import { Customjavascript } from '../../../../custom';
import { errorHandleService, notifyService } from "../../../common";
import { ROLE } from "../../../constants/constants";
import Navbar from '../../../common/headers/Navbar';
import { UserMessage } from "../../../constants/MessageConfig";
import { loaderStatus } from "../../../redux/Actions/LoaderAction";
import { AddContactus } from "../../../Api/api_calls/contactusApi";
export default function Login(props) {
    // state manage for login fields
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.Auth);
    // state manage for login fields
    const [userName, setusername] = useState("");
    const [password, setpassword] = useState("");

    // contact us query state manage
    const [contactemail, setcontactemail] = useState('')
    const [contactusername, setcontactusername] = useState('')
    const [contactquery, setcontactquery] = useState('')

    const [contactErrormesseges, setcontactErrormesseges] = useState('')

    // reset function after contactus
    const resetall = () => {
        setcontactemail('')
        setcontactusername('')
        setcontactquery('')
    }

    // state manage for login field errors
    const [loginpageerror, setloginpageerror] = useState("");
    useEffect(() => {
        Customjavascript();
    }, []);
    const LoginUser = () => {
        let emailVal = userName.trim()
        let passwordVal = password.trim()
        //  valudation for email input
        if (!emailVal) {
            setloginpageerror(UserMessage.LOGIN_MAIL_ENTER)
            return
        }
        // validations for password input
        if (!passwordVal) {
            setloginpageerror(UserMessage.LOGIN_PASSWORD_ENTER)
            return
        }

        dispatch(signIn(emailVal, passwordVal, props));
    };
    if (auth?.role === ROLE[0]) {
        props.history.push("/account");
        notifyService.success(UserMessage.LOGIN_USER_SUCCESS)
    }
    if (auth?.role === ROLE[1]) {
        props.history.push("/admin_landing");
        notifyService.success(UserMessage.LOGIN_ADMIN_SUCCESS)
    }
    // Validation handles for login fields
    // validations for username field
    const usernamevalidation = (event) => {
        setloginpageerror('')
        setusername(event.target.value);
    };
    // validations for password field
    const passwordvalidation = (event) => {
        setpassword(event.target.value);
        setloginpageerror('')
    };


    // contact us api method hit 
    const contactusmethod = () => {
        let contactusdata = {};
        contactusdata['userName'] = contactusername
        contactusdata['email'] = contactemail
        contactusdata['query'] = contactquery


        let Contactnamevalue = contactusername

        if (!Contactnamevalue) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_USERNAME)
            return
        }
        let alphaExp = /^[a-zA-Z\s]*$/
        if (!Contactnamevalue.match(alphaExp)) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_NAME_VALIDATION)
            return
        }

        if (!contactusdata.email) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_EMAIL)
            return
        }
        if (!contactusdata.query) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_QUERY)
            return
        }

        dispatch(loaderStatus(true));
        AddContactus(contactusdata).then(response => {
            dispatch(loaderStatus(false));
            if (response.data.result) {
                notifyService.success(UserMessage.QUERY_SUCCESS)
                document.querySelector('#contactUsLoge').click()
                resetall()
            }
            else {
                notifyService.error(response.data.message)
            }
        })
            .catch(err => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            })
    }

    const contactusernamevalidation = (event) => {
        setcontactusername(event.target.value);
        setcontactErrormesseges('')
    };
    const contactemailvalidation = (event) => {
        setcontactemail(event.target.value);
        setcontactErrormesseges('')
    };
    const contactqueryvalidation = (event) => {
        setcontactquery(event.target.value);
        setcontactErrormesseges('')
    };



    return (
        <div className="home">
            {/* common header for all modules */}
            <Navbar />
            <section className="banner">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-4 col-md-12">
                            <div className="signup-section">
                                <h2>Log In</h2>
                                <p className="ErrorNew">{loginpageerror}</p>
                                <form>
                                    <div className="textinput">
                                        <input type="text" id="Username" onChange={usernamevalidation} />
                                        <label for="Username">Email</label><br />
                                    </div>
                                    <div className="textinput">
                                        <input type="password" id="password" onChange={passwordvalidation} />
                                        <label for="password">Password</label><br />
                                    </div>
                                    <Link to="/forgotpassword" className="forgetpassword">
                                        Forgot Password?
                                    </Link>
                                    <button
                                        className="buttonsignup"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            LoginUser();
                                        }}
                                    >
                                        LOG IN
                                    </button>
                                    <div className="already-text">
                                        <p>
                                            Don't have an account?{" "}
                                            <Link to="/register">SIGN UP</Link>
                                            <p style={{ paddingTop: 3 }}><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#contactLogReg">Contact us</a></p>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="history-modal modal fade" id="contactLogReg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        </div>
                        <div class="modal-body">
                            <h2>Contact Us</h2>
                            <p className="ErrorNew">{contactErrormesseges}</p>
                            <form>
                                <div class="textinput">
                                    <input type="text" id="adminmail" value="Inquiries@stampchampbingo.com" className='filled cursorSet' disabled={true} />
                                    <label for="adminmail">Inquiry To</label>
                                </div>
                                <div class="textinput">
                                    <input type="text" id="password" value={contactusername} onChange={contactusernamevalidation} />
                                    <label for="password">Name</label>
                                </div>
                                <div class="textinput">
                                    <input type="email" id="newpassword" value={contactemail} onChange={contactemailvalidation} />
                                    <label for="newpassword">Email</label>
                                </div>
                                <div class="textinput textheight">
                                    <textarea rows="3" id="confirmpassword" value={contactquery} onChange={contactqueryvalidation}></textarea>
                                    <label for="confirmpassword">Query</label>
                                </div>
                                <button class="yes-submit" onClick={(e) => { e.preventDefault(); contactusmethod() }}>YES, SUBMIT</button>
                                <button class="no-submit" type="button" data-bs-dismiss="modal" id="contactUsLoge">NO, GO BACK</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}