import React, { useState, useEffect } from "react";
import { Customjavascript } from "../../../../custom";
import { Link } from "react-router-dom";
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { useDispatch } from "react-redux";
import axios from "axios";
import { notifyService, errorHandleService } from "../../../common";
import { forgotpasswordApi } from '../../../Api/api_calls/forgotpasswordApi'
import Navbar from "../../../common/headers/Navbar";
import { signOut } from "../../../redux/Actions/AuthAction";
import { withRouter } from 'react-router';
import { UserMessage } from "../../../constants/MessageConfig";
import { Url } from "../../../Api/api_setting/apiConstants"

function ForgotPassword(props) {
  useEffect(() => {
    Customjavascript();
  }, []);
  const dispatch = useDispatch();
  // state manage for forgot fields
  const [email, setEmail] = useState("");
  const [forgotdata, setforgotdata] = useState('')
  // state manage for validation errors show
  const [forgotpageError, setforgotpageError] = useState("");
  // state manage for forgotpassword update
  const [code, setcode] = useState("")
  const [newpasswrod, setnewpassword] = useState("")
  const [confirmpasswrod, setconfirmpasswrod] = useState("")


  // function for forgot password api hit 
  const ForgotPasswordFunction = () => {
    resetMethod()
    let data = {
      email: email,
    };
    let emailVal = email.trim()
    if (!emailVal) {
      setforgotpageError(UserMessage.FORGOT_EMAIL)
      return
    }
    dispatch(loaderStatus(true));
    forgotpasswordApi(data)
      .then((response) => {
        dispatch(loaderStatus(false));
        if (response.data.result) {
          document.querySelector('#changeModalId').click()
          setforgotdata(response?.data?.data)
          Sendotp(response?.data?.data)
        }
        else {
          notifyService.error(response?.data?.error)
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));
        notifyService.onErrorHandle()
      });
  };

  // api for send otp for forgot password user need an otp and onsuccess of (forgotpasswordApi) this api will implement 
  const Sendotp = (data) => {
    let otpdata = {
      email: data?.email,
      _id: data?._id,
    }
    const headers = {
      'x-access-token': data?.token
    };
    dispatch(loaderStatus(true));
    axios.post(Url?.OTP_SEND_API, otpdata, { headers })
      .then(response => {
        dispatch(loaderStatus(false));
        if (response?.status) {
          notifyService.success(UserMessage.FORGOT_OTP_SENT)
        } else {
          notifyService.error('Something went wrong')
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      });

  }


  // function for update forgot password api this api will implented onsuccess of match otp api 
  const ForgotPasswordupdate = (datainfo) => {
    let newpasswrodval = newpasswrod.trim()
    let confirmpasswrodval = confirmpasswrod.trim()
    // validation for new password
    if (!newpasswrodval) {
      setforgotpageError(UserMessage.FORGOT_ENTER_MAIL)
      return
    }

    let passwordformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
    if (!newpasswrodval.match(passwordformat)) {
      setforgotpageError(UserMessage.FORGOT_PASSWORD_VALIDATION)
      return
    }

    // validations for confirm password
    if (!confirmpasswrodval) {
      setforgotpageError(UserMessage.FORGOT_CONFIRM_PASSWORD)
      return
    }
    if (newpasswrodval !== confirmpasswrodval) {
      setforgotpageError(UserMessage.FORGOT_CONFIRM_SAME)
      return
    }
    let updateforgotdata = {
      newPassword: newpasswrod,
      confirmPassword: confirmpasswrod,
      _id: forgotdata?._id
    };
    const headers = {
      'x-access-token': forgotdata?.token,
    };
    document.querySelector('#closeModalPaswrod').click()
    dispatch(loaderStatus(true));
    axios.post(Url?.NEW_PASSWORD_API, updateforgotdata, { headers })
      .then(response => {
        dispatch(loaderStatus(false));
        if (response?.status) {
          dispatch(signOut());
          props.history.push("/login");
          notifyService.success(UserMessage.FORGOT_PASSWORD_CHANGE)
          dispatch(loaderStatus(false));
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      });
  }


  // api for verify otp (we have to call the api with axios method because we need to send token in header)
  const EntercodeSuccess = (datafor) => {
    let codeval = code.trim()
    if (!codeval) {
      setforgotpageError(UserMessage.FORGOT_ENTER_CODE)
      return
    }
    if (codeval.length > 5) {
      setforgotpageError(UserMessage.FORGOT_CODE_LESS)
      return
    }
    let data = {
      otp: code,
      _id: forgotdata._id
    };
    const headers = {
      'x-access-token': forgotdata.token
    };

    dispatch(loaderStatus(true));
    axios.post(Url?.OTP_MATCH, data, { headers })
      .then(response => {
        dispatch(loaderStatus(false));

        if (response?.data?.result) {
          ForgotPasswordupdate()
        }
        else {
          notifyService.error(response?.data?.message)
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));
      });
  }

  // handle for new password input
  const newpasswordhandle = (event) => {
    setnewpassword(event.target.value);
    setforgotpageError('')
  };

  // handle for confirm new password input
  const confirmpasswordhandle = (event) => {
    setconfirmpasswrod(event.target.value);
    setforgotpageError('')
  };

  // handle for email input for forgot password 
  const emailvalidation = (event) => {
    setEmail(event.target.value);
    setforgotpageError('')
  };

  // Validation handles for Verification Code fields
  const verificationCodeValidation = (event) => {
    setcode(event.target.value);
  };

  // reset form
  const resetMethod = () => {
    setnewpassword('');
    setconfirmpasswrod('');
    setforgotpageError('')
  }

  return (
    <div className="home">
      <Navbar />
      <section className="banner">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-4 col-md-12">
              <div className="signup-section">
                <h2>Forgot Password</h2>
                <p className="ErrorNew">{forgotpageError}</p>
                <form>
                  <div className="textinput">
                    <input
                      type="email"
                      id="PhoneNumber"
                      onChange={emailvalidation}
                      required
                    /> <br />
                    <label for="PhoneNumber">EMAIL</label>
                  </div>
                  <button className="buttonsignup" onClick={(e) => { e.preventDefault(); ForgotPasswordFunction(); }}>
                    SUBMIT
                  </button>
                  <div className="already-text">
                    <p>
                      Already have an account? {" "}
                      <Link to="/login">LOG IN</Link>
                    </p>
                  </div>
                </form>
                <button className="buttonHide " data-bs-toggle="modal" data-bs-target="#forgetpassword" id="changeModalId" onClick={(e) => { e.preventDefault(); }}>hideButton</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="history-modal modal fade" id="forgetpassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  >
        <div className="modal-dialog" >
          <div className="modal-content" >
            <div className="modal-header">
            </div>
            <div className="modal-body">
              <h2>Change password</h2>
              <p className="ErrorNew">{forgotpageError}</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <p class="message-info">
                  Please enter code sent to your mail
                </p>
                <div class="textinput">
                  <input type="number" id="entercode" onChange={verificationCodeValidation} />
                  <label for="entercode">Enter Code</label>

                </div>
                <div className="textinput">
                  <input type="password" id="newpassword"
                    value={newpasswrod}
                    onChange={newpasswordhandle}
                  /><br />
                  <label for="newpassword">new Password</label>
                </div>
                <div className="textinput">
                  <input type="password" id="confirmpassword"
                    value={confirmpasswrod}
                    onChange={confirmpasswordhandle}
                  /><br />
                  <label for="confirmpassword">Confirm Password</label>
                </div>
                <button className="yes-submit"
                  onClick={(e) => {
                    e.preventDefault(); EntercodeSuccess();
                  }}>YES, SUBMIT</button>
                <button class="no-submit" aria-hidden="false" type="button" data-bs-dismiss="modal" id="closeModalPaswrod" onClick={resetMethod}>NO, GO BACK</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ForgotPassword)