import { loaderStatus } from './LoaderAction';
import { useDispatch } from "react-redux";
import { loginUser } from '../../Api/api_calls/authApis';
import { notifyService, errorHandleService } from '../../common';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { balanceAction } from './BalanceAction';
import{ Url } from "../../../App/Api/api_setting/apiConstants"

export const signIn = (email, password, props) => {
  return (dispatch) => {
    dispatch(loaderStatus(true));
    loginUser({ email, password })
      .then((info) => {
        dispatch(loaderStatus(false));
        if (info?.data?.isVerfied === false) {
          notifyService.error(info.data.message)
          Sendotp(info?.data)
          props.history.push({
            pathname: "/entercode",
            state: { data: info?.data }
          });
        }
        else {
          dispatch(loaderStatus(false));
          if (info?.data?.token) localStorage.setItem("bingotoken", info?.data?.token);
          let successInfo = {};
          if (info?.data?.token){ 
            successInfo['token'] = info?.data?.token
            const decodetoken = jwtDecode(info?.data?.token);
            let venmoBalance = decodetoken?.venmoBalance
            let reduxObject = {}
            if (venmoBalance) {
                reduxObject['venmoBalance'] = venmoBalance
            } else {
                reduxObject['venmoBalance'] = 0
            }
            dispatch(balanceAction(reduxObject))
          }
          if (info?.data?.data) successInfo['userInfo'] = info?.data?.data
          else {
            notifyService.error(info.data.message)
          }
          dispatch({
            type: "USER_LOADED",
            allInfo: successInfo
          });
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));
        // errorHandleService.onErrorHandle()
      });
  };
};


// send otp api hit for send otp when user is active but trying to login
const Sendotp = (info) => {
  const decodetoken = jwtDecode(info?.token);

  let otpdata = {
    email: decodetoken?.email,
    _id: decodetoken?._id,
  }
  const headers = {
    'x-access-token': info?.token
  };
  axios.post(Url?.OTP_SEND_API, otpdata, { headers })
    .then(response => console.log(response));
}

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "SIGN_OUT",
    });
  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState()?.Auth?.bingotoken;
    let successInfo = {};
    if (token) successInfo['token'] = token
    // else successInfo['token'] = null
    if (token) {
      const decodetoken = jwtDecode(token);
      let venmoBalance = decodetoken?.venmoBalance
      let reduxObject = {}
      if (venmoBalance) {
          reduxObject['venmoBalance'] = venmoBalance
      } else {
          reduxObject['venmoBalance'] = 0
      }
      dispatch(balanceAction(reduxObject))
      dispatch({
        type: "USER_LOADED",
        allInfo: successInfo
      });
    } else return null;
  };
};

export const Logout = () => {
  const dispatch = useDispatch();
  dispatch(signOut());
}
