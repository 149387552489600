import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

export default function Addplayer(props) {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            {
                (() => {
                    if (props?.csvStatusSend) {
                        return (
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                props?.dataHeader.map((row) => (
                                                    <TableCell className="tableHeading">{row?.name}</TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            props?.data?.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row" className="tableText">
                                                        {row?.Type}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className="tableText">
                                                        {row['Player/Team']}
                                                    </TableCell>
                                                    <TableCell className="tableText">
                                                        <Box>{row?.Period}</Box>
                                                    </TableCell>
                                                    <TableCell className="tableText">
                                                        <Box>{row?.Point}</Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    } else {
                        return (
                            <Grid item xs={12}>
                                <Box p={2}>
                                    <Alert severity="error">Opps...! {props?.statusMessage}</Alert>
                                </Box>
                            </Grid>
                        )
                    }
                })()
            }
        </Grid>
    )
}
