import React from 'react'
import { useHistory } from "react-router-dom";
import CommonHeader from '../../common/headers/headerbeforelogin';

function Error404Page() {
    const history = useHistory()

    // handle for redireect to home page
    const redirectHome = () => {
        history.push("/")
    }

    return (
        <div class="home">
            <CommonHeader />
            <section class="banner">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-4 col-md-12">
                            <div class="signup-section notfoundpage">
                                <h1>404 <span>OPPS! PAGE NOT FOUND</span></h1>
                                <p>Sorry, the page you were looking for doesn't exist.</p>
                                <button class="buttonsignup" onClick={redirectHome}>Back to Home </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Error404Page

