export const MessageList = {

    // Csv message list start - 
    CSV_LENGTH: 'Blank csv will not upload',
    CSV_ORDER_HEADER: 'Plase check your csv columns order or file columns heading',
    CSV_UPLOAD_SUCCESS: 'Props uploaded successfully',

    // Romm message list start -
    ROOM_SUCCESS: 'Room created successfully',
    ROOM_UPDATE_SUCCESS: 'Room edited successfully',

    // Props message list start -
    UPDATE_PROPS: 'Props edited successfully',

    // Admin allusers list start
    ALL_USER_NAME: 'Please enter your name',
    ALL_USER_ALPHABAT: 'Only albhabets allowed in name',
    ALL_USER_USERNAME: 'Please enter user id',
    ALL_USER_VENMO: 'Please enter Venom handle',
    ALL_USER_VENMO_VALIDATION: 'Venmo details should be between 5-16',
    ALL_USER_VENMO_CHARACTRS: "Only special characters alllowed in Venmo are -, _ @ ",
    ALL_USER_CHARACTRS: 'No special characters allowed in referal code',
    ALL_USER_SUCCESS: 'User updated successfully',
    ALL_USER_PHONE_ERROR: 'Please enter valid phone number',
    ALL_USER_NO_FOUND:'No User Found',
    ALL_USER_NO_CARD:"No Cards Found !",

    // admin wallet managment list start
    WALLET_MANAGE_UPDATE: 'Balance added successfully',

    // admin profile change password msgs
    ADMIN_OLD_PASSWORD: 'Please enter your old password',
    ADMIN_NEW_PASSWORD: 'Please enter your new password',
    ADMIN_PASSWORD_VALIDATION: 'Minimum 8 characters are required with combination of uppercase, lowercase and special character',
    ADMIN_CONFIRM_PASSWORD: 'Please enter your confirm password',
    ADMIN_CONFIRM_SAME: 'New password and confirm password should be same',
    ADMIN_PASSWORD_NOT_SAME:"Old password and new password can not be same",
    ADMIN_PASSWORD_SUCCESS: 'Password changed successfully',
    ADMIN_NAME: 'Please enter name',
    ADMIN_NAME_ALPHABATE:'Only alphabets allowed in name',
    ADMIN_PHONE:'Please enter valid phone number',
    ADMIN_PROFILE_SUCCESS:'Profile updated successfully',
}



// CONFIG MESSEGES FOR USER SIDE (WEB)
export const UserMessage = {

    // config messeges for enter otp
    OTP_SENT: 'OTP sent successfully and your OTP will be valid for 2 minutes',
    SOMETHING_WRONG: 'Something went wrong',
    ENTER_CODE: 'Please enter your code',
    CODE_LESS: 'Code will be less than 6',
    OTP_MATCH: 'OTP matched successfully',

    // config messeges for forgot password
    FORGOT_EMAIL: 'Please enter your email',
    FORGOT_OTP_SENT: 'OTP sent successfully and your OTP will be valid for 2 minutes',
    FORGOT_ENTER_MAIL: 'Please enter your new password',
    FORGOT_PASSWORD_VALIDATION: 'Minimum 8 characters are required with combination of uppercase, lowercase and special character',
    FORGOT_CONFIRM_PASSWORD: 'Please enter your confirm password',
    FORGOT_CONFIRM_SAME: 'Password and confirm Password should be same',
    FORGOT_PASSWORD_CHANGE: 'Password changed successfully',
    FORGOT_ENTER_CODE: 'Please enter your code',
    FORGOT_CODE_LESS: 'Code will be less than 6 digits',
    FORGOT_OTP_MATCH: 'OTP matched successfully',

    // config messeges for forgot password
    LOGIN_MAIL_ENTER: 'Please enter your email',
    LOGIN_PASSWORD_ENTER: 'Please enter your password',
    LOGIN_PASSWORD_LENGTH: 'Password length should be greater then 8',
    LOGIN_USER_SUCCESS: 'User login successfully',
    LOGIN_ADMIN_SUCCESS: 'Admin login successfully',

    // config messeges for register 
    REGISTER_MAIL: 'Please enter your email',
    REGISTER_NAME: 'Please enter your name',
    REGISTER_ALPHABAT: 'Only albhabets allowed in name',
    ALPHANUMARIC_SPECIAL_CHAR: 'User id should be alphanumeric with special characters',
    REGISTER_USERNAME: 'Please enter your user id',
    REGISTER_PASSWORD: 'Please enter your password',
    REGISTER_PASSWORD_VALIDATION: 'Minimum 8 characters are required with combination of uppercase, lowercase and special character',
    REGISTER_VENMO: 'Please enter your Venmo handle',
    REGISTER_VENMO_VALIDATION: 'Venmo details should be between 5-16',
    REGISTER_VENMO_CHARACTRS: "Only special characters alllowed in Venmo are -, _ @ ",
    REGISTER_CHARACTRS: 'No special characters allowed in referal code',
    REGISTER_SUCCESS: 'OTP sent successfully and your OTP will be valid for 2 minutes',
    REGISTER_PHONE_ERROR: 'Please enter valid phone number',
    QUERY_SUCCESS: 'Query submitted successfully', 
    REGISTER_CONTACTUS_EMAIL:'Please enter your email',
    REGISTER_CONTACTUS_USERNAME:'Please enter your name ',
    REGISTER_CONTACTUS_NAME_VALIDATION:'Only alphabets allowed in name',
    REGISTER_CONTACTUS_QUERY:'Please fill your query',


    // Card constant section --
    BLANK_CARD: "Please fill your card",
    ROOM_ID_NOT: "Room id is not define",
    USER_ID_NOT: "User id not define",
    CARD_ADDED_SUCCESS: 'Card added successfully'

}

