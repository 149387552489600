import React from 'react'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from '../redux/Actions/AuthAction';

export default function LogoutEvn(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    return () => {
        dispatch(signOut());
        history.push("/");
    }
}
