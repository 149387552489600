import { wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const saveUserCard = (data) => {
    return wrappedFetch(Url.SAVE_USER_CARD, "POST", data)
}

export const getRoomDetailsByIdUser = (roomId) => {
    return wrappedFetch(Url.GET_ROOM_DETAILS_USER_URL + roomId, "GET")
}

export const getRoomAndPropsOfUserMethod = (userId, roomId) => {
    return wrappedFetch(Url.GET_USER_ROOM_DETAILS + userId + "&roomId=" + roomId, "GET")
}

// Leaderbaord info api -
export const myLeaderBoardMethod = (userId, roomId, userCardId) => {
    return wrappedFetch(Url.MY_LEADERBOARD_INFO + userCardId +"&roomId="+roomId+"&uid="+userId, "GET")
}

// general leader board -
export const commanLeaderBoardMethod = (roomId) => {
    return wrappedFetch(Url.COMMON_LEADERBOARD_INFO+roomId , "GET")
}
