import React, { useEffect, useState, } from 'react'
import '../../../../index.css'
import { useDispatch, useSelector } from 'react-redux';
import { storeCardRoomDetails } from '../../../redux/Actions/userCardAction';
import { errorHandleService } from '../../../common';
import stampchamp from '../../../../Assets/images/stampchamp.png'
import dateFormat from 'dateformat';
import Navbar from '../../../common/headers/Navbar'
import { useParams } from 'react-router-dom'
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { getRoomAndPropsOfUserMethod } from '../../../Api/api_calls/cardApis';
import ball from '../../../../Assets/images/ball.png'
import LogoutEvn from '../../../common/LogoutEvn';
import { initiateSocket, matchUserCardSocket, revertUserCardUser, leaderBoardDataEmit, userLeaderBoardInfo, commanLeaderBoardInfo, winnerInfoSocket, roomStartInfoSocket } from '../../../service/socket';
import './card.css'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Leaderboard from '../Leaderboard/Leaderboard';
import InfoIcon from '@mui/icons-material/Info';

export default function Cardsinplay(props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { roomId, userId } = useParams();
    const auth = useSelector((state) => state.Auth);
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);
    const logoutEvents = LogoutEvn()
    const [, setMasterData] = useState([])
    const [userCardId, setUserCardId] = useState(null)
    const [cardOccre, setCardOccre] = useState({})
    const [usercarddata, setusercarddata] = useState({})
    const [filledCount, setFilledCount] = useState(1)
    const [realPayOut, setrealPayout] = useState(null)

    // Code for start box 
    // row 1
    const [r1c1, set_r1c1] = useState({})
    const [r1c2, set_r1c2] = useState({})
    const [r1c3, set_r1c3] = useState({})
    const [r1c4, set_r1c4] = useState({})
    const [r1c5, set_r1c5] = useState({})
    // row 2
    const [r2c1, set_r2c1] = useState({})
    const [r2c2, set_r2c2] = useState({})
    const [r2c3, set_r2c3] = useState({})
    const [r2c4, set_r2c4] = useState({})
    const [r2c5, set_r2c5] = useState({})
    // row 3
    const [r3c1, set_r3c1] = useState({})
    const [r3c2, set_r3c2] = useState({})
    const [r3c3, set_r3c3] = useState({})
    const [r3c4, set_r3c4] = useState({})
    const [r3c5, set_r3c5] = useState({})
    // row 4
    const [r4c1, set_r4c1] = useState({})
    const [r4c2, set_r4c2] = useState({})
    const [r4c3, set_r4c3] = useState({})
    const [r4c4, set_r4c4] = useState({})
    const [r4c5, set_r4c5] = useState({})
    // row 5
    const [r5c1, set_r5c1] = useState({})
    const [r5c2, set_r5c2] = useState({})
    const [r5c3, set_r5c3] = useState({})
    const [r5c4, set_r5c4] = useState({})
    const [r5c5, set_r5c5] = useState({})

    // my leaderBoard info -
    const [myLeaderBoard, setMyleaderBoard] = useState({})
    // General leaderboard info -
    const [commanLeaderBoard, setCommanleaderBoard] = useState([])

    // Is Combination state -
    const [matchObj, setMatchObj] = useState([
        // Row 1 set
        {
            matchCombination: 'row1',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Row 2 set
        {
            matchCombination: 'row2',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Row 3 set
        {
            matchCombination: 'row3',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Row 4 set
        {
            matchCombination: 'row4',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Row 5 set
        {
            matchCombination: 'row5',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Column 1 set
        {
            matchCombination: 'col1',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Column 2 set
        {
            matchCombination: 'col2',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Column 3 set
        {
            matchCombination: 'col3',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Column 4 set
        {
            matchCombination: 'col4',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Column 5 set
        {
            matchCombination: 'col5',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Cross Combination 1 
        {
            matchCombination: 'crossRight',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        },
        // Cross Combination 2
        {
            matchCombination: 'crossLeft',
            isComplete: false,
            points: 0,
            uid: userId,
            roomId: roomId,
            userCardId: userCardId
        }
    ])


    const handleClick = () => {
        enqueueSnackbar(
            <Grid container>
                <Grid item xs={12} md={12} className="cardHead">
                    <span>Bingo Occurred Card</span>
                </Grid>
                <div className="modal-box cardOccure">
                    <Box pt={2}>
                        <h5>{cardOccre?.playerId?.type}</h5>
                        <Grid container>
                            <Grid item xs={6}>
                                <div className="irst-text">
                                    <h6>4th <br></br><span>QTR</span></h6>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="irst-text">
                                    <h6> {cardOccre?.playerId?.points} <br></br><span>PTS</span></h6>
                                </div>
                            </Grid>
                        </Grid>
                        <div className="bootom-text redbox">
                            {cardOccre?.playerId?.name}
                        </div>
                    </Box>
                </div>
            </Grid>, {
            autoHideDuration: 2000
        });
    };

    useEffect(() => {
        getRoomAndPropsOfUser(userId, roomId)
        let data = {}
        data['roomId'] = roomId
        data['role'] = auth?.role
        data['userId'] = userId
        if (data) initiateSocket(data);
    }, [])

    const isBingoCheck = () => {
        // Comnination - row1
        if (isMatch(r1c1) && isMatch(r1c2) && isMatch(r1c3) && isMatch(r1c4) && isMatch(r1c5)) {
            if (!matchObj[0]?.isComplete) {
                let point = 0;
                if (r1c1?.playerId?.points) { point = point + r1c1?.playerId?.points }
                if (r1c2?.playerId?.points) { point = point + r1c2?.playerId?.points }
                if (r1c3?.playerId?.points) { point = point + r1c3?.playerId?.points }
                if (r1c4?.playerId?.points) { point = point + r1c4?.playerId?.points }
                if (r1c5?.playerId?.points) { point = point + r1c5?.playerId?.points }
                // leaderBoardDataEmit(matchObj)
                updateMatchStateFunction(0, point)
            }
        }
        // Comnination - row2
        if (isMatch(r2c1) && isMatch(r2c2) && isMatch(r2c3) && isMatch(r2c4) && isMatch(r2c5)) {
            if (!matchObj[1]?.isComplete) {
                let point = 0;
                if (r2c1?.playerId?.points) { point = point + r2c1?.playerId?.points }
                if (r2c2?.playerId?.points) { point = point + r2c2?.playerId?.points }
                if (r2c3?.playerId?.points) { point = point + r2c3?.playerId?.points }
                if (r2c4?.playerId?.points) { point = point + r2c4?.playerId?.points }
                if (r2c5?.playerId?.points) { point = point + r2c5?.playerId?.points }
                // leaderBoardDataEmit()
                updateMatchStateFunction(1, point)
            }
        }
        // Comnination - row3
        if (isMatch(r3c1) && isMatch(r3c2) && isMatch(r3c4) && isMatch(r3c5)) {
            if (!matchObj[2]?.isComplete) {
                let point = 0;
                if (r3c1?.playerId?.points) { point = point + r3c1?.playerId?.points }
                if (r3c2?.playerId?.points) { point = point + r3c2?.playerId?.points }
                if (r3c4?.playerId?.points) { point = point + r3c4?.playerId?.points }
                if (r3c5?.playerId?.points) { point = point + r3c5?.playerId?.points }
                updateMatchStateFunction(2, point + 1)
            }
        }
        // Comnination - row4
        if (isMatch(r4c1) && isMatch(r4c2) && isMatch(r4c3) && isMatch(r4c4) && isMatch(r4c5)) {
            if (!matchObj[3]?.isComplete) {
                let point = 0;
                if (r4c1?.playerId?.points) { point = point + r4c1?.playerId?.points }
                if (r4c2?.playerId?.points) { point = point + r4c2?.playerId?.points }
                if (r4c3?.playerId?.points) { point = point + r4c3?.playerId?.points }
                if (r4c4?.playerId?.points) { point = point + r4c4?.playerId?.points }
                if (r4c5?.playerId?.points) { point = point + r4c5?.playerId?.points }
                updateMatchStateFunction(3, point)
            }
        }
        // Comnination - row5
        if (isMatch(r5c1) && isMatch(r5c2) && isMatch(r5c3) && isMatch(r5c4) && isMatch(r5c5)) {
            if (!matchObj[4]?.isComplete) {
                let point = 0;
                if (r5c1?.playerId?.points) { point = point + r5c1?.playerId?.points }
                if (r5c2?.playerId?.points) { point = point + r5c2?.playerId?.points }
                if (r5c3?.playerId?.points) { point = point + r5c3?.playerId?.points }
                if (r5c4?.playerId?.points) { point = point + r5c4?.playerId?.points }
                if (r5c5?.playerId?.points) { point = point + r5c5?.playerId?.points }
                updateMatchStateFunction(4, point)
            }
        }
        // Comnination - Column1
        if (isMatch(r1c1) && isMatch(r2c1) && isMatch(r3c1) && isMatch(r4c1) && isMatch(r5c1)) {
            if (!matchObj[5]?.isComplete) {
                let point = 0;
                if (r1c1?.playerId?.points) { point = point + r1c1?.playerId?.points }
                if (r2c1?.playerId?.points) { point = point + r2c1?.playerId?.points }
                if (r3c1?.playerId?.points) { point = point + r3c1?.playerId?.points }
                if (r4c1?.playerId?.points) { point = point + r4c1?.playerId?.points }
                if (r5c1?.playerId?.points) { point = point + r5c1?.playerId?.points }
                updateMatchStateFunction(5, point)
            }
        }
        // Comnination - Column2
        if (isMatch(r1c2) && isMatch(r2c2) && isMatch(r3c2) && isMatch(r4c2) && isMatch(r5c2)) {
            if (!matchObj[6]?.isComplete) {
                let point = 0;
                if (r1c2?.playerId?.points) { point = point + r1c2?.playerId?.points }
                if (r2c2?.playerId?.points) { point = point + r2c2?.playerId?.points }
                if (r3c2?.playerId?.points) { point = point + r3c2?.playerId?.points }
                if (r4c2?.playerId?.points) { point = point + r4c2?.playerId?.points }
                if (r5c2?.playerId?.points) { point = point + r5c2?.playerId?.points }
                updateMatchStateFunction(6, point)
            }
        }
        // Comnination - Column3
        if (isMatch(r1c3) && isMatch(r2c3) && isMatch(r4c3) && isMatch(r5c3)) {
            if (!matchObj[7]?.isComplete) {
                let point = 0;
                if (r1c3?.playerId?.points) { point = point + r1c3?.playerId?.points }
                if (r2c3?.playerId?.points) { point = point + r2c3?.playerId?.points }
                if (r4c3?.playerId?.points) { point = point + r4c3?.playerId?.points }
                if (r5c3?.playerId?.points) { point = point + r5c3?.playerId?.points }
                updateMatchStateFunction(7, point + 1)
            }
        }
        // Comnination - Column4
        if (isMatch(r1c4) && isMatch(r2c4) && isMatch(r3c4) && isMatch(r4c4) && isMatch(r5c4)) {
            if (!matchObj[8]?.isComplete) {
                let point = 0;
                if (r1c4?.playerId?.points) { point = point + r1c4?.playerId?.points }
                if (r2c4?.playerId?.points) { point = point + r2c4?.playerId?.points }
                if (r3c4?.playerId?.points) { point = point + r3c4?.playerId?.points }
                if (r4c4?.playerId?.points) { point = point + r4c4?.playerId?.points }
                if (r5c4?.playerId?.points) { point = point + r5c4?.playerId?.points }
                updateMatchStateFunction(8, point)
            }
        }
        // Comnination - Column5
        if (isMatch(r1c5) && isMatch(r2c5) && isMatch(r3c5) && isMatch(r4c5) && isMatch(r5c5)) {
            if (!matchObj[9]?.isComplete) {
                let point = 0;
                if (r1c5?.playerId?.points) { point = point + r1c5?.playerId?.points }
                if (r2c5?.playerId?.points) { point = point + r2c5?.playerId?.points }
                if (r3c5?.playerId?.points) { point = point + r3c5?.playerId?.points }
                if (r4c5?.playerId?.points) { point = point + r4c5?.playerId?.points }
                if (r5c5?.playerId?.points) { point = point + r5c5?.playerId?.points }
                updateMatchStateFunction(9, point)
            }
        }
        // Comnination - Cross1
        if (isMatch(r1c1) && isMatch(r2c2) && isMatch(r4c4) && isMatch(r5c5)) {
            if (!matchObj[10]?.isComplete) {
                let point = 0;
                if (r1c1?.playerId?.points) { point = point + r1c1?.playerId?.points }
                if (r2c2?.playerId?.points) { point = point + r2c2?.playerId?.points }
                if (r4c4?.playerId?.points) { point = point + r4c4?.playerId?.points }
                if (r5c5?.playerId?.points) { point = point + r5c5?.playerId?.points }
                updateMatchStateFunction(10, point + 1)
            }
        }
        // Comnination - Cross2
        if (isMatch(r1c5) && isMatch(r2c4) && isMatch(r4c2) && isMatch(r5c1)) {
            if (!matchObj[11]?.isComplete) {
                let point = 0;
                if (r1c5?.playerId?.points) { point = point + r1c5?.playerId?.points }
                if (r2c4?.playerId?.points) { point = point + r2c4?.playerId?.points }
                if (r4c2?.playerId?.points) { point = point + r4c2?.playerId?.points }
                if (r5c1?.playerId?.points) { point = point + r5c1?.playerId?.points }
                updateMatchStateFunction(11, point + 1)
            }

        }
    }

    function updateMatchStateFunction(position, point) {
        matchObj[position]['isComplete'] = true
        matchObj[position]['points'] = point
        matchObj[position]['userCardId'] = userCardId
        setMatchObj(matchObj)
        // leaderBoardDataEmit(matchObj[position])
    }

    // check object is not emplty and isMatch true -
    function isMatch(obj) {
        if (Object.keys(obj).length === 0) {
            return false
        } else if (obj?.isMatch === false) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        revertUserCardUser((err, data) => {
            if (err) return;
            setMasterData(oldData => {
                setCardOccre({})
                oldData?.forEach(obj => {
                    if (obj?.playerId?._id === data?._id) {
                        let getObj = obj
                        setCardOccre(getObj)
                        getObj['isMatch'] = false
                        if (getObj.boxId === "r1c1") {
                            set_r1c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c2") {
                            set_r1c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c3") {
                            set_r1c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c4") {
                            set_r1c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c5") {
                            set_r1c5(getObj)
                            isBingoCheck()
                        }
                        // row 2
                        if (getObj.boxId === "r2c1") {
                            set_r2c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c2") {
                            set_r2c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c3") {
                            set_r2c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c4") {
                            set_r2c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c5") {
                            set_r2c5(getObj)
                            isBingoCheck()
                        }
                        // row 3
                        if (getObj.boxId === "r3c1") {
                            set_r3c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c2") {
                            set_r3c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c4") {
                            set_r3c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c5") {
                            set_r3c5(getObj)
                            isBingoCheck()
                        }
                        // row 4
                        if (getObj.boxId === "r4c1") {
                            set_r4c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c2") {
                            set_r4c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c3") {
                            set_r4c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c4") {
                            set_r4c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c5") {
                            set_r4c5(getObj)
                            isBingoCheck()
                        }
                        // row 5
                        if (getObj.boxId === "r5c1") {
                            set_r5c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c2") {
                            set_r5c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c3") {
                            set_r5c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c4") {
                            set_r5c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c5") {
                            set_r5c5(getObj)
                            isBingoCheck()
                        }
                    }
                })
                matchSet()
                return [...oldData,]
            })
        });
    }, [r1c1, r1c2, r1c3, r1c4, r1c5, r2c1, r2c2, r2c3, r2c4, r2c5, r3c1, r3c2, r3c3, r3c4, r3c5, r4c1, r4c2, r4c3, r4c4, r4c5, r5c1, r5c2, r5c3, r5c4, r5c5]);


    useEffect(() => {
        matchUserCardSocket((err, data) => {
            if (err) return;
            setMasterData(oldData => {
                setCardOccre({})
                oldData?.forEach(obj => {
                    if (obj?.playerId?._id === data?._id) {
                        let getObj = obj
                        setCardOccre(getObj)
                        getObj['isMatch'] = true
                        if (getObj.boxId === "r1c1") {
                            set_r1c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c2") {
                            set_r1c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c3") {
                            set_r1c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c4") {
                            set_r1c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r1c5") {
                            set_r1c5(getObj)
                            isBingoCheck()
                        }
                        // row 2
                        if (getObj.boxId === "r2c1") {
                            set_r2c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c2") {
                            set_r2c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c3") {
                            set_r2c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c4") {
                            set_r2c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r2c5") {
                            set_r2c5(getObj)
                            isBingoCheck()
                        }
                        // row 3
                        if (getObj.boxId === "r3c1") {
                            set_r3c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c2") {
                            set_r3c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c4") {
                            set_r3c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r3c5") {
                            set_r3c5(getObj)
                            isBingoCheck()
                        }
                        // row 4
                        if (getObj.boxId === "r4c1") {
                            set_r4c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c2") {
                            set_r4c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c3") {
                            set_r4c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c4") {
                            set_r4c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r4c5") {
                            set_r4c5(getObj)
                            isBingoCheck()
                        }
                        // row 5
                        if (getObj.boxId === "r5c1") {
                            set_r5c1(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c2") {
                            set_r5c2(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c3") {
                            set_r5c3(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c4") {
                            set_r5c4(getObj)
                            isBingoCheck()
                        }
                        if (getObj.boxId === "r5c5") {
                            set_r5c5(getObj)
                            isBingoCheck()
                        }
                    }
                })
                matchSet()
                return [...oldData,]
            })
        });
    }, [r1c1, r1c2, r1c3, r1c4, r1c5, r2c1, r2c2, r2c3, r2c4, r2c5, r3c1, r3c2, r3c3, r3c4, r3c5, r4c1, r4c2, r4c3, r4c4, r4c5, r5c1, r5c2, r5c3, r5c4, r5c5]);

    useEffect(() => {
        userLeaderBoardInfo((err, data) => {
            if (err) return;
            let getNewList = []
            if (data?.results?.length > 0) {
                data?.results.map(obj => { if (obj != null) { getNewList.push(obj) } }
                )
                setMyleaderBoard(getNewList.pop())
            }
        });
    }, []);

    useEffect(() => {
        commanLeaderBoardInfo((err, data) => {
            if (err) return;
            setCommanleaderBoard(data?.results)
        });
    }, []);

    // checking of matchcount
    const matchSet = () => {
        let countSet = 1
        if (r1c1.isMatch) {
            countSet = countSet + 1
        }
        if (r1c2.isMatch) {
            countSet = countSet + 1
        }
        if (r1c3.isMatch) {
            countSet = countSet + 1
        }
        if (r1c4.isMatch) {
            countSet = countSet + 1
        }
        if (r1c5.isMatch) {
            countSet = countSet + 1
        }
        // row 2 -
        if (r2c1.isMatch) {
            countSet = countSet + 1
        }
        if (r2c2.isMatch) {
            countSet = countSet + 1
        }
        if (r2c3.isMatch) {
            countSet = countSet + 1
        }
        if (r2c4.isMatch) {
            countSet = countSet + 1
        }
        if (r2c5.isMatch) {
            countSet = countSet + 1
        }
        // row 3 -
        if (r3c1.isMatch) {
            countSet = countSet + 1
        }
        if (r3c2.isMatch) {
            countSet = countSet + 1
        }
        if (r3c4.isMatch) {
            countSet = countSet + 1
        }
        if (r3c5.isMatch) {
            countSet = countSet + 1
        }
        // row 4 -
        if (r4c1.isMatch) {
            countSet = countSet + 1
        }
        if (r4c2.isMatch) {
            countSet = countSet + 1
        }
        if (r4c3.isMatch) {
            countSet = countSet + 1
        }
        if (r4c4.isMatch) {
            countSet = countSet + 1
        }
        if (r4c5.isMatch) {
            countSet = countSet + 1
        }
        // row 5 -
        if (r5c1.isMatch) {
            countSet = countSet + 1
        }
        if (r5c2.isMatch) {
            countSet = countSet + 1
        }
        if (r5c3.isMatch) {
            countSet = countSet + 1
        }
        if (r5c4.isMatch) {
            countSet = countSet + 1
        }
        if (r5c5.isMatch) {
            countSet = countSet + 1
        }
        setFilledCount(countSet)
    }

    // get user created card info for room ----
    function getRoomAndPropsOfUser(userIdGet, roomIdGet) {
        dispatch(loaderStatus(true));
        getRoomAndPropsOfUserMethod(userIdGet, roomIdGet).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                let dataGet = res?.data?.data[0]?.cardDetails
                let cardInfo = res?.data?.data[0]
                let storeCardInfo = {}
                storeCardInfo['cardDetails'] = cardInfo?.cardDetails
                storeCardInfo['roomId'] = cardInfo?.roomId
                setUserCardId(cardInfo?._id)
                dispatch(storeCardRoomDetails(storeCardInfo))
                setMasterData(dataGet)
                setPlayerInCard(dataGet)
                setusercarddata(cardInfo?.roomId)
                if (res?.data?.realPayOut) {
                    setrealPayout(res?.data?.realPayOut)
                }
                // Count Filled start --
                let count = 1
                dataGet.map(obj => {
                    if (obj?.isMatch) {
                        count = count + 1
                    }
                })
                setFilledCount(count)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    const payoutCount = (entryFee, maxPlayer) => {
        if (entryFee && maxPlayer) {
            return (entryFee * maxPlayer) * 0.9
        } else {
            return 0
        }
    }



    const setPlayerInCard = (dataGet) => {
        set_r1c1(dataGet[0])
        set_r1c2(dataGet[1])
        set_r1c3(dataGet[2])
        set_r1c4(dataGet[3])
        set_r1c5(dataGet[4])
        // row 2
        set_r2c1(dataGet[5])
        set_r2c2(dataGet[6])
        set_r2c3(dataGet[7])
        set_r2c4(dataGet[8])
        set_r2c5(dataGet[9])
        // row 3
        set_r3c1(dataGet[10])
        set_r3c2(dataGet[11])
        set_r3c4(dataGet[12])
        set_r3c5(dataGet[13])
        // row 4
        set_r4c1(dataGet[14])
        set_r4c2(dataGet[15])
        set_r4c3(dataGet[16])
        set_r4c4(dataGet[17])
        set_r4c5(dataGet[18])
        // row 5
        set_r5c1(dataGet[19])
        set_r5c2(dataGet[20])
        set_r5c3(dataGet[21])
        set_r5c4(dataGet[22])
        set_r5c5(dataGet[23])
    }

    // get winner info -
    useEffect(() => {
        winnerInfoSocket((err, data) => {
            if (err) return;
            if (data?.isOver === true) {
                props.history.push("/winner/" + roomId + "/" + userId)
            }
        });
    }, []);

    // get info to start room -----
    useEffect(() => {
        roomStartInfoSocket((err, data) => {
            if (err) return;
            if (data.realPayOut) {
                setrealPayout(data.realPayOut)
            }
            setusercarddata(data?.results)
        });
    }, []);

    // handle for return to home page 
    const homepage = () => {
        props.history.push("/account")
    }

    // check condition card name is bigger or not
    const checkingTooltipText = (propsName) => {
        if (propsName?.length > 12) {
            return <span class="tooltiptext">{propsName}</span>
        } else {
            return ''
        }
    }

    // check condition card type is bigger or not
    const checkingTooltipTexttype = (propstype) => {
        if (propstype.length > 9) {
            return <span class="tooltiptext">{propstype}</span>
        } else {
            return ''
        }
    }
    return (
        <div class="accounts">
            <Navbar />
            <Button onClick={handleClick} className='buttonHide' id="occordCardId"></Button>

            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <img class="img-fluid" onClick={homepage} src={ball} /> CARDS IN PLAY
                            </a>
                        </div>
                        <div class="col">
                            <ul class="accounts-ul">
                                <li class="balance">Balance <span>$&nbsp;{userbalance.toFixed(2)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section class="banner create-card">
                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="history-modal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">
                                                <span class="first-heading">
                                                    {dateFormat(usercarddata?.startDateTime, "mmmm dS, yyyy")}&nbsp;
                                                    {dateFormat(usercarddata.startDateTime, "h:MM TT")}
                                                </span>
                                                {
                                                    usercarddata?.title?.split("v-s")[0].length > 8 || usercarddata?.title?.split("v-s")[1].length > 8 ?
                                                        <span class="second-heading bigger-text">
                                                            <span className='lfttitle'>{usercarddata?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'> {usercarddata?.title?.split("v-s")[1]}
                                                            </span>
                                                        </span>
                                                        :
                                                        <span class="second-heading">
                                                            <span className='lfttitle'>{usercarddata?.title?.split("v-s")[0]}</span>&nbsp;
                                                            <span className="">VS</span>&nbsp;
                                                            <span className='rgttitle'>{usercarddata?.title?.split("v-s")[1]} </span>
                                                        </span>
                                                }

                                                {
                                                    realPayOut ? <span class="third-heading">WINNER'S PAYOUT: ${realPayOut?.toFixed(2)}</span> : <span class="third-heading">MAX PAYOUT<p title="Max payout vary according to total players in room" className='ptagDispayInline'><InfoIcon className='infoicon' /></p>: ${payoutCount(usercarddata?.entryFee, usercarddata?.maxPlayer).toFixed(2)}</span>
                                                }
                                            </h5>
                                        </div>
                                        <div class="modal-body">
                                            <div class="inner-checs">
                                                <ul>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c1).length != 0 ?
                                                                <div className={r1c1?.isMatch === true ? "modal-box active-join " : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c1?.playerId?.type.length > 9 ? r1c1?.playerId?.type?.substring(0, 9) + '...' : r1c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c1?.playerId?.name?.length > 12 ? r1c1?.playerId?.name.substring(0, 12) + '...' : r1c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c2).length != 0 ?
                                                                <div className={r1c2.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c2?.playerId?.type.length > 9 ? r1c2?.playerId?.type?.substring(0, 9) + '...' : r1c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c2?.playerId?.name?.length > 12 ? r1c2?.playerId?.name.substring(0, 12) + '...' : r1c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c3).length != 0 ?
                                                                <div className={r1c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c3?.playerId?.type.length > 9 ? r1c3?.playerId?.type?.substring(0, 9) + '...' : r1c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c3?.playerId?.name?.length > 12 ? r1c3?.playerId?.name.substring(0, 12) + '...' : r1c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c4).length != 0 ?
                                                                <div className={r1c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c4?.playerId?.type.length > 9 ? r1c4?.playerId?.type?.substring(0, 9) + '...' : r1c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c4?.playerId?.name?.length > 12 ? r1c4?.playerId?.name.substring(0, 12) + '...' : r1c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r1c5).length != 0 ?
                                                                <div className={r1c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.type.length > 9 ? r1c5?.playerId?.type?.substring(0, 9) + '...' : r1c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r1c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r1c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r1c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r1c5?.playerId?.name?.length > 12 ? r1c5?.playerId?.name.substring(0, 12) + '...' : r1c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r1c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c1).length != 0 ?
                                                                <div className={r2c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c1?.playerId?.type.length > 9 ? r2c1?.playerId?.type?.substring(0, 9) + '...' : r2c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c1?.playerId?.name?.length > 12 ? r2c1?.playerId?.name.substring(0, 12) + '...' : r2c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest' >
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c2).length != 0 ?
                                                                <div className={r2c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c2?.playerId?.type.length > 9 ? r2c2?.playerId?.type?.substring(0, 9) + '...' : r2c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c2?.playerId?.name?.length > 12 ? r2c2?.playerId?.name.substring(0, 12) + '...' : r2c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(set_r2c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c3).length != 0 ?
                                                                <div className={r2c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c3?.playerId?.type.length > 9 ? r2c3?.playerId?.type?.substring(0, 9) + '...' : r2c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c3?.playerId?.name?.length > 12 ? r2c3?.playerId?.name.substring(0, 12) + '...' : r2c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c4).length != 0 ?
                                                                <div className={r2c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c4?.playerId?.type.length > 9 ? r2c4?.playerId?.type?.substring(0, 9) + '...' : r2c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c4?.playerId?.points} <span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c4?.playerId?.name?.length > 12 ? r2c4?.playerId?.name.substring(0, 12) + '...' : r2c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r2c5).length != 0 ?
                                                                <div className={r2c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r2c5?.playerId?.type.length > 9 ? r2c5?.playerId?.type?.substring(0, 9) + '...' : r2c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r2c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r2c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r2c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r2c5?.playerId?.name?.length > 12 ? r2c5?.playerId?.name.substring(0, 12) + '...' : r2c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r2c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c1).length != 0 ?
                                                                <div className={r3c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c1?.playerId?.type.length > 9 ? r3c1?.playerId?.type?.substring(0, 9) + '...' : r3c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c1?.playerId?.name?.length > 12 ? r3c1?.playerId?.name.substring(0, 12) + '...' : r3c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c2).length != 0 ?
                                                                <div className={r3c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c2?.playerId?.type.length > 9 ? r3c2?.playerId?.type?.substring(0, 9) + '...' : r3c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c2?.playerId?.name?.length > 12 ? r3c2?.playerId?.name.substring(0, 12) + '...' : r3c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        <div className="modal-box active-join center-bingo" style={{ lineHeight: 'inherit' }}>
                                                            {/* center */}
                                                            <div className="textdiv">
                                                                <div className="middle-img">
                                                                    <h3>1 PTS</h3>
                                                                    <img className="img-fluid" src={stampchamp} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-left"></span> : ''}
                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c4).length != 0 ?
                                                                <div className={r3c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c4?.playerId?.type.length > 9 ? r3c4?.playerId?.type?.substring(0, 9) + '...' : r3c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c4?.playerId?.name?.length > 12 ? r3c4?.playerId?.name.substring(0, 12) + '...' : r3c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r3c5).length != 0 ?
                                                                <div className={r3c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r3c5?.playerId?.type.length > 9 ? r3c5?.playerId?.type?.substring(0, 9) + '...' : r3c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r3c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r3c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r3c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r3c5?.playerId?.name?.length > 12 ? r3c5?.playerId?.name.substring(0, 12) + '...' : r3c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r3c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c1).length != 0 ?
                                                                <div className={r4c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c1?.playerId?.type.length > 9 ? r4c1?.playerId?.type?.substring(0, 9) + '...' : r4c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c1?.playerId?.name?.length > 12 ? r4c1?.playerId?.name.substring(0, 12) + '...' : r4c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-righttp-angle"></span> : ''}
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c2).length != 0 ?
                                                                <div className={r4c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c2?.playerId?.type.length > 9 ? r4c2?.playerId?.type?.substring(0, 9) + '...' : r4c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c2?.playerId?.name?.length > 12 ? r4c2?.playerId?.name.substring(0, 12) + '...' : r4c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-top"></span> : ''}
                                                        {
                                                            Object.keys(r4c3).length != 0 ?
                                                                <div className={r4c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c3?.playerId?.type.length > 9 ? r4c3?.playerId?.type?.substring(0, 9) + '...' : r4c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c3?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c3?.playerId?.name?.length > 12 ? r4c3?.playerId?.name.substring(0, 12) + '...' : r4c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-lefttp-angle"></span> : ''}
                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c4).length != 0 ?
                                                                <div className={r4c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c4?.playerId?.type.length > 9 ? r4c4?.playerId?.type?.substring(0, 9) + '...' : r4c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c4?.playerId?.period}</h6>

                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c4?.playerId?.name?.length > 12 ? r4c4?.playerId?.name.substring(0, 12) + '...' : r4c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                        {
                                                            Object.keys(r4c5).length != 0 ?
                                                                <div className={r4c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r4c5?.playerId?.type.length > 9 ? r4c5?.playerId?.type?.substring(0, 9) + '...' : r4c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r4c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r4c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r4c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r4c5?.playerId?.name?.length > 12 ? r4c5?.playerId?.name.substring(0, 12) + '...' : r4c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r4c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c1).length != 0 ?
                                                                <div className={r5c1.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c1?.playerId?.type.length > 9 ? r5c1?.playerId?.type?.substring(0, 9) + '...' : r5c1?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c1?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c1?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c1?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c1?.playerId?.name?.length > 12 ? r5c1?.playerId?.name.substring(0, 12) + '...' : r5c1?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c1?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c2).length != 0 ?
                                                                <div className={r5c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c2?.playerId?.type.length > 9 ? r5c2?.playerId?.type?.substring(0, 9) + '...' : r5c2?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c2?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c2?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c2?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c2?.playerId?.name?.length > 12 ? r5c2?.playerId?.name.substring(0, 12) + '...' : r5c2?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c2?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c3).length != 0 ?
                                                                <div className={r5c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c3?.playerId?.type.length > 9 ? r5c3?.playerId?.type?.substring(0, 9) + '...' : r5c3?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c3?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c3?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c3?.playerId?.points} <span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c3?.playerId?.name?.length > 12 ? r5c3?.playerId?.name.substring(0, 12) + '...' : r5c3?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c3?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                        {
                                                            Object.keys(r5c4).length != 0 ?
                                                                <div className={r5c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c4?.playerId?.type.length > 9 ? r5c4?.playerId?.type?.substring(0, 9) + '...' : r5c4?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c4?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c4?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c4?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        <div class="tooltipp">
                                                                            {r5c4?.playerId?.name?.length > 12 ? r5c4?.playerId?.name.substring(0, 12) + '...' : r5c4?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c4?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                    <li className='cardTest'>
                                                        {
                                                            Object.keys(r5c5).length != 0 ?
                                                                <div className={r5c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                                    <h5>
                                                                        <div class="tooltipp">
                                                                            {r5c5?.playerId?.type.length > 9 ? r5c5?.playerId?.type?.substring(0, 9) + '...' : r5c5?.playerId?.type}
                                                                            {
                                                                                checkingTooltipTexttype(r5c5?.playerId?.type)
                                                                            }
                                                                        </div>
                                                                    </h5>
                                                                    <div className="textdiv">
                                                                        <div className="irst-text">
                                                                            <h6>{r5c5?.playerId?.period}</h6>
                                                                        </div>
                                                                        <div className="middle-img">

                                                                        </div>
                                                                        <div className="last-text">
                                                                            {r5c5?.playerId?.points}<span>PTS</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bootom-text redbox">
                                                                        {/* tooptip start */}
                                                                        <div class="tooltipp">
                                                                            {r5c5?.playerId?.name?.length > 12 ? r5c5?.playerId?.name.substring(0, 12) + '...' : r5c5?.playerId?.name}
                                                                            {
                                                                                checkingTooltipText(r5c5?.playerId?.name)
                                                                            }
                                                                        </div>
                                                                        {/* tooptip end */}
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <Leaderboard generalLeaderBoardInfo={commanLeaderBoard} myLeaderBoardInfo={myLeaderBoard} roomId={roomId} userId={userId} userCardId={userCardId} roomInfo={usercarddata} filledCount={filledCount} />
                        </div>
                    </div>
                    <div class="margin"></div>
                </div>
            </section >

        </div >
    );
}
