import { wrappedFetch,wrappedGet } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";

export const CreatePlayerApi = (data,roomId) => {
    return wrappedFetch(Url.ADD_PLAYER_URL+roomId, "PUT", data);
};

export const getAllPlayer = () => {
    return wrappedGet(Url.GET_PLAYER_URL, "GET");
};

export const EditPropsApi=(data)=>{
    return wrappedFetch(Url.UPDATE_PLAYER_URL, "POST", data)
}

export const DeletePropsApi=(playerId)=>{
    return wrappedFetch(Url.DELETE_PLAYER_URL+playerId,"DELETE")
}