import { useSelector } from "react-redux";

export const GrantPermission = (requestedRoles) => {
    const auth = useSelector((state) => state.Auth);
    if (auth?.role === requestedRoles[0]) {
        return true
    } else if(auth?.role === requestedRoles[0]){return true} 
    else {
        return false
    }
};
