import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Base setup - 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
// Upload csv code start here -------
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
// Upload csv code end here -------
// ---------------- Modal and Form Code Start --------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { errorHandleService, notifyService } from '../../../common';
// ---------------- Modal and Form Code Close --------------------
import Addplayer from './Addplayer';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { CreatePlayerApi, getAllPlayer, EditPropsApi, DeletePropsApi } from '../../../Api/api_calls/playerApi';
// Table code start ---------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import dateFormat from 'dateformat';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import { FIXCSV } from '../../../constants/constants';
import { MessageList } from '../../../constants/MessageConfig';
import { TextField } from '@mui/material';
import LogoutEvn from '../../../common/LogoutEvn';


const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputLabel: {
        fontWeight: 450
    },
    errorColor: {
        color: 'red'
    },
    modalHight: {
        Height: '28rem',
        Overflow: 'scroll'
    },
    setFile: {
        fontSize: '15px',
        fontWeight: 500
    },
    setSize: {
        fontSize: '14px',
        fontWeight: 500
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function Allplayer() {
    const classes = useStyles();
    // Upload csv code start here -------
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState('');
    // Modal code start ------
    const [open, setOpen] = React.useState(false);
    const [fullWidth,] = React.useState(true);
    const [maxWidth,] = React.useState('lg');
    // Modal code to edit props  ------
    const [openProps, setPropsOpen] = React.useState(false);
    const [fullWidthProps,] = React.useState(true);
    const [maxWidthProps,] = React.useState('sm');
    const [player, setPlayerList] = useState([]);
    // Modal code close ------
    // edit section code start ----
    const [editData, setEditData] = useState({});
    const [editName, setEditName] = useState(null)
    const [editPeriod, setEditPeriod] = useState(null)
    const [editPoints, setEditPoints] = useState(null)
    const [editType, setEditType] = useState(null)
    // edit section code close ---
    const [csvStatus, setCsvStatus] = useState(true)
    const [csvErrorMesssage, setCsvErrorMesssage] = useState('')


    // process CSV data
    const processData = dataString => {
        dispatch(loaderStatus(true));
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));
        setCsvStatus(true)
        setCsvErrorMesssage('')
        if (list.length === 0) {
            setCsvErrorMesssage(MessageList?.CSV_LENGTH)
            setCsvStatus(false)
        }
        if (columns[0]?.name != FIXCSV[0]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[1]?.name != FIXCSV[1]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[2]?.name != FIXCSV[2]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        if (columns[3]?.name != FIXCSV[3]) {
            setCsvErrorMesssage(MessageList?.CSV_ORDER_HEADER)
            setCsvStatus(false)
        }
        setData(list);
        setColumns(columns);
        dispatch(loaderStatus(false));
        handleClickOpen()
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    // Upload csv code close here -------

    // get request to get all player list -
    function getAllPlayerMethod() {
        dispatch(loaderStatus(true));
        getAllPlayer().then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) setPlayerList(res.data.data);
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // Get request to get all l list -
    useEffect(() => {
        getAllPlayerMethod()
        // eslint-disable-next-line
    }, [])

    // modal code start --------
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // window.location.reload()
        setOpen(false);
        resetEverything()
    };

    const reloadClose = () => {
        window.location.reload()
        setOpen(false);
        resetEverything()
    }

    const handleCloseEdit = () => {
        setPropsOpen(false);
        resetEverything()
    };

    const handleEditOpen = () => {
        setPropsOpen(true)
    }


    const resetEverything = () => {
        setPropsOpen(false)
        setOpen(false);
        setColumns([])
        setData([])
        setFileName('')
    }

    const handleUploadCsv = async () => {
        let getData = await data
        if (getData.length <= 0) {
            notifyService.error('Empty props will not upload')
        } else {
            setOpen(false);
            // resetEverything()
            let sendData = getData.map(item => {
                return {
                    type: item['Type'],
                    name: item['Player/Team'],
                    period: item['Period'],
                    points: item['Point']
                };
            });
            dispatch(loaderStatus(true));
            CreatePlayerApi(sendData).then(res => {
                dispatch(loaderStatus(false));
                notifyService.success(MessageList.CSV_UPLOAD_SUCCESS)
                reloadClose()
                getAllPlayerMethod()
            }).catch(err => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
                logoutEvents()
            })
        }
    }

    const updateProps = () => {
        let updateVlaue = editData
        let updateObject = {}
        updateObject['createdAt'] = updateVlaue?.createdAt
        updateObject['imgUrl'] = updateVlaue?.imgUrl
        updateObject['isActive'] = updateVlaue?.isActive
        updateObject['name'] = editName
        updateObject['period'] = editPeriod
        updateObject['points'] = editPoints
        updateObject['type'] = editType
        updateObject['_id'] = updateVlaue?._id
        dispatch(loaderStatus(true));
        EditPropsApi(updateObject).then(res => {
            notifyService.success('Props updated successfully')
            handleClose()
            getAllPlayerMethod()
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })

    }

    // modal code close --------

    // Edit player ---
    function editOption(getData) {
        setEditData(getData)
        setEditName(getData?.name)
        setEditPeriod(getData?.period)
        setEditPoints(getData?.points)
        setEditType(getData?.type)
        handleEditOpen()
    }

    function handleModalDelete(data) {
        let playerId = data._id
        dispatch(loaderStatus(true));
        DeletePropsApi(playerId).then(res => {
            notifyService.success('Props deleted successfully')
            handleClose()
            getAllPlayerMethod()
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={9}>
                    <Box component="div" display="inline" mt={3} className="MuiButton-root">Props</Box>
                </Grid>
                <Grid item xs={3} direction="row" className="textEnd">
                    <label htmlFor="contained-button-file">
                        <spna className={classes.inputLabel}>{fileName}</spna> &nbsp;&nbsp;
                        <Input accept=".csv" id="contained-button-file" multiple type="file" onChange={handleFileUpload} />
                        <Button variant="contained" component="span" className="commonButtonClr">
                            Upload Props
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="start">Type</TableCell>
                                    <TableCell align="start">Player/Team</TableCell>
                                    <TableCell align="start">Period</TableCell>
                                    <TableCell align="start">Point</TableCell>
                                    <TableCell align="center">Created Date</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {player.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.type}
                                        </TableCell>
                                        <TableCell align="start">
                                            <Box>{row.name}</Box>
                                        </TableCell>
                                        <TableCell align="start">
                                            <Box>{row.period}</Box>
                                        </TableCell>
                                        <TableCell align="start">
                                            <Box>{row.points}</Box>
                                        </TableCell>
                                        <TableCell align="center">{
                                            dateFormat(row.createdAt, "mmmm dS, yyyy")
                                        }</TableCell>
                                        <TableCell align="center">
                                            <Box component="div" display="inline">
                                                <EditIcon className="cursorPoint" onClick={() => editOption(row)} /></Box>
                                            <Box component="div" display="inline">
                                                <DeleteIcon className="deleteIcon" onClick={() => handleModalDelete(row)}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                className="modalHight"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={reloadClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Props preview"}</DialogTitle>
                <DialogContent>
                    <Box p={1}>
                        <span className="setFile">&nbsp;File&nbsp;&nbsp;</span><Chip label={fileName} />
                    </Box>
                    <Addplayer data={data} dataHeader={columns} csvStatusSend={csvStatus} statusMessage={csvErrorMesssage} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={reloadClose}>Cancel</Button>
                    {
                        (() => {
                            if (csvStatus) {
                                return <Button onClick={handleUploadCsv}>Upload</Button>
                            }
                        })()
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={fullWidthProps}
                maxWidth={maxWidthProps}
                open={openProps}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseEdit}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Edit props"}</DialogTitle>
                <DialogContent>
                    <Box pb={1} pt={1} className={classes.setSize}>Type</Box>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        size="small"
                        value={editType}
                        onChange={(e) => setEditType(e.target.value)}
                    />
                    <Box pb={1} pt={1} className={classes.setSize}>Player/Team</Box>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        size="small"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                    />
                    <Box pb={1} pt={1} className={classes.setSize}>Period</Box>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        size="small"
                        value={editPeriod}
                        onChange={(e) => setEditPeriod(e.target.value)}
                    />
                    <Box pb={1} pt={1} className={classes.setSize}>Points</Box>
                    <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        size="small"
                        type="number"
                        value={editPoints}
                        onChange={(e) => setEditPoints(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    <Button onClick={updateProps}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
