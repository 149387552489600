import React from 'react'
import HightOrderCom from '../Hoc/HightOrderCom'
import AllUsers from './allUsers'

export default function AllusersHoc(props) {
    return (
        <div>
            <HightOrderCom cmp={AllUsers}/>
        </div>
    )
}