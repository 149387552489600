import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import dateFormat from 'dateformat';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { errorHandleService, notifyService } from '../../../common';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import Table from '@mui/material/Table';
import queryString from "query-string";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LogoutEvn from '../../../common/LogoutEvn';
import { getRoomDetailsById } from '../../../Api/api_calls/adminroomApis';
import { initiateSocket, adminCardSend, matchAdminCardSocket, gameOverDataEmit, gameStartDataEmit, commanLeaderBoardInfo } from '../../../service/socket';
import TextField from '@mui/material/TextField';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import { commanLeaderBoardMethod } from '../../../Api/api_calls/cardApis';
// import CircularProgress from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import './card.css'
import ButtonGroup from '@mui/material/ButtonGroup';

const useStyles = makeStyles((theme) => ({
}));


function GamePlay(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn();
    // state ,anage for get all room
    const [roomDetails, setRoomDetails] = useState({});
    const [propsDetails, setPropsDetails] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [count,] = useState(1);
    const [roomIdInfo, setRoomId] = useState('');
    const auth = useSelector((state) => state.Auth);
    const [gameStatus, setGameStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [commanLeaderInfo, setCommanLeaderBoardInfo] = useState([])
    let GameStatusList = ['Not_Start', 'Running', 'End']
    const [constVal, setConst] = useState(0)



    // get room details -
    useEffect(() => {
        let roomId = queryString.parse(props.location.search).id
        setRoomId(roomId)
        let data = {}
        data['roomId'] = roomId
        data['role'] = auth?.role
        data['userId'] = auth?._id
        if (data) initiateSocket(data);
        getroomDetailsMethod(roomId)
        dispatch(loaderStatus(true));
        matchAdminCardSocket((err, data) => {
            dispatch(loaderStatus(false));
            if (err) return;
            if (data?.props) {
                setPropsDetails(data?.props)
                getroomDetailsMethod(roomId)
            } else {
                getroomDetailsMethod(roomId)
            }
        });
        // eslint-disable-next-line
    }, [])

    // Get room details 
    function getroomDetailsMethod(roomId) {
        dispatch(loaderStatus(true));
        getRoomDetailsById(roomId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setRoomDetails(res?.data?.data?.room)
                setPropsDetails(res?.data?.data?.props)
                setMasterData(res?.data?.data?.props)
                let isOver = res?.data?.data?.room?.isOver
                let isStart = res?.data?.data?.room?.isStart
                // let propsoccured = res?.data?.data?.props

                if (isOver === false && isStart === true) {
                    // game running
                    setGameStatus(GameStatusList[1])
                } else if (isOver === true && isStart === false) {
                    // game end
                    setGameStatus(GameStatusList[2])
                } else {
                    // game not start
                    setGameStatus(GameStatusList[0])
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }
    const onButtonClick = async (row) => {
        if (gameStatus === GameStatusList[0]) notifyService.error('Game is not start !')
        else if (gameStatus === GameStatusList[2]) notifyService.error('Game is over !')
        else {
            let newProps = row
            try {
                await timer(newProps)
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    const timer = async (newProps) => {
        dispatch(loaderStatus(true));
        setTimeout(async () => {
            await adminCardSend(newProps, (err, data) => {
                if (err) { return }
                // else{
                //     setTimeout(async () => {
                //         let roomId = queryString.parse(props.location.search).id
                //         getCommonLeaderboardInfo(roomId)
                //     }, 10000);  
                // };
            });
        }, 10000);
    }

    useEffect(() => {
        setInterval(async () => {
            let pathName = window.location.pathname.split('/')[1]
            if (pathName && pathName == "adminPlay") {
                let roomId = queryString.parse(props.location.search).id
                getCommonLeaderboardInfo(roomId)
            }
        }, 9000);
    }, []);

    const gameOver = async () => {
        setOpen(true);
    }

    const disagreeMethod = () => {
        setOpen(false);
    }

    const agressMethod = () => {
        // dispatch(loaderStatus(true));
        // setTimeout(async () => {
        //     dispatch(loaderStatus(false));
        //     gameOverDataEmit(roomIdInfo)
        //     setGameStatus(GameStatusList[2])
        //     notifyService.success('Game over successfully')
        //     setOpen(false);
        //     props.history.push('/room_managment');
        // }, 4000);
        gameOverDataEmit(roomIdInfo)
        setGameStatus(GameStatusList[2])
        notifyService.success('Game over successfully')
        setOpen(false);
        props.history.push('/room_managment');
    }

    const gameStart = async () => {
        gameStartDataEmit(roomIdInfo)
        setGameStatus(GameStatusList[1])
        notifyService.success('Game started successfully')
    }

    // on change of user -
    const getPropsNameByChange = (event) => {
        let getSearchValue = event?.target?.value
        const matches = masterData.filter(obj => obj?.name?.includes(getSearchValue));
        setPropsDetails(matches)

    }

    useEffect(() => {
        let roomId = queryString.parse(props.location.search).id
        getCommonLeaderboardInfo(roomId)
    }, []);

    // get my leaderboard info ----
    function getCommonLeaderboardInfo(roomId) {
        // dispatch(loaderStatus(true));
        commanLeaderBoardMethod(roomId).then(res => {
            // dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setCommanLeaderBoardInfo(res?.data?.results)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    useEffect(() => {
        commanLeaderBoardInfo((err, data) => {
            if (err) return;
            setCommanLeaderBoardInfo(data?.results)
        });
    }, []);



    return (
        <div>
            <span className='displaynothing'>{constVal}</span>
            <Grid container spacing={3} direction="row">
                <Grid item xs={8}>
                    <Box component="div" style={{ fontWeight: 700 }} display="inline" className="mainHeading">Game Play</Box>
                </Grid>
                <Grid item xs={4} direction="row" className="textEnd">
                    {
                        gameStatus === GameStatusList[2] ? <><Chip label="Game Over" className='subHeading' /></> :
                            <Box>
                                {gameStatus === GameStatusList[1] ? <CircleIcon style={{ color: 'red' }} className="blink" /> : <Button variant="contained" onClick={gameStart} className="buttonTheme buttonFontTheme" size='small' endIcon={<ScheduleSendIcon />}>Start Game</Button>}&nbsp;
                                <Button variant="contained" className="buttonWorningTheme buttonFontTheme" size='small' endIcon={<CancelScheduleSendIcon />} onClick={gameOver}>End Game</Button>
                            </Box>
                    }
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12} className="setPadding">
                    <Box component="div" display="inline" mt={3} className='subHeading'>Room Detail</Box>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className="tableHeading">Title</TableCell>
                                    <TableCell align="center" className="tableHeading">Match Type</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Date</TableCell>
                                    <TableCell align="center" className="tableHeading">Start Time</TableCell>
                                    <TableCell align="center" className="tableHeading">Entry Fee</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" align="center" className="tableText">
                                        {roomDetails?.title?.split("v-s")[0]}&nbsp;<span className="title-vs">VS</span>&nbsp;{roomDetails?.title?.split("v-s")[1]}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        {roomDetails.matchType}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        {dateFormat(roomDetails.startDateTime, "mmmm dS, yyyy")}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        {dateFormat(roomDetails.startDateTime, "h:MM TT")}
                                    </TableCell>
                                    <TableCell align="center" className="tableText">
                                        $&nbsp;{roomDetails.entryFee}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={5}>
                    <Box component="div" display="inline" mt={3} className='subHeading'>Leaderboard</Box>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Place</TableCell>
                                    <TableCell align="center" className="tableHeading">Name</TableCell>
                                    <TableCell align="center" className="tableHeading">Bingos</TableCell>
                                    <TableCell align="center" className="tableHeading">Pts</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    commanLeaderInfo && commanLeaderInfo?.map((row, index) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell >
                                            <TableCell align="center">
                                                {row?.uid?.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.bingoCount}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row?.points}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={7}>
                    <Grid container direction="row">
                        <Grid item xs={6} >
                            <Box component="div" display="inline" mt={3} className='subHeading'>Props</Box>
                        </Grid>
                        <Grid item xs={6} className='textEnd'>
                            <TextField id="outlined-basic" label="Search Props By Player" variant="outlined" size='small' className='widht100' onChange={(event) => { getPropsNameByChange(event) }} />
                            <span className='buttonHide'>{count}</span>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{ maxHeight: 350 }}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHeading">Type</TableCell>
                                    <TableCell align="center" className="tableHeading">Player</TableCell>
                                    <TableCell align="center" className="tableHeading">Period</TableCell>
                                    <TableCell align="center" className="tableHeading">Points</TableCell>
                                    <TableCell align="center" className="tableHeading">Did it occur?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {propsDetails && propsDetails?.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell component="th" scope="row" className="tableText">
                                            {row?.type}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.name}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.period}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            {row?.points}
                                        </TableCell>
                                        <TableCell align="center" className="tableText">
                                            <ButtonGroup disableElevation variant="contained">
                                                <Button className={row?.isOcurred === true ? 'darkButton' : 'faddedCss'} onClick={() => onButtonClick(row)}>Yes</Button>
                                                <Button className={row?.isOcurred === false ? 'darkButton' : 'faddedCss'} onClick={() => onButtonClick(row)}>No</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            propsDetails.length <= 0 ? <Grid item xs={12} className={classes.textCenter}><Box p={2} className='tableHeading textCenter'>No Props Available</Box></Grid> : ''
                        }
                    </TableContainer>
                </Grid>
                <Dialog aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" className="mainHeading">
                        Game Over
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            <Alert severity="warning" className="normalTextTheme">Are you sure you want to close this room ?</Alert>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button className='modalCommonButton' onClick={disagreeMethod}>Disagree</Button>
                        <Button autoFocus className='modalCommonButton' onClick={agressMethod}>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </div>
    )
}

export default withRouter(GamePlay)
