import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { withRouter } from 'react-router';
import { makeStyles } from '@mui/styles';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import navLogo from '../../../../Assets/images/navLogo.png'
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from "react-redux";
import { signOut } from '../../../redux/Actions/AuthAction';
import { ADMIN_ROUTE } from '../../../constants/AdminRouter';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import Avatar from '@mui/material/Avatar';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { getUserProfile } from '../../../Api/api_calls/profileApi';
import { storeUserDetails } from '../../../redux/Actions/userAction';
import { errorHandleService } from '../../../common';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    backgroundColor: "#151515"
  },
  userName: {
    fontSize: '1.1rem'
  },
  logoSize: {
    height: '2rem'
  }
}));

function HightOrderCom(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUrl, setCurrentUrl] = useState('')
  const userId = useSelector((state) => state?.Auth?._id);
  const name = useSelector(state => state.user.name)
  const userImage = useSelector(state => state.user.imgUrl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutOut = () => {
    dispatch(signOut());
    props.history.push("/");
  }

  const profileRedirect = () => {
    props.history.push("/admin_profile");
  }

  // Ower Code Implementation -
  function redirectNow(data) {
    props.history.push(data?.path)
  }

  // rendering for getall room list
  useEffect(() => {
    if (ADMIN_ROUTE.length > 0) {
      let currentPath = window?.location?.pathname?.split("/")[1]
      if (currentPath) {
        ADMIN_ROUTE.map(obj => {
          if ('/' + currentPath === obj?.path) {
            setCurrentUrl(obj.name)
          }
        })
      }
    }
    getUserDetails()
  }, [])

  function getUserDetails() {
    let userIdSend = userId
    dispatch(loaderStatus(true));
    getUserProfile(userIdSend).then(res => {
        dispatch(loaderStatus(false));
        let profileData = res.data?.data[0]
        let profileImage = res.data?.data[0]?.imgUrl;
        let reduxObject = {}
        if (profileData) {
            reduxObject['phone'] = profileData?.phone
            reduxObject['name'] = profileData?.name
            reduxObject['imgUrl'] = profileImage
        } else {
            reduxObject['phone'] = ''
            reduxObject['name'] = ''
            reduxObject['imgUrl'] = ''
        }
        dispatch(storeUserDetails(reduxObject))
    }).catch(err => {
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
    })
}



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className={classes.root} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img className={classes.logoSize} src={navLogo} />
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt="User Photo" src={userImage} />&nbsp;
              <span className="normalFontWithColorWhite">{name}</span>&nbsp;<SettingsApplicationsIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={profileRedirect} className='subHeading'><AccountCircleIcon className='themeColorText'/>&nbsp;&nbsp;Profile</MenuItem>
              <MenuItem onClick={logoutOut} className='subHeading'><LogoutIcon className='themeColorText' />&nbsp;&nbsp;Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {
              ADMIN_ROUTE.map((text, index) => (
                <ListItem button key={text} onClick={() => redirectNow(text)} className={text?.name == currentUrl ? 'buttonTheme' : null}>
                  <ListItemIcon className='sideNavCss'>
                    {(() => {
                      if (index == 0) {
                        return (
                          <DashboardIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 1) {
                        return (
                          <PodcastsIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 2) {
                        return (
                          <CoPresentIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 3) {
                        return (
                          <SupervisedUserCircleIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 4) {
                        return (
                          <AccountBalanceWalletIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 5) {
                        return (
                          <PolicyIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 6) {
                        return (
                          <GavelIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                      if (index == 7) {
                        return (
                          <PersonSearchIcon className={text?.name == currentUrl ? 'buttonTheme' : null} />
                        )
                      }
                    })()}
                  </ListItemIcon>
                  <span className="buttonFontTheme">{text?.name}</span>
                </ListItem>
              ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <props.cmp />
      </Box>
    </Box>
  );
}

export default withRouter(HightOrderCom)