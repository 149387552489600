import React, { useState, useEffect } from 'react'
import navlogo from '../../../Assets/images/navLogo.png'
import user from '../../../Assets/images/user.png'
import { useDispatch, useSelector } from "react-redux";
import { signOut } from '../../redux/Actions/AuthAction';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import { withRouter } from 'react-router';
import SettingsIcon from '@mui/icons-material/Settings';
import AvtarImg from '../../../Assets/images/AvtarImg.png'
import { errorHandleService, notifyService } from '../index';
import { loaderStatus } from '../../redux/Actions/LoaderAction';
import { AddContactus } from '../../Api/api_calls/contactusApi';
import { UserMessage } from '../../constants/MessageConfig';
import { Customjavascript } from '../../../custom';
export const useStyles = makeStyles(() => ({
    menu: {
        "& .MuiPaper-root": {
            backgroundColor: "#456f33"
        }
    },
    setFont: {
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: '500'
    },
    iconSetting: {
        color: '#ffffff',
        fontWeight: '500'
    },
    setIcons: {
        height: '35px',
        border: '1px solid',
        borderRadius: '2rem',
        width: '34px'
    },
    setIcon: {
        paddingTop: '2px',
        height: '35px',
        fontWeight: '500',
        borderRadius: '1rem',
    },
    setIcon: {
        height: '35px',
        color: '#838383',
        fontWeight: '500',
        borderRadius: '1rem',
    },

}));
function Navbar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [file, setFile] = useState(AvtarImg);
    const userImage = useSelector(state => state.user.imgUrl)

    const useremail = useSelector(state => state.Auth.email)
    const username = useSelector(state => state.Auth.userName)


    const dispatch = useDispatch();
    const auth = useSelector((state) => state.Auth);


    // contact us query state manage
    const [contactquery, setcontactquery] = useState('')


    const [contactErrormesseges, setcontactErrormesseges] = useState('')

    // reset function after contactus
    const resetall = () => {
        setcontactquery('')
    }


    const logoutOut = () => {
        // document.querySelector('#menu-appbar').click()
        setAnchorEl(null)
        document.querySelector('#closeModalVenmo').click()
        dispatch(signOut());
        props.history.push("/");
        notifyService.success('User logout successfully')
    }


    const closeModalLog = () => {
        document.querySelector('#menu-appbar').click()
        setAnchorEl(null)
    }

    const goBackLtd = () => {
        document.querySelector('#menu-appbar').click()
        // setAnchorEl(null)
    }

    useEffect(() => {
        Customjavascript();
        if (userImage) {
            setFile(userImage)
        } else {
            setFile(AvtarImg)
        }

    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Profilehandle = () => {
        props.history.push("/user_profile")
    }

    const homepage = () => {
        props.history.push("/")
    }


    // contact us api method hit 
    const contactusmethod = () => {
        let contactusdata = {};
        contactusdata['userName'] = username
        contactusdata['email'] = useremail
        contactusdata['query'] = contactquery

        if (!contactusdata.userName) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_USERNAME)
            return
        }

        if (!contactusdata.email) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_EMAIL)
            return
        }
        if (!contactusdata.query) {
            setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_QUERY)
            return
        }

        dispatch(loaderStatus(true));
        AddContactus(contactusdata).then(response => {
            dispatch(loaderStatus(false));
            if (response.data.result) {
                notifyService.success(UserMessage.QUERY_SUCCESS)
                document.querySelector('#contactUsClose').click()
                resetall()
            }
            else {
                notifyService.error(response.data.message)
            }
        })
            .catch(err => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            })

    }

    const contactqueryvalidation = (event) => {
        setcontactquery(event.target.value);
        setcontactErrormesseges('')
    };

    const Howtoplayredirect = () => {
        props.history.push('/howtoplay')
    }

    return (
        <header className="header-sec">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <a href="#">
                            <img className="img-fluid" onClick={homepage} src={navlogo} />
                        </a>
                    </div>
                    {
                        auth?.role ? <div className="col-lg-9 col-sm-12">
                            <ul className="nav-ul">
                                <li>
                                    <button data-bs-toggle="modal" onClick={Howtoplayredirect} class="user contact-headerbtn">How to play</button>
                                </li>
                                <li>
                                    <button data-bs-toggle="modal" data-bs-target="#contactus" class="user contact-headerbtn">Contact Us</button>
                                </li>
                                <li className="mobilehideli">
                                    <a href="#" className="user">
                                        <img className={classes.setIcons} src={userImage ? userImage : file} /><span>{auth?.userName}</span> 
                                    </a>
                                </li>
                                <li className="mobilehideli">
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                        className="menuSet"
                                    >
                                        <SettingsIcon className={classes.setIcon} />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}

                                        className={classes.menu}
                                    >
                                        <MenuItem onClick={Profilehandle} className={classes.setFont}>
                                            <ListItemIcon >
                                                <PeopleIcon className={classes.iconSetting} fontSize="small" />
                                            </ListItemIcon>
                                            Profile
                                        </MenuItem>
                                        {/* data-bs-toggle="modal" data-bs-target="#logoutconfirmation"  */}
                                        <MenuItem className={classes.setFont} onClick={logoutOut} >
                                            <ListItemIcon >
                                                <Logout className={classes.iconSetting} fontSize="small" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </li>
                            </ul>
                            <ul className='mobilnavshow'>
                                <li>
                                        <a href="#" className="user">
                                            <img className={classes.setIcons} src={userImage ? userImage : file} /><span>{auth?.userName}</span> 
                                        </a>
                                    </li>
                                    <li>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                            className="menuSet"
                                        >
                                            <SettingsIcon className={classes.setIcon} />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}

                                            className={classes.menu}
                                        >
                                            <MenuItem onClick={Profilehandle} className={classes.setFont}>
                                                <ListItemIcon >
                                                    <PeopleIcon className={classes.iconSetting} fontSize="small" />
                                                </ListItemIcon>
                                                Profile
                                            </MenuItem>
                                            {/* data-bs-toggle="modal" data-bs-target="#logoutconfirmation"  */}
                                            <MenuItem className={classes.setFont} onClick={logoutOut} >
                                                <ListItemIcon >
                                                    <Logout className={classes.iconSetting} fontSize="small" />
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </li>
                            </ul>
                        </div> : null
                    }

                </div>
            </div>
            <div class="history-modal modal fade" id="contactus" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        </div>
                        <div class="modal-body">
                            <h2>Contact Us</h2>
                            <p className="ErrorNew">{contactErrormesseges}</p>
                            <form>
                                <div class="textinput">
                                    <input type="text" id="adminmailinner" value="Inquiries@stampchampbingo.com" className='filled cursorSet' disabled={true} />
                                    <label for="adminmailinner">Inquiry To</label>
                                </div>
                                <div class="textinput">
                                    <input type="text" id="innerUserName" value={username} className='filled cursorSet' disabled={true} />
                                    <label for="innerUserName" >User Id</label>
                                </div>
                                <div class="textinput">
                                    <input type="email" id="innerEmail" value={useremail} className='filled cursorSet' disabled={true} />
                                    <label for="innerEmail">Email</label>
                                </div>
                                <div class="textinput textheight">
                                    <textarea rows="3" id="innerConform" value={contactquery} onChange={contactqueryvalidation}></textarea>
                                    <label for="innerConform">Query</label>
                                </div>
                                <button class="yes-submit" onClick={(e) => { e.preventDefault(); contactusmethod() }}>YES, SUBMIT</button>
                                <button class="no-submit" type="button" data-bs-dismiss="modal" id="contactUsClose">NO, GO BACK</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="history-modal modal fade" id="logoutconfirmation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        </div>
                        <div class="modal-body">
                            <h2>Logout</h2>
                            {/* <form onSubmit={e => e.preventDefault()}> */}
                            <p>Are you sure you want to logout ?</p>
                            <button class="yes-submit" onClick={logoutOut} >YES, SUBMIT</button>
                            <button class="no-submit" id="closeModalVenmo" data-bs-dismiss="modal" onClick={goBackLtd}>NO, GO BACK</button>
                            {/* document.querySelector('#closelogout').click()    id="closelogout" */}
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default withRouter(Navbar)