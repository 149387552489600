import React, { useEffect, useState, } from 'react'
import { useDispatch } from 'react-redux';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import leaderboard from '../../../../Assets/images/Leaderboard-logo.png'
import { myLeaderBoardMethod, commanLeaderBoardMethod } from '../../../Api/api_calls/cardApis';
import { errorHandleService } from '../../../common';
import LogoutEvn from '../../../common/LogoutEvn';

export default function Leaderboard(props) {
    const [userLeaderInfo, setUserLeaderBoardInfo] = useState({})
    const [commanLeaderInfo, setCommanLeaderBoardInfo] = useState([])
    console.log(commanLeaderInfo)

    const calculateTimeLeft = () => {
        let roomStartDate = props?.roomInfo?.startDateTime
        const difference = +new Date(roomStartDate) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [year] = useState(new Date().getFullYear());
    const logoutEvents = LogoutEvn()
    const dispatch = useDispatch();

    useEffect(() => {
        setUserLeaderBoardInfo(props?.myLeaderBoardInfo)
    }, [props?.myLeaderBoardInfo]);

    useEffect(() => {
        setCommanLeaderBoardInfo(props?.generalLeaderBoardInfo)
    }, [props?.generalLeaderBoardInfo]);

    useEffect(() => {
        try {
            setInterval(async () => {
                let pathName = window.location.pathname.split('/')[1]
                if (pathName && pathName == "cards_play") {
                    getCommonLeaderboardInfo(props?.roomId)
                }
            }, 3000);
        } catch (e) {
            console.log(e);
        }

    }, []);


    useEffect(() => {
        if (props?.userId && props?.roomId && props?.userCardId) {
            setInterval(async () => {
                let pathName = window.location.pathname.split('/')[1]
                if (pathName && pathName == "cards_play") {
                    getMyLeaderboardInfo(props?.userId, props?.roomId, props?.userCardId)
                }
            }, 3000);
        }
    }, [props?.userId, props?.roomId, props?.userCardId]);


    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    // get my leaderboard info ----
    function getMyLeaderboardInfo(userId, roomId, userCardId) {
        // dispatch(loaderStatus(true));
        myLeaderBoardMethod(userId, roomId, userCardId).then(res => {
            // dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setUserLeaderBoardInfo(res?.data?.results)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get my leaderboard info ----
    function getCommonLeaderboardInfo(roomId) {
        // dispatch(loaderStatus(true));
        commanLeaderBoardMethod(roomId).then(res => {
            // dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setCommanLeaderBoardInfo(res?.data?.results)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    return (
        <div class="player-table">
            <div class="table-responsive">
                <table class="table header-fixed"
                    style={{ border: '2px solid #151515 ' }}
                >
                    <thead>
                        <tr rowspan="4" class="pointstr">
                            {props?.roomInfo?.isStart ? <th className='thfullwidth'>Game In-Progress <span className='blinkGreen blickScc'>...</span></th> : <th className='thfullwidth'>
                                <span className='timerCss blinkGreen'>{timerComponents.length ? timerComponents : <span> 0 seconds</span>}</span><br></br>
                                Awaiting Start of Game...
                            </th>
                            }
                        </tr>
                        <tr class="threecoltr">
                            <th scope="col">POINTS <span>{userLeaderInfo?.points ? userLeaderInfo?.points : 0}</span></th>
                            <th scope="col">BINGOS <span>{userLeaderInfo?.bingoCount ? userLeaderInfo?.bingoCount : 0}</span></th>
                            <th scope="col">FILLED <span>{props?.filledCount}/25</span></th>
                        </tr>
                        <tr class="leaderboardtr">
                            <th>
                                <img class="img-fluid" src={leaderboard} /> Leaderboard
                            </th>
                        </tr>
                        <tr class="trheading slwidth">
                            <th scope="col" style={{ textAlign: 'center' }}>S.no</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Name</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Bingos</th>

                            <th scope="col" className="tableheades" style={{ textAlign: 'center' }} >PTS</th>
                        </tr>
                    </thead>
                    <tbody class="cardplay" style={{ backgroundColor: '#E7E9F2' }}>
                        {
                            commanLeaderInfo && commanLeaderInfo?.map((row, index) => (
                                <tr className="tbodytr tlwidth">
                                    <td className="filterable-cell" style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.uid?.name}</td>
                                    <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.bingoCount}</td>
                                    <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.points}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
