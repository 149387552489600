import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AvtarImg from '../../../../Assets/images/AvtarImg.png'
import { errorHandleService , notifyService} from '../../../common';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { changePasswordApi, getUserProfile, updateProfileMethod, uploadImageProfile } from '../../../Api/api_calls/profileApi';
import { useDispatch, useSelector } from 'react-redux';
import { MessageList } from '../../../constants/MessageConfig';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { storeUserDetails } from "../../../redux/Actions/userAction";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    textField: {
        width: '35ch',
    },
    inputCss: {
        display: 'none',
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function AdminProfile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state?.Auth?._id);
    const adminemail = useSelector((state) => state?.Auth?.email);
    const [value, setValue] = React.useState('1');
    const [Oldpassword, setOldpassword] = useState('');
    const [ConfirmnewPassword, setConfirmnewPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    // for profile
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    // image upload -
    const [file, setFile] = useState(AvtarImg);
    const [filePath, setFilePath] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //-----------------------------------------  Profile Update Code Start ------------------------------------

    useEffect(() => {
        getUserDetails();
    }, []);

    // get user details -
    function getUserDetails() {
        let userIdSend = userId
        dispatch(loaderStatus(true));
        getUserProfile(userIdSend).then(res => {
            dispatch(loaderStatus(false));
            let profileData = res.data?.data[0]
            let profileImage = res.data?.data[0]?.imgUrl;
            let reduxObject = {}
            if (profileData) {
                reduxObject['phone'] = profileData?.phone
                reduxObject['name'] = profileData?.name
                reduxObject['imgUrl'] = profileImage
                setPhone(profileData?.phone)
                setName(profileData?.name)
                if (profileImage) {
                    setFile(profileImage)
                } else {
                    setFile(AvtarImg)
                }
            } else {
                reduxObject['phone'] = ''
                reduxObject['name'] = ''
                reduxObject['imgUrl'] = ''
            }
            dispatch(storeUserDetails(reduxObject))
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    const updateProfile = () => {
        let updateObject = {}
        if (!name) {
            notifyService.error(MessageList.ADMIN_NAME)
            return
        }
        let alphaExp = /^[a-zA-Z\s]*$/
        if (!name.match(alphaExp)) {
            notifyService.error(MessageList.ADMIN_NAME_ALPHABATE)
            return
        }
        if (phone) {
            if ((phone.length < 10) || (phone.length > 10)) {
                notifyService.error(MessageList.ADMIN_PHONE)
                return
            }
        }

        updateObject['name'] = name
        updateObject['phone'] = phone
        updateObject['_id'] = userId
        updateProfleMethod(updateObject)

    }

    const updateProfleMethod = (finalUpdateObject) => {
        dispatch(loaderStatus(true))
        updateProfileMethod(finalUpdateObject).then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) {
                if (filePath) {
                    let data = new FormData();
                    data.append('image', filePath);
                    data.append('_id', userId);
                    dispatch(loaderStatus(true));
                    uploadImageProfile(data).then(resData => {
                        dispatch(loaderStatus(false));
                        getUserDetails();
                        notifyService.success(MessageList.ADMIN_PROFILE_SUCCESS)
                    }).catch(err => {
                        dispatch(loaderStatus(false));
                        errorHandleService.onErrorHandle()
                    })
                } else {
                    getUserDetails()
                    dispatch(loaderStatus(false));
                    notifyService.success(MessageList.ADMIN_PROFILE_SUCCESS)
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    //-----------------------------------------  Profile Update Code Close ------------------------------------

    // Upload image url set  -
    function uploadSingleFileMethod(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFilePath(e.target.files[0]);
    }

    const changePasswrod = () => {
        let passwordformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
        if (!Oldpassword) {
            notifyService.info(MessageList.ADMIN_OLD_PASSWORD)
            return
        }
        if (!NewPassword) {
            // notifyService.info('please enter your new password')
            notifyService.info(MessageList.ADMIN_NEW_PASSWORD)
            return
        }
        if (!NewPassword.match(passwordformat)) {
            notifyService.info(MessageList.ADMIN_PASSWORD_VALIDATION)
            return
        }
        // --------------------------------------------------------------------
        if (!ConfirmnewPassword) {
            notifyService.info(MessageList.ADMIN_CONFIRM_PASSWORD)
            return
        }

        if (NewPassword != ConfirmnewPassword) {
            notifyService.info(MessageList.ADMIN_CONFIRM_SAME)
            return
        }
        if (Oldpassword === NewPassword) {
            notifyService.info(MessageList.ADMIN_PASSWORD_NOT_SAME)
            return
        }



        let passwrodObj = {}
        passwrodObj['oldPassword'] = Oldpassword
        passwrodObj['newPassword'] = ConfirmnewPassword
        passwrodObj['confirmPassword'] = NewPassword
        passwrodObj['_id'] = userId
        dispatch(loaderStatus(true));
        changePasswordApi(passwrodObj).then(res => {
            if (res?.data?.result) {
                dispatch(loaderStatus(false));
                resetPasswrod()
                notifyService.success(MessageList.ADMIN_PASSWORD_SUCCESS)
            } else {
                dispatch(loaderStatus(false));
                resetPasswrod()
                notifyService.error(res.data.error)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    const resetPasswrod = () => {
        setOldpassword('')
        setConfirmnewPassword('')
        setNewPassword('')
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={3} direction="row" justify="center">
                <Grid item xs={12}>
                    <Box component="div" display="inline" mt={3} className="mainHeading">About You</Box>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>

                <Grid container direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Grid xs={10} className='textCenter'>
                        <Box pt={2}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Profile" value="1"  {...a11yProps(0)} className={value == 1 ? 'buttonTheme' : 'normalTextTheme'} />
                                        <Tab label="Change password" value="2"  {...a11yProps(1)} className={value == 2 ? 'buttonTheme' : 'normalTextTheme'} />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className="profileBackgroudColor">
                                    <Grid container spacing={3} direction="row">
                                        <Grid item xs={4}>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid direction="row">
                                                <Grid item xs={4}>
                                                    <div class="profile-image">
                                                        <img
                                                            src={file}
                                                            class="img-sizeadmin" alt="profile" />
                                                        <input accept="image/*" className={classes.inputCss} id="icon-button-file" type="file" onChange={uploadSingleFileMethod} />
                                                        <label htmlFor="icon-button-file">
                                                            <IconButton className='themeColorText' aria-label="upload picture" component="span">
                                                                <PhotoCameraIcon />
                                                            </IconButton>
                                                        </label>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-start">
                                                <Grid item>
                                                    <AccountBoxIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item >
                                                    <TextField id="standard-basic" className={classes.textField} variant="standard" value={name} onChange={(e) => setName(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                Mobile Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <LocalPhoneIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item >
                                                    <TextField id="standard-basic" type="number" className={classes.textField} variant="standard" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <EmailIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item >
                                                    <TextField id="standard-basic" value={adminemail} disabled={true} className={classes.textField} variant="standard" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} align="center" className="setUpdateButton" >
                                            <Button align="center" className="buttonTheme" variant="contained" size="small"
                                                onClick={() => updateProfile()}
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value="2" className="profileBackgroudColor">
                                    <Grid container spacing={3} direction="row">
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <EmailIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item>
                                                    <TextField id="standard-basic" className={classes.textField} variant="standard"
                                                        value={adminemail} disabled={true}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *Old password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <VpnKeyIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item>
                                                    <TextField id="standard-basic" className={classes.textField} variant="standard"
                                                        value={Oldpassword} type="password"
                                                        onChange={(e) => setOldpassword(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *New Password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <VpnKeyIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item>
                                                    <TextField id="standard-basic" className={classes.textField} variant="standard"
                                                        value={NewPassword} onChange={(e) => setNewPassword(e.target.value)}
                                                        type="password"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2" gutterBottom className='normalTextTheme'>
                                                *Confirm Password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item>
                                                    <VpnKeyIcon className='themeColorText' />
                                                </Grid>
                                                <Grid item>
                                                    <TextField id="standard-basic" className={classes.textField} variant="standard"
                                                        type="password" value={ConfirmnewPassword} onChange={(e) => setConfirmnewPassword(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className="setUpdateButton" align="center">
                                            <Button variant="contained" className="buttonTheme" size="small"
                                                onClick={() => changePasswrod()}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default AdminProfile;