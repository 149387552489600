import $ from 'jquery';


export const Customjavascript = () => {
    $(".textinput input").change(function () {
        if ($(this).val() != "") {
            $(this).addClass('filled');
        } else {
            $(this).removeClass('filled');
        }
    })
}



export const jquerymincss = () => {
   
}
