import React from 'react'
import HightOrderCom from '../Hoc/HightOrderCom'
import RoomManagment from './roommanagment'

export default function RoomManagmentHoc(props) {
    return (
        <div>
            <HightOrderCom cmp={RoomManagment}/>
        </div>
    )
}