import axios from "axios";
import { ApiConfig } from "./apiConstants";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { signOut } from '../../../App/redux/Actions/AuthAction'
import { useHistory } from "react-router-dom";
import { Logout } from "../../../App/redux/Actions/AuthAction";
// import { logoutError } from "../../redux/actions/authAction";
import createHistory from "history/createBrowserHistory"
// const dispatch = useDispatch();
// const history = useHistory()

const fetchClient = (props) => {

  let instance = axios.create({
    // baseURL: API_URL,
    validateStatus: (status) => {
      return (
        (status >= 200 && status <= 204) ||
        status === 401 ||
        status === 400 ||
        status === 409
      );
    },
  });
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem(ApiConfig.AUTHTOKEN);
    let flag=0;
    if (!_.isEmpty(token)) {
      config.headers[ApiConfig.AUTHORIZATION_HEADER] = token;
      
      let decoded;
      try{decoded= JSON.parse(atob(token.split(".")[1]));}catch(err){
        Logout()
        // dispatch(signOut());
        props.history.push('/signin');
      }
      if (decoded && Date.now() >= decoded.exp * 1000) {
       // dispatch(signOut());
        Logout()
        props.history.push('/signin');
      }
     
    }
    if(flag===0){return config}
    // return config
  });


  instance.interceptors.response.use((response) => {
    if (response.status === 401 && response.statusText === "Unauthorized") {
        // dispatch(signOut());
        // history.push('/signin');
        localStorage.removeItem(ApiConfig.AUTHTOKEN)
        const history = createHistory();
        history.replace("/")
        window.location.reload();
        // Logout()
        // props.history.push('/signin');
    }
    try {
      // if (response.data.token) {
      //   const token = response.data.token;
      //   localStorage.setItem(ApiConfig.AUTHTOKEN, token);
      // }
      return response;
    } catch (err) {
      console.log("Error in axiosConfig", err);
    }
  });
  return instance;
};
export default fetchClient();
