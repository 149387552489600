import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import ball from '../../../../Assets/images/ball.png'
import Schedule from '../../../../Assets/images/Schedule.png'
import Navbar from "../../../common/headers/Navbar";
import { ChooseroomApi } from '../../../Api/api_calls/chooseroomApi'
import { getRoomAndPropsOfUserMethod } from '../../../Api/api_calls/cardApis';
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { errorHandleService, notifyService } from '../../../common'
import dateFormat from 'dateformat';
import LogoutEvn from '../../../common/LogoutEvn';
import InfoIcon from '@mui/icons-material/Info';

export default function ChooseGameRoom(props) {
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const [roomdata, setroomdata] = useState([])
    const auth = useSelector((state) => state.Auth);
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);

    // need to use useeffect beacause at the time of page loading we getting room data from api
    useEffect(() => {
        Getroom()
    }, [])

    // Api function for get room details
    const Getroom = () => {
        // for get today date
        var today = new Date().toISOString();
        dispatch(loaderStatus(true));
        ChooseroomApi(today).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                setroomdata(res?.data?.data)
            }
            else {
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()

        })
    }

    // enter to room -
    const enterRoom = (data) => {
        let userIdGet = auth?._id
        let roomIdGet = data._id
        dispatch(loaderStatus(true));
        getRoomAndPropsOfUserMethod(userIdGet, roomIdGet).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if (res?.data?.code == 401) {
                    notifyService.error(res?.data?.message)
                } else if (res?.data?.data?.length > 0) {
                    props.history.push("/cards_play/" + roomIdGet + "/" + userIdGet)
                } else {
                    props.history.push("/cardcreate?id=" + roomIdGet)
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const payoutCount = (entryFee, maxPlayer) => {
        if (entryFee && maxPlayer) {
            return (entryFee * maxPlayer) * 0.9
        } else {
            return 0
        }
    }

    return (
        <div className="accounts">
            <Navbar />
            <div className="accounts-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <a href="#" className="accounts-heading">
                                <img class="img-fluid" src={Schedule} /> Choose Game Room
                            </a>
                        </div>
                        <div className="col">
                            <ul className="accounts-ul">
                                <li className="balance">Balance <span>$&nbsp;{userbalance.toFixed(2)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="banner">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-4 col-md-12">
                            <div className="home-divition">
                                {roomdata && roomdata?.map((row) => (
                                    <>
                                        <div className="choose-room-card">
                                            <div className="inner-card">
                                                <div className="inner-logo">
                                                    <a href="#" className="choose-room-logo">
                                                        <img className="img-fluid" src={ball} />&nbsp;{row?.matchType}
                                                    </a>
                                                </div>
                                                <div className="inner-vs">


                                                    {
                                                        row?.title?.split("v-s")[0].length > 8 || row?.title?.split("v-s")[1].length > 8 ?
                                                            <div class="vs-section bigger-text">
                                                                <div className='first-logo'><h3>{row?.title?.split("v-s")[0]}</h3></div>
                                                                <div className="vs-text">VS</div>
                                                                <div className='second-logo'><h3>{row?.title?.split("v-s")[1]}</h3></div>
                                                            </div>
                                                            :
                                                            <div class="vs-section">
                                                                <span className='first-logo'><h3>{row?.title?.split("v-s")[0]}</h3></span>
                                                                <div className="vs-text">VS</div>
                                                                <div className='second-logo'><h3>{row?.title?.split("v-s")[1]}</h3></div>
                                                            </div>
                                                    }
                                                    <div className="vs-content">
                                                        <p><span> {dateFormat(row.startDateTime, "mmmm dS, yyyy")}</span>{dateFormat(row.startDateTime, "h:MM TT")}</p>
                                                    </div>
                                                </div>
                                                <div className="bottom-card">
                                                    <table className="bottom-table">
                                                        <tr>
                                                            <td>Entry<span>${row?.entryFee}</span></td>
                                                            <td>Max Payout<InfoIcon className='infoicon' /><span>${payoutCount(row?.entryFee, row?.maxPlayer).toFixed(2)}</span></td>
                                                            <td>Max Players<span>{row?.uid?.length + "/" + row?.maxPlayer}</span></td>
                                                        </tr>
                                                    </table>
                                                    <button class="vewbtn" onClick={() => enterRoom(row)}>CHOOSE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="margin"></div>
                </div>
            </section>
        </div>
    )
}
