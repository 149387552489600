export const ADMIN_ROUTE = [
    {
        name: 'Dashboard',
        path: "/admin_landing"
    },
    {
        name: 'Room',
        path: "/all_rooms"
    },
    {
        name: 'Manage Room',
        path: "/room_managment"
    },
    {
        name: 'All Users',
        path: "/all_users"
    },
    {
        name: 'Manage Wallet',
        path: "/manage_venmo"
    },
    {
        name: 'Privacy Policy',
        path: "/privacy_policy"
    },
    {
        name: 'Term & condition',
        path: "/terms_conditions"
    }
    // {
    //     name: 'User Queries',
    //     path: "/userquery"
    // }
];

