import React, { useEffect, useState } from 'react'
import '../../../../index.css'
import card from '../../../../Assets/images/Card.png'
import cardinactive from '../../../../Assets/images/Card-inactive.png'
import ball from '../../../../Assets/images/ball.png'
import stampchamp from '../../../../Assets/images/stampchamp.png'
import scores from '../../../../Assets/images/Scores.png'
import Navbar from '../../../common/headers/Navbar'
import '../../../../Assets/jquery/owl.carousel.min.css'
import '../../../../Assets/jquery/owl.theme.default.css'
import { useDispatch, useSelector } from "react-redux";
import { Getuserbingohistory } from '../../../Api/api_calls/userhistoryApi'
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { errorHandleService } from '../../../common'
import './userHistory.css'
import dateFormat from 'dateformat';


export default function Userhistory() {

    const dispatch = useDispatch();
    const userId = useSelector((state) => state?.Auth?._id);
    const [bingohistory, setbingohistory] = useState([])
    const [userCard, setSelectedCard] = useState({})
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);


    // Card history code start -
    // row 1
    const [r1c1, set_r1c1] = useState({})
    const [r1c2, set_r1c2] = useState({})
    const [r1c3, set_r1c3] = useState({})
    const [r1c4, set_r1c4] = useState({})
    const [r1c5, set_r1c5] = useState({})
    // row 2
    const [r2c1, set_r2c1] = useState({})
    const [r2c2, set_r2c2] = useState({})
    const [r2c3, set_r2c3] = useState({})
    const [r2c4, set_r2c4] = useState({})
    const [r2c5, set_r2c5] = useState({})
    // row 3
    const [r3c1, set_r3c1] = useState({})
    const [r3c2, set_r3c2] = useState({})
    const [r3c3, set_r3c3] = useState({})
    const [r3c4, set_r3c4] = useState({})
    const [r3c5, set_r3c5] = useState({})
    // row 4
    const [r4c1, set_r4c1] = useState({})
    const [r4c2, set_r4c2] = useState({})
    const [r4c3, set_r4c3] = useState({})
    const [r4c4, set_r4c4] = useState({})
    const [r4c5, set_r4c5] = useState({})
    // row 5
    const [r5c1, set_r5c1] = useState({})
    const [r5c2, set_r5c2] = useState({})
    const [r5c3, set_r5c3] = useState({})
    const [r5c4, set_r5c4] = useState({})
    const [r5c5, set_r5c5] = useState({})
    // Card history code close -

    useEffect(() => {
        userbingohistory();
    }, []);

    const userbingohistory = () => {
        let loginuserid = userId
        dispatch(loaderStatus(true));
        Getuserbingohistory(loginuserid).then(res => {
            if (res?.data?.result) {
                dispatch(loaderStatus(false));
                setbingohistory(res?.data?.data)
            }
            else {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    const selectedCard = (gettingObj) => {
        setSelectedCard(gettingObj)
        let getCard = gettingObj?.cardDetails
        if (getCard) {
            setCardsDeatails(getCard)
        }
    }

    const setCardsDeatails = (dataGet) => {
        set_r1c1(dataGet[0])
        set_r1c2(dataGet[1])
        set_r1c3(dataGet[2])
        set_r1c4(dataGet[3])
        set_r1c5(dataGet[4])
        // row 2
        set_r2c1(dataGet[5])
        set_r2c2(dataGet[6])
        set_r2c3(dataGet[7])
        set_r2c4(dataGet[8])
        set_r2c5(dataGet[9])
        // row 3
        set_r3c1(dataGet[10])
        set_r3c2(dataGet[11])
        set_r3c4(dataGet[12])
        set_r3c5(dataGet[13])
        // row 4
        set_r4c1(dataGet[14])
        set_r4c2(dataGet[15])
        set_r4c3(dataGet[16])
        set_r4c4(dataGet[17])
        set_r4c5(dataGet[18])
        // row 5
        set_r5c1(dataGet[19])
        set_r5c2(dataGet[20])
        set_r5c3(dataGet[21])
        set_r5c4(dataGet[22])
        set_r5c5(dataGet[23])
    }
    const checkingTooltipText = (propsName) => {
        if (propsName?.length > 12) {
            return <span class="tooltiptext">{propsName}</span>
        } else {
            return ''
        }
    }


    const checkingTooltipTexttype = (propstype) => {
        if (propstype?.length > 9) {
            return <span class="tooltiptext">{propstype}</span>
        } else {
            return ''
        }
    }

    return (
        <div class="accounts">
            <Navbar />
            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <img class="img-fluid" src={scores} /> History
                            </a>
                        </div>
                        <div class="col">
                            <ul class="accounts-ul">
                                <li class="balance">Balance <span>$&nbsp;{userbalance.toFixed(2)}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-container">
                {bingohistory?.map && bingohistory?.map((row) => (

                    <div class=" card">
                        <div class="choose-room-card historyCard">
                            <div class="inner-card">
                                <div class="inner-logo">
                                    <a href="#" class="choose-room-logo">
                                        <img class="img-fluid" src={ball} />{row?.roomId?.matchType}
                                    </a>
                                </div>
                                <div class="inner-vs cssTest">
                                    {
                                        row?.roomId?.title?.split("v-s")[0].length > 8 || row?.roomId?.title?.split("v-s")[1].length > 8 ?
                                            <div class="vs-section bigger-text">
                                                <div className='first-logo'><h3>{row?.roomId?.title?.split("v-s")[0]}</h3></div>
                                                <div className="vs-text">VS</div>
                                                <div className='second-logo'><h3>{row?.roomId?.title?.split("v-s")[1]}</h3>
                                                </div>
                                            </div>
                                            :
                                            <div class="vs-section">
                                                <div className='first-logo'><h3>{row?.roomId?.title?.split("v-s")[0]}</h3></div>
                                                <div className="vs-text">VS</div>
                                                <div className='second-logo'><h3>{row?.roomId?.title?.split("v-s")[1]}</h3></div>
                                            </div>
                                    }

                                    <div class="vs-content">
                                        <p><span> {dateFormat(row?.roomId?.startDateTime, "mmmm dS, yyyy")}</span>{dateFormat(row?.roomId?.startDateTime, "h:MM TT")}</p>
                                    </div>
                                </div>

                                <div class="bottom-card">
                                    <table class="bottom-table">
                                        <tr>
                                            <td>Place<span>{row?.winningPotion > 0 ? row?.winningPotion : 'N/A'}</span></td>
                                            <td>Points<span>{row?.points}</span></td>
                                            <td>Bingos<span>{row?.bingoCount}</span></td>
                                            <td>Won<span>${row?.winningAmt}</span></td>
                                        </tr>
                                    </table>
                                    <div class="inner-checs bingoaccounts">
                                        <ul>
                                            {row?.cardDetails && row?.cardDetails?.map((obj) =>

                                            (
                                                <>
                                                    {(() => {
                                                        if (obj?.boxId === 'r1c1') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[1].isMatch === true && row?.cardDetails[2]?.isMatch === true && row?.cardDetails[3]?.isMatch === true && row?.cardDetails[4].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[5].isMatch === true && row?.cardDetails[10]?.isMatch === true && row?.cardDetails[14]?.isMatch === true && row?.cardDetails[19]?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r1c2') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[1].isMatch === true && row?.cardDetails[2]?.isMatch === true && row?.cardDetails[3]?.isMatch === true && row?.cardDetails[4].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[1]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[11]?.isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[20].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r1c3') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[1].isMatch === true && row?.cardDetails[2]?.isMatch === true && row?.cardDetails[3]?.isMatch === true && row?.cardDetails[4].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[2]?.isMatch === true && row?.cardDetails[7].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[21].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r1c4') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[1].isMatch === true && row?.cardDetails[2]?.isMatch === true && row?.cardDetails[3]?.isMatch === true && row?.cardDetails[4].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[3]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[22].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r1c5') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[19].isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[9].isMatch === true && row?.cardDetails[13]?.isMatch === true && row?.cardDetails[18]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r2c1') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[5]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[7]?.isMatch === true && row?.cardDetails[8]?.isMatch === true && row?.cardDetails[9].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[5].isMatch === true && row?.cardDetails[10]?.isMatch === true && row?.cardDetails[14]?.isMatch === true && row?.cardDetails[19]?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r2c2') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                    {row?.cardDetails[5]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[7]?.isMatch === true && row?.cardDetails[8]?.isMatch === true && row?.cardDetails[9].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[1]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[11]?.isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[20].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r2c3') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[5]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[7]?.isMatch === true && row?.cardDetails[8]?.isMatch === true && row?.cardDetails[9].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[2]?.isMatch === true && row?.cardDetails[7].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[21].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r2c4') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[19].isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                    {row?.cardDetails[5]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[7]?.isMatch === true && row?.cardDetails[8]?.isMatch === true && row?.cardDetails[9].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[3]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[2]?.isMatch === true && row?.cardDetails[22].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r2c5') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[9].isMatch === true && row?.cardDetails[13]?.isMatch === true && row?.cardDetails[18]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-bottom"></span> : ''}

                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r3c1') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[10]?.isMatch === true && row?.cardDetails[11].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[13].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[5].isMatch === true && row?.cardDetails[10]?.isMatch === true && row?.cardDetails[14]?.isMatch === true && row?.cardDetails[19]?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r3c2') {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        {row?.cardDetails[10]?.isMatch === true && row?.cardDetails[11].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[13].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {row?.cardDetails[1]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[11]?.isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[20].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[19].isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                        {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                        {row?.cardDetails[2]?.isMatch === true && row?.cardDetails[7].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[21].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {row?.cardDetails[10]?.isMatch === true && row?.cardDetails[11].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[13].isMatch === true ? <span class="green-line-right"></span> : ''}


                                                                        {<img className="img-fluid" src={card} />}
                                                                    </li>
                                                                </>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r3c4') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[10]?.isMatch === true && row?.cardDetails[11].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[13].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[13]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[22].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r3c5') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[9].isMatch === true && row?.cardDetails[13]?.isMatch === true && row?.cardDetails[18]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r4c1') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[14]?.isMatch === true && row?.cardDetails[15].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[18].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[5].isMatch === true && row?.cardDetails[10]?.isMatch === true && row?.cardDetails[14]?.isMatch === true && row?.cardDetails[19]?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r4c2') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[19].isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                    {row?.cardDetails[14]?.isMatch === true && row?.cardDetails[15].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[18].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[1]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[11]?.isMatch === true && row?.cardDetails[15]?.isMatch === true && row?.cardDetails[20].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r4c3') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[14]?.isMatch === true && row?.cardDetails[15].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[18].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[2]?.isMatch === true && row?.cardDetails[7].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[21].isMatch === true ? <span class="green-line-bottom"></span> : ''}

                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r4c4') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[0]?.isMatch === true && row?.cardDetails[6].isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                    {row?.cardDetails[14]?.isMatch === true && row?.cardDetails[15].isMatch === true && row?.cardDetails[16]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[18].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {row?.cardDetails[3]?.isMatch === true && row?.cardDetails[8].isMatch === true && row?.cardDetails[12]?.isMatch === true && row?.cardDetails[17]?.isMatch === true && row?.cardDetails[22].isMatch === true ? <span class="green-line-bottom"></span> : ''}

                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r4c5') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[4]?.isMatch === true && row?.cardDetails[9].isMatch === true && row?.cardDetails[13]?.isMatch === true && row?.cardDetails[18]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r5c1') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[19]?.isMatch === true && row?.cardDetails[20].isMatch === true && row?.cardDetails[21]?.isMatch === true && row?.cardDetails[22]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r5c2') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[19]?.isMatch === true && row?.cardDetails[20].isMatch === true && row?.cardDetails[21]?.isMatch === true && row?.cardDetails[22]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r5c3') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[19]?.isMatch === true && row?.cardDetails[20].isMatch === true && row?.cardDetails[21]?.isMatch === true && row?.cardDetails[22]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r5c4') {
                                                            return (
                                                                <li>
                                                                    {row?.cardDetails[19]?.isMatch === true && row?.cardDetails[20].isMatch === true && row?.cardDetails[21]?.isMatch === true && row?.cardDetails[22]?.isMatch === true && row?.cardDetails[23].isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                        if (obj?.boxId === 'r5c5') {
                                                            return (
                                                                <li>
                                                                    {
                                                                        obj.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    })()}

                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                    <button class="vewbtn" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => selectedCard(row)} >VIEW</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}



            </div>

            <div class="history-modal modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <span class="first-heading">
                                    {dateFormat(userCard?.roomId?.startDateTime, "mmmm dS, yyyy")}&nbsp;
                                    {dateFormat(userCard?.roomId?.startDateTime, "h:MM TT")}
                                </span>
                                {
                                    userCard?.roomId?.title?.split("v-s")[0].length > 8 || userCard?.roomId?.title?.split("v-s")[1].length > 8 ?
                                        <span class="second-heading bigger-text">
                                            <span className='lfttitle'>{userCard?.roomId?.title?.split("v-s")[0]}</span>&nbsp;
                                            <span className="">VS</span>&nbsp;
                                            <span className='rgttitle'> {userCard?.roomId?.title?.split("v-s")[1]}
                                            </span>
                                        </span>
                                        :
                                        <span class="second-heading">
                                            <span className='lfttitle'>{userCard?.roomId?.title?.split("v-s")[0]}</span>&nbsp;
                                            <span className="">VS</span>&nbsp;
                                            <span className='rgttitle'>{userCard?.roomId?.title?.split("v-s")[1]} </span>
                                        </span>
                                }
                                <span class="third-heading">PAYOUT: ${userCard?.winningAmt}</span>
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="inner-checs">
                                <ul>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c1).length != 0 ?
                                                <div className={r1c1?.isMatch === true ? "modal-box active-join " : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r1c1?.playerId?.type.length > 9 ? r1c1?.playerId?.type?.substring(0, 9) + '...' : r1c1?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r1c1?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r1c1?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r1c1?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r1c1?.playerId?.name?.length > 12 ? r1c1?.playerId?.name.substring(0, 12) + '...' : r1c1?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r1c1?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c2).length != 0 ?
                                                <div className={r1c2.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r1c2?.playerId?.type.length > 9 ? r1c2?.playerId?.type?.substring(0, 9) + '...' : r1c2?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r1c2?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r1c2?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r1c2?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r1c2?.playerId?.name?.length > 12 ? r1c2?.playerId?.name.substring(0, 12) + '...' : r1c2?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r1c2?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c3).length != 0 ?
                                                <div className={r1c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r1c3?.playerId?.type.length > 9 ? r1c3?.playerId?.type?.substring(0, 9) + '...' : r1c3?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r1c3?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r1c3?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r1c3?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r1c3?.playerId?.name?.length > 12 ? r1c3?.playerId?.name.substring(0, 12) + '...' : r1c3?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r1c3?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c4).length != 0 ?
                                                <div className={r1c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r1c4?.playerId?.type.length > 9 ? r1c4?.playerId?.type?.substring(0, 9) + '...' : r1c4?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r1c4?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r1c4?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r1c4?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r1c4?.playerId?.name?.length > 12 ? r1c4?.playerId?.name.substring(0, 12) + '...' : r1c4?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r1c4?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c5).length != 0 ?
                                                <div className={r1c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r1c5?.playerId?.type.length > 9 ? r1c5?.playerId?.type?.substring(0, 9) + '...' : r1c5?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r1c5?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r1c5?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r1c5?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r1c5?.playerId?.name?.length > 12 ? r1c5?.playerId?.name.substring(0, 12) + '...' : r1c5?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r1c5?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c1).length != 0 ?
                                                <div className={r2c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r2c1?.playerId?.type.length > 9 ? r2c1?.playerId?.type?.substring(0, 9) + '...' : r2c1?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r2c1?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r2c1?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r2c1?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r2c1?.playerId?.name?.length > 12 ? r2c1?.playerId?.name.substring(0, 12) + '...' : r2c1?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r2c1?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest' >
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c2).length != 0 ?
                                                <div className={r2c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r2c2?.playerId?.type.length > 9 ? r2c2?.playerId?.type?.substring(0, 9) + '...' : r2c2?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r2c2?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r2c2?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r2c2?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r2c2?.playerId?.name?.length > 12 ? r2c2?.playerId?.name.substring(0, 12) + '...' : r2c2?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r2c2?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c3).length != 0 ?
                                                <div className={r2c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r2c3?.playerId?.type.length > 9 ? r2c3?.playerId?.type?.substring(0, 9) + '...' : r2c3?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r2c3?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r2c3?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r2c3?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r2c3?.playerId?.name?.length > 12 ? r2c3?.playerId?.name.substring(0, 12) + '...' : r2c3?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r2c3?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c4).length != 0 ?
                                                <div className={r2c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r2c4?.playerId?.type.length > 9 ? r2c4?.playerId?.type?.substring(0, 9) + '...' : r2c4?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r2c4?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r2c4?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r2c4?.playerId?.points} <span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r2c4?.playerId?.name?.length > 12 ? r2c4?.playerId?.name.substring(0, 12) + '...' : r2c4?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r2c4?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c5).length != 0 ?
                                                <div className={r2c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r2c5?.playerId?.type.length > 9 ? r2c5?.playerId?.type?.substring(0, 9) + '...' : r2c5?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r2c5?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r2c5?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r2c5?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r2c5?.playerId?.name?.length > 12 ? r2c5?.playerId?.name.substring(0, 12) + '...' : r2c5?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r2c5?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c1).length != 0 ?
                                                <div className={r3c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r3c1?.playerId?.type.length > 9 ? r3c1?.playerId?.type?.substring(0, 9) + '...' : r3c1?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r3c1?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r3c1?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r3c1?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r3c1?.playerId?.name?.length > 12 ? r3c1?.playerId?.name.substring(0, 12) + '...' : r3c1?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r3c1?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c2).length != 0 ?
                                                <div className={r3c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r3c2?.playerId?.type.length > 9 ? r3c2?.playerId?.type?.substring(0, 9) + '...' : r3c2?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r3c2?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r3c2?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r3c2?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r3c2?.playerId?.name?.length > 12 ? r3c2?.playerId?.name.substring(0, 12) + '...' : r3c2?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r3c2?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        <div className="modal-box active-join center-bingo" style={{ lineHeight: 'inherit' }}>
                                            {/* center */}
                                            <div className="textdiv">
                                                <div className="middle-img">
                                                    <h3>1 PTS</h3>
                                                    <img className="img-fluid" src={stampchamp} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-left"></span> : ''}
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c4).length != 0 ?
                                                <div className={r3c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r3c4?.playerId?.type.length > 9 ? r3c4?.playerId?.type?.substring(0, 9) + '...' : r3c4?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r3c4?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r3c4?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r3c4?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r3c4?.playerId?.name?.length > 12 ? r3c4?.playerId?.name.substring(0, 12) + '...' : r3c4?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r3c4?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c5).length != 0 ?
                                                <div className={r3c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r3c5?.playerId?.type.length > 9 ? r3c5?.playerId?.type?.substring(0, 9) + '...' : r3c5?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r3c5?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r3c5?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r3c5?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r3c5?.playerId?.name?.length > 12 ? r3c5?.playerId?.name.substring(0, 12) + '...' : r3c5?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r3c5?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c1).length != 0 ?
                                                <div className={r4c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r4c1?.playerId?.type.length > 9 ? r4c1?.playerId?.type?.substring(0, 9) + '...' : r4c1?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r4c1?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r4c1?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r4c1?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r4c1?.playerId?.name?.length > 12 ? r4c1?.playerId?.name.substring(0, 12) + '...' : r4c1?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r4c1?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-righttp-angle"></span> : ''}
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c2).length != 0 ?
                                                <div className={r4c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r4c2?.playerId?.type.length > 9 ? r4c2?.playerId?.type?.substring(0, 9) + '...' : r4c2?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r4c2?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r4c2?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r4c2?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r4c2?.playerId?.name?.length > 12 ? r4c2?.playerId?.name.substring(0, 12) + '...' : r4c2?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r4c2?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-top"></span> : ''}
                                        {
                                            Object.keys(r4c3).length != 0 ?
                                                <div className={r4c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r4c3?.playerId?.type.length > 9 ? r4c3?.playerId?.type?.substring(0, 9) + '...' : r4c3?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r4c3?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r4c3?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r4c3?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r4c3?.playerId?.name?.length > 12 ? r4c3?.playerId?.name.substring(0, 12) + '...' : r4c3?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r4c3?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-lefttp-angle"></span> : ''}
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c4).length != 0 ?
                                                <div className={r4c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r4c4?.playerId?.type.length > 9 ? r4c4?.playerId?.type?.substring(0, 9) + '...' : r4c4?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r4c4?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>

                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r4c4?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r4c4?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r4c4?.playerId?.name?.length > 12 ? r4c4?.playerId?.name.substring(0, 12) + '...' : r4c4?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r4c4?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c5).length != 0 ?
                                                <div className={r4c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r4c5?.playerId?.type.length > 9 ? r4c5?.playerId?.type?.substring(0, 9) + '...' : r4c5?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r4c5?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r4c5?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r4c5?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r4c5?.playerId?.name?.length > 12 ? r4c5?.playerId?.name.substring(0, 12) + '...' : r4c5?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r4c5?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c1).length != 0 ?
                                                <div className={r5c1.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    {/* <h5>{r5c1?.playerId?.type}</h5> */}
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r5c1?.playerId?.type.length > 9 ? r5c1?.playerId?.type?.substring(0, 9) + '...' : r5c1?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r5c1?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>

                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r5c1?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r5c1?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r5c1?.playerId?.name?.length > 12 ? r5c1?.playerId?.name.substring(0, 12) + '...' : r5c1?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r5c1?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c2).length != 0 ?
                                                <div className={r5c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r5c2?.playerId?.type.length > 9 ? r5c2?.playerId?.type?.substring(0, 9) + '...' : r5c2?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r5c2?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r5c2?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r5c2?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r5c2?.playerId?.name?.length > 12 ? r5c2?.playerId?.name.substring(0, 12) + '...' : r5c2?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r5c2?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c3).length != 0 ?
                                                <div className={r5c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r5c3?.playerId?.type.length > 9 ? r5c3?.playerId?.type?.substring(0, 9) + '...' : r5c3?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r5c3?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r5c3?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r5c3?.playerId?.points} <span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r5c3?.playerId?.name?.length > 12 ? r5c3?.playerId?.name.substring(0, 12) + '...' : r5c3?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r5c3?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c4).length != 0 ?
                                                <div className={r5c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r5c4?.playerId?.type.length > 9 ? r5c4?.playerId?.type?.substring(0, 9) + '...' : r5c4?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r5c4?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r5c4?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r5c4?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r5c4?.playerId?.name?.length > 12 ? r5c4?.playerId?.name.substring(0, 12) + '...' : r5c4?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r5c4?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {
                                            Object.keys(r5c5).length != 0 ?
                                                <div className={r5c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {r5c5?.playerId?.type.length > 9 ? r5c5?.playerId?.type?.substring(0, 9) + '...' : r5c5?.playerId?.type}
                                                            {
                                                                checkingTooltipTexttype(r5c5?.playerId?.type)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{r5c5?.playerId?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {r5c5?.playerId?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {r5c5?.playerId?.name?.length > 12 ? r5c5?.playerId?.name.substring(0, 12) + '...' : r5c5?.playerId?.name}
                                                            {
                                                                checkingTooltipText(r5c5?.playerId?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}


