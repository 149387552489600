
import React, { useEffect } from 'react'
import './index.css'
import Loader from '../src/App/common/Loader'
import { BrowserRouter as Router, Switch, Route ,BrowserRouter} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Customjavascript } from './custom';
import allRoutesPath from './App/routes/allPaths';
import { loadUser } from './App/redux/Actions/AuthAction';
import HomePage from './App/module/Prelogin/Homepage/homepage';
import ForgotPassword from './App/module/Prelogin/Forgotpassword/forgotpassword';
import Login from './App/module/Prelogin/Login/login';
import EnterCode from './App/module/Prelogin/Entercode/entercode';
import Register from './App/module/Prelogin/Register/register';
import RoleBasedRouting from './App/routes/RoleBasedRouting';
import Pagenotfound from './App/module/Errors/pagenotfound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SnackbarProvider } from 'notistack';
import Zoom from '@mui/material/Zoom';
import Preloginprivacypolicy from './App/module/Prelogin/Privacypolicy/privacypolicy';
import Prelogintermsandconditions from './App/module/Prelogin/Termsandconditions/termsandconditions';

function App() {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state?.Loader?.status);

  useEffect(() => {
    dispatch(loadUser());
    Customjavascript();
  }, [dispatch]);

  return (
    <BrowserRouter>
    <SnackbarProvider maxSnack={3} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
    TransitionComponent={Zoom}
>
      <Router >
        <Loader loaderStatus={loader} />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/entercode" component={EnterCode} />
          <Route exact path="/privacypolicy" component={Preloginprivacypolicy} />
          <Route exact path="/termsandconditions" component={Prelogintermsandconditions} />

          {allRoutesPath?.map(
            (
              {
                exact,
                path,
                component: Component,
                roles
              },
              index
            ) => (
              <RoleBasedRouting
                key={index}
                path={path}
                exact
                component={Component}
                roles={roles}
              />
            )
          )}
          <Route path="*" component={Pagenotfound} />
        </Switch>
      </Router>
      <ToastContainer toastStyle={{ backgroundColor: "#003115", color: "#ffffff" }} />
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
