import authReducer from "./AuthReducers";
import loaderReducer from "./LoaderReducer";
import userReducer from "./userReducer";
import userCardReducer from "./userCardReducer";
import balanceReducer from "./balanceReducers";

const reducers = {
    Auth: authReducer,
    Loader: loaderReducer,
    user:userReducer,
    Card:userCardReducer,
    userBalance:balanceReducer
}

export default reducers;