import { wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const forgotpasswordApi = (data) => {
    return wrappedFetch(Url.FORGOT_PASSWORD_URL, "POST", data);
};

export const forgotpasswordupdateApi = (data) => {
    return wrappedFetch(Url.FORGOT_PASSWORD_UPDATE_URL, "POST", data);
};


