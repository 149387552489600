import { CARD_R00M_DETAILS_STORE } from "../Actions/ActionTypes";

const initialState = {
    cardDetails: [],
    roomId: {}
  };

  const userCardReducer = (state = initialState, action) => {
    switch (action.type) {  
      case CARD_R00M_DETAILS_STORE:
        return {
        cardDetails: action.payload.cardDetails,
        roomId: action.payload.roomId
        };
      default:
        return state;
    }
  };
  export default userCardReducer;