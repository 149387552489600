import React, { useEffect, useState, } from 'react'
import { useDispatch } from 'react-redux';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import leaderboard from '../../../../Assets/images/Leaderboard-logo.png'
import { myLeaderBoardMethod, commanLeaderBoardMethod } from '../../../Api/api_calls/cardApis';
import { errorHandleService } from '../../../common';
import LogoutEvn from '../../../common/LogoutEvn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';


export default function Leaderboard(props) {
    const [userLeaderInfo, setUserLeaderBoardInfo] = useState({})
    const [commanLeaderInfo, setCommanLeaderBoardInfo] = useState([])
    const userId = useSelector((state) => state?.Auth?._id);

    let newPlaceArray = [];
    // const [newPlaceArray, setNewPlaceArray] = useState([])

    const logoutEvents = LogoutEvn()
    const dispatch = useDispatch();

    useEffect(() => {
        getCommonLeaderboardInfo(props?.roomId)
    }, []);

    useEffect(() => {
        if (props?.userId && props?.roomId && props?.userCardId) {
            getMyLeaderboardInfo(props?.userId, props?.roomId, props?.userCardId)
        }
    }, [props?.userId, props?.roomId, props?.userCardId]);


    // get my leaderboard info ----
    function getMyLeaderboardInfo(userId, roomId, userCardId) {
        dispatch(loaderStatus(true));
        myLeaderBoardMethod(userId, roomId, userCardId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setUserLeaderBoardInfo(res?.data?.results)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get my leaderboard info ----
    function getCommonLeaderboardInfo(roomId) {
        dispatch(loaderStatus(true));
        commanLeaderBoardMethod(roomId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setCommanLeaderBoardInfo(res?.data?.results)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    return (
        <div class="player-table">
            <div class="table-responsive">
                <table class="table header-fixed"
                    style={{ border: '2px solid #151515 ' }}
                >
                    <thead>
                        <tr rowspan="4" class="pointstr">
                            <th>Game Over</th>
                        </tr>
                        <tr class="threecoltr">
                            <th scope="col">PLACE <span>{userLeaderInfo?.winningPotion ? userLeaderInfo?.winningPotion : 'N/A'}</span></th>
                            <th scope="col">POINTS <span>{userLeaderInfo?.points ? userLeaderInfo?.points : 0}</span></th>
                            <th scope="col">BINGOS <span>{userLeaderInfo?.bingoCount ? userLeaderInfo?.bingoCount : 0}</span></th>
                            <th scope="col">WON <span>${userLeaderInfo?.winningAmt ? userLeaderInfo?.winningAmt : 0}</span></th>
                        </tr>
                        <tr class="leaderboardtr">
                            <th>
                                <img class="img-fluid" src={leaderboard} /> Leaderboard
                            </th>
                        </tr>
                        <tr class="trheading">
                            <th scope="col" style={{ textAlign: 'center' }}>Place</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Name</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Bingos</th>
                            <th scope="col" className="tableheades" style={{ textAlign: 'center' }}>PTS</th>
                        </tr>
                    </thead>
                    <tbody class="cardplay winnertbl" style={{ backgroundColor: '#E7E9F2' }}>
                        {commanLeaderInfo && commanLeaderInfo?.map((row, index) => (
                            <tr className="tbodytr">
                                <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.winningPotion}</td>
                                <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.uid?.name}</td>
                                <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.bingoCount}</td>
                                <Grid container>
                                    <Grid xs={9.5}>
                                        <td className="filterable-cell" style={{ textAlign: 'center' }}>{row?.points}&nbsp;&nbsp; </td>
                                    </Grid>
                                    <Grid xs={2.5}>
                                        {row?.winningPotion === 1 ? <EmojiEventsIcon className='winnerCss' /> : <></>}
                                    </Grid>
                                </Grid>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
