import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader(props) {
    // const classes = useStyles();

    return (
        <div>
            <Backdrop sx={{ color: 'green', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.loaderStatus}>
                <CircularProgress color="success" />
            </Backdrop>
        </div>
    )
}