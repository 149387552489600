import React from 'react'
import HightOrderCom from '../Hoc/HightOrderCom'
import PrivacyPolicy from './privacypolicy'

export default function PrivacyPolicyHoc(props) {
    return (
        <div>
            <HightOrderCom cmp={PrivacyPolicy}/>
        </div>
    )
}