import React from 'react'
import '../../../../index.css'
import Phone from '../../../../Assets/images/Phone.png'
import navlogo from '../../../../Assets/images/navLogo.png'
import { Link,Redirect } from 'react-router-dom'
import { useSelector } from "react-redux";
import { ROLE } from '../../../constants/constants'
import Navbar from '../../../common/headers/Navbar'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function HomePage(props) {

    const auth = useSelector((state) => state.Auth);
    if (auth?.role == ROLE[1]) {
        return <Redirect to='/admin_landing' />
    }
    if (auth?.role == ROLE[0]) {
        return <Redirect to='/account' />
    }

    return (

        <div className="home">
            <Navbar />
            <section className="banner">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-10 col-md-12">
                            <div className="content-div">
                                <div className="content-area mobile">
                                    <img className="img-fluid contentimg" src={navlogo} />
                                    <h1>A NEW WAY TO BINGO</h1>
                                    <p>Enjoy the excitement of online sports gaming with the thrill of bingo competition!<br />
                                        StampChamp gives you the ability to CUSTOMIZE your very own bingo-style card with proposition (prop) bets from a live sporting event.<br />
                                        Simply join a game room, drag and drop your favorite props onto your blank bingo card, then sit back and watch as your card gets stamped in REAL TIME as the props occur in the sporting event!<br />
                                        Achieving bingos earns you points, and the more points you earn, the higher your name will rise on the leaderboard. 1st place takes home the prize!</p>
                                </div>
                                <div className="image-div">
                                    <img className="img-fluid" src={Phone} />
                                </div>
                                <div className="button-section mobile">
                                    <Link to="/register" className="signup">SIGN UP TO START PLAYING</Link>
                                    <Link to="/login" className="login">LOG IN TO CONTINUE</Link>
                                </div>
                                <div className="content-area desktop">
                                    <img className="img-fluid contentimg" src={navlogo} />
                                    <h1>A NEW WAY TO BINGO</h1>
                                    <p>Enjoy the excitement of online sports gaming with the thrill of bingo competition!<br />
                                        StampChamp gives you the ability to CUSTOMIZE your very own bingo-style card with proposition (prop) bets from a live sporting event.<br />
                                        Simply join a game room, drag and drop your favorite props onto your blank bingo card, then sit back and watch as your card gets stamped in REAL TIME as the props occur in the sporting event!<br />
                                        Achieving bingos earns you points, and the more points you earn, the higher your name will rise on the leaderboard. 1st place takes home the prize!</p>
                                    <div className="button-section">
                                        <Link to="/register" className="signup">SIGN UP TO START PLAYING</Link>
                                        <Link to="/login" className="login">LOG IN TO CONTINUE</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center'>
                        <div className="col col-lg-10 col-md-12 footerlink">
                            <Link to="/privacypolicy" className="linkCss">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;
                            <Link to="/termsandconditions" className="linkCss">Term & Condition</Link>
                        </div>
                    </div>
                </div>
                {/* <Grid container>
                    <Grid xs={6} className='textCenter'>
                    </Grid>
                    <Grid xs={6} className='textCenter'>
                        <Box pb={3}>
                        <Link to="/privacypolicy" className="linkCss">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;
                        <Link to="/termsandconditions" className="linkCss">Term & Conditions</Link>
                        </Box>
                    </Grid>
                </Grid> */}
            </section>
        </div>
    );
}