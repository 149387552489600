import React, { useState, useEffect } from 'react'
import '../../../../index.css'
import { useDispatch } from "react-redux";
import Navbar from '../../../common/headers/Navbar'
import Ball from '../../../../Assets/images/ball.png'
import { getPrivacypolicy } from '../../../Api/api_calls/privacypolicyApi'
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { errorHandleService } from '../../../common'

export default function Preloginprivacypolicy(props) {
    const dispatch = useDispatch();
    const [content, setContent] = useState(null)

    function getPrivacypolicyMethod() {
        dispatch(loaderStatus(true));
        getPrivacypolicy().then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) {
                setContent(res?.data?.data?.privacyPolicy)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    // Get request to get privacy policy -
    useEffect(() => {
        getPrivacypolicyMethod()
        // eslint-disable-next-line
    }, [])

    const homepage = () => {
        props.history.push('/')
    }

    return (
        <div className="accounts">
            <Navbar />
            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <img class="img-fluid" src={Ball} onClick={homepage} /> PRIVACY POLICY
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="banner">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div class="col col-lg-7 col-md-12">
                            <div class="terms-page">
                                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}





