import React, { useState, useEffect } from "react";
import '../../../../index.css'
import { useDispatch, useSelector } from "react-redux";
import Frame from '../../../../Assets/images/Frame.png'
import AvtarImg from '../../../../Assets/images/AvtarImg.png'
import lock from '../../../../Assets/images/lock.png'
import vicon from '../../../../Assets/images/vicon.png'
import accounticon from '../../../../Assets/images/accounticon.png'
import walet from '../../../../Assets/images/walet.png'
import Navbar from "../../../common/headers/Navbar";
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { notifyService, errorHandleService } from "../../../common";
import { loaderStatus } from "../../../redux/Actions/LoaderAction";
import { storeUserDetails } from "../../../redux/Actions/userAction";
import { changePasswordApi, changeVenmoApi, updateProfileMethod, getUserProfile, DeleteUser, uploadImageProfile, DeleteUserimage } from "../../../Api/api_calls/profileApi";
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import { generaterefferal } from "../../../Api/api_calls/generatereferalApi";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDashbaordDetailsUserMethod } from "../../../Api/api_calls/dashboard";
import LogoutEvn from "../../../common/LogoutEvn";
import createHistory from "history/createBrowserHistory"
import {ApiConfig} from '../../../Api/api_setting/apiConstants'



const useStyles = makeStyles((theme) => ({
    inputDisplay: {
        display: 'none'
    },
    inputSetting: {
        color: '#78ce52',
        cursor: 'pointer'
    },
    editClass: {
        color: '#78ce52',
        fontSize: '2rem',
        marginRight: '6px'
    },
    cursorSet: {
        cursor: 'not-allowed'
    }
}));


export default function ProfilePage(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const logoutEvents = LogoutEvn()
    // image upload -
    const [file, setFile] = useState(AvtarImg);
    const [filePath, setFilePath] = useState(null);
    // field data -
    const [email, setEmail] = useState('')
    const [userName, setUserName] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [toggledName, setToggledName] = useState(false)
    const filledUnFiled = (toggledName) ? 'filled' : '';
    const [toggledPhone, setToggledPhone] = useState(false)
    const filledUnFiledPhone = (toggledPhone) ? 'filled' : '';
    const userId = useSelector((state) => state?.Auth?._id);
    const role = useSelector((state) => state?.Auth?.role);
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);
    // Change passwrod code start ----
    const [oldPasswrod, setOldPasswrod] = useState('')
    const [nawPasswrod, setNawPasswrod] = useState('');
    const [conformPasswrod, setConformPasswrod] = useState('');
    const [toggledoldPasswrod, setToggledoldPasswrod] = useState(false)
    const filledUnFiledoldPasswrod = (toggledoldPasswrod) ? 'filled' : '';
    const [togglednawPasswrod, setTogglednawPasswrod] = useState(false)
    const filledUnFilednawPasswrod = (togglednawPasswrod) ? 'filled' : '';
    const [toggledconformPasswrod, setToggledconformPasswrod] = useState(false)
    const filledUnFiledconformPasswrod = (toggledconformPasswrod) ? 'filled' : '';
    // for error message passwrod
    const [errorMessagePasswrod, setErrorMessagePasswrod] = useState('')
    // Change venmo code start ----
    const [oldVenmo, setOldVenmo] = useState('')
    const [newVenmo, setNewVenmo] = useState('');
    const [togglednewVenmo, setTogglednewVenmo] = useState(false)
    const filledUnFilednewPasswrod = (togglednewVenmo) ? 'filled' : '';
    // for error message passwrod
    const [errorMessageVenmo, setErrorMessageVenmo] = useState('')
    const [photoChnageMessage, setPhotoChnageMessage] = useState(false)
    // setrefferalcode data state
    const [referralcodedata, setreferralcodedata] = useState('')
    const [userLastCard, setLastCardDetial] = useState({})

    // state for check user image exists or not
    const [profilecheck, setprofilecheck] = useState('')

    // Change passwrod code close ----
    useEffect(() => {
        getUserDetails();
        getProfileDetailsUser(userId)

    }, []);


    //Get user profile details --
    function getProfileDetailsUser(userId) {
        dispatch(loaderStatus(true));
        getDashbaordDetailsUserMethod(userId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if (res?.data?.data.length > 0) {
                    setLastCardDetial(res?.data?.data[0])
                } else {
                    setLastCardDetial({})
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }


    const Homehandle = () => {
        props.history.push("/");
    }

    // Upload image url set  -
    function uploadSingleFileMethod(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setFilePath(e.target.files[0]);
        setPhotoChnageMessage(true)
    }

    // Update First Name Field -
    const nameUpdate = (e) => {
        if (e.target.value === "") {
            setToggledName(false)
        } else {
            setToggledName(true)
        }
        setName(e.target.value)
    }

    const phoneUpdate = (e) => {
        if (e.target.value === "") {
            setToggledPhone(false)
        } else {
            setToggledPhone(true)
        }
        setPhone(e.target.value)
    }

    // get user details -
    function getUserDetails() {
        let userIdSend = userId
        dispatch(loaderStatus(true));
        getUserProfile(userIdSend).then(res => {
            dispatch(loaderStatus(false));
            let profileData = res.data?.data[0]
            let profileImage = res.data?.data[0]?.imgUrl;
            setprofilecheck(res.data?.data[0]?.imgUrl)
            setOldVenmo(res.data?.data[0]?.venmo)
            let reduxObject = {}
            if (profileData) {
                reduxObject['email'] = profileData?.email
                reduxObject['userName'] = profileData?.userName
                reduxObject['phone'] = profileData?.phone
                reduxObject['name'] = profileData?.name
                reduxObject['imgUrl'] = profileImage
                setEmail(profileData?.email)
                setUserName(profileData?.userName)
                setPhone(profileData?.phone)
                setName(profileData?.name)
                if (profileData?.name) {
                    setToggledName(true)
                }
                if (profileData?.phone) {
                    setToggledPhone(true)
                }
                if (profileImage) {
                    setFile(profileImage)
                } else {
                    setFile(AvtarImg)
                }
            } else {
                reduxObject['email'] = ''
                reduxObject['userName'] = ''
                reduxObject['phone'] = ''
                reduxObject['name'] = ''
                reduxObject['imgUrl'] = ''
            }
            dispatch(storeUserDetails(reduxObject))
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    const updateProfile = () => {
        let updateObject = {}
        if (!name) {
            notifyService.error('Name is required')
            return
        }
        let alphaExp = /^[a-zA-Z\s]*$/
        if (!name.match(alphaExp)) {
            notifyService.error('Only alphabets allowed in name')
            return
        }
        if (phone) {
            if ((phone.length < 10) || (phone.length > 10)) {
                notifyService.error('Please enter valid phone number')
                return
            }
        }

        updateObject['name'] = name
        updateObject['phone'] = phone
        updateObject['_id'] = userId
        updateProfleMethod(updateObject)

    }

    const updateProfleMethod = (finalUpdateObject) => {
        dispatch(loaderStatus(true));
        setPhotoChnageMessage(false)
        updateProfileMethod(finalUpdateObject).then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) {
                if (filePath) {
                    let data = new FormData();
                    data.append('image', filePath);
                    data.append('_id', userId);
                    dispatch(loaderStatus(true));
                    uploadImageProfile(data).then(resData => {
                        dispatch(loaderStatus(false));
                        getUserDetails();
                        notifyService.success('Profile updated successfully')
                    }).catch(err => {
                        dispatch(loaderStatus(false));
                        errorHandleService.onErrorHandle()
                    })
                } else {
                    getUserDetails()
                    dispatch(loaderStatus(false));
                    notifyService.success('Profile updated successfully')
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    // Change password code working
    const conformPasswrodMethodSet = (value) => {
        setConformPasswrod(value)
        if (value != '') {
            setToggledconformPasswrod(true)
        }
    }

    const oldPasswrodMethod = (value) => {
        setOldPasswrod(value)
        if (value != '') {
            setToggledoldPasswrod(true)
        }
    }

    const newPasswrodMethod = (value) => {
        setNawPasswrod(value)
        if (value != '') {
            setTogglednawPasswrod(true)
        }
    }

    const changePasswrod = () => {
        let passwordformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
        if (!oldPasswrod) {
            setErrorMessagePasswrod('Please enter your old password')
            return
        } else {
            setErrorMessagePasswrod('')
        }

        if (!nawPasswrod) {
            setErrorMessagePasswrod('Please enter your new password')
            return
        } else {
            setErrorMessagePasswrod('')
        }

        if (!nawPasswrod.match(passwordformat)) {
            setErrorMessagePasswrod('A minimum 8 characters password required with combination of uppercase and lowercase and special character')
            return
        } else {
            setErrorMessagePasswrod('')
        }
        // --------------------------------------------------------------------
        if (!conformPasswrod) {
            setErrorMessagePasswrod('Please enter your confirm password')
            return
        } else {
            setErrorMessagePasswrod('')
        }

        if (nawPasswrod != conformPasswrod) {
            setErrorMessagePasswrod('New password and confirm password should be same')
            return
        } else {
            setErrorMessagePasswrod('')
        }

        let passwrodObj = {}
        passwrodObj['oldPassword'] = oldPasswrod
        passwrodObj['newPassword'] = nawPasswrod
        passwrodObj['confirmPassword'] = conformPasswrod
        passwrodObj['_id'] = userId
        dispatch(loaderStatus(true));
        changePasswordApi(passwrodObj).then(res => {
            if (res?.data?.result) {
                dispatch(loaderStatus(false));
                resetPasswrod()
                notifyService.success('Password changed successfully')
                document.querySelector('#closeModal').click()
            } else {
                dispatch(loaderStatus(false));
                resetPasswrod()
                notifyService.error(res.data.error)
                document.querySelector('#closeModal').click()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    // password Reset -
    const resetPasswrod = () => {
        setOldPasswrod('')
        setNawPasswrod('')
        setConformPasswrod('')
        setToggledoldPasswrod(false)
        setTogglednawPasswrod(false)
        setToggledconformPasswrod(false)
        setErrorMessagePasswrod('')
        setPhotoChnageMessage(false)
    }

    const resetAll = () => {
        resetPasswrod()
    }

    const setNewVenmoMethod = (value) => {
        setNewVenmo(value)
        if (value != '') {
            setTogglednewVenmo(true)
        }
    }

    const newVenmoChangeSubmit = () => {
        if (!oldVenmo) {
            setErrorMessageVenmo('Please enter your old Venmo')
            return
        } else {
            setErrorMessageVenmo('')
        }

        if (!newVenmo) {
            setErrorMessageVenmo('Please enter your new Venmo')
            return
        } else {
            setErrorMessageVenmo('')
        }
        if ((newVenmo <= 4) || (newVenmo >= 16)) {
            setErrorMessageVenmo("New Venmo should be between 5-16")
            return
        } else {
            setErrorMessageVenmo('')
        }
        if (!newVenmo.match(/^[a-zA-Z0-9_@-]+$/)) {
            setErrorMessageVenmo("No special character will be allowed other than -, _ , @")
            return
        } else {
            setErrorMessageVenmo('')
        }

        let venmoObj = {};
        venmoObj['newVenmo'] = newVenmo
        venmoObj['oldVenmo'] = oldVenmo
        venmoObj['_id'] = userId
        dispatch(loaderStatus(true));
        changeVenmoApi(venmoObj).then(res => {
            if (res?.data?.result) {
                dispatch(loaderStatus(false));
                resetPasswrod()
                getUserDetails()
                document.querySelector('#venmochangeclose').click()
                notifyService.success('Venmo changed successfully')
                document.querySelector('#venmochangeclose').click()
            } else {
                dispatch(loaderStatus(false));
                resetPasswrod()
                getUserDetails()
                document.querySelector('#venmochangeclose').click()
                notifyService.error(res?.data?.error)
                document.querySelector('#venmochangeclose').click()
            }
        }).catch(err => {
            document.querySelector('#venmochangeclose').click()
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    // program to generate random strings
    // declare all characters
    const characters = 'abcdefghijklmnopqrstuvwxyz';

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // Generate refferal code api method
    const Generaterefferalcode = () => {
        let referraldata = {};
        referraldata['sentBy'] = userId
        referraldata['referalCode'] = (generateString(4)).trim()
        referraldata['role'] = role
        dispatch(loaderStatus(true));
        generaterefferal(referraldata).then(res => {
            if (res?.data?.result) {
                setreferralcodedata(res?.data?.data?.referalCode)
                document.querySelector('#refferalmodal').click()
                dispatch(loaderStatus(false));
            }
            else {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    // User soft delete api hit
    const DeleteuserApi = () => {
        let loginuserid = userId
        dispatch(loaderStatus(true));
        DeleteUser(loginuserid).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                document.querySelector('#deleteActId').click()
                notifyService.success('User deleted successfully')
                const history = createHistory();
                localStorage.removeItem(ApiConfig.AUTHTOKEN)
                history.replace("/")
                window.location.reload();
            }
            else {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })

    }

    // method for delete user image 
    const Deleteuserimagemethod = () => {
        let loginuserid = userId
        dispatch(loaderStatus(true));
        DeleteUserimage(loginuserid).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                getUserDetails()
                notifyService.success('Photo removed successfully')
            }
            else {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })

    }

    // venmo Reset -
    const resetVenmo = () => {
        // setOldVenmo('')
        setNewVenmo('')
        setTogglednewVenmo(false)
        setErrorMessageVenmo('')
        setPhotoChnageMessage(false)
    }

    const resetVenmoMethod = () => {
        resetVenmo()
    }

    return (
        <div class="accounts">
            <Navbar />
            <div class="accounts-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a href="#" class="accounts-heading">
                                <EditIcon className={classes.editClass} />Profile
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section class="banner create-card">
                <div class="container-fluid">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-12 col-md-12">
                            <div class="profile-section">
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 mobilehide">
                                        <img className="img-fluid cursorPoint" src={Frame} onClick={Homehandle} />
                                    </div>
                                    <div class="col-lg-8 col-md-12">
                                        <div class="row justify-content-md-center">
                                            <div class="col-lg-10 col-md-12">
                                                <div class="edit-profile">
                                                    <div class="profileimg-section">
                                                        <Box pb={3}>
                                                            <div class="photosection">
                                                                <img src={file} class="img-size" alt="profile" />
                                                                <input accept="image/*" id="icon-button-file" className={classes.inputDisplay} type="file" onChange={uploadSingleFileMethod} />

                                                            </div>
                                                            <div class="buttonarea" style={{ textAlign: "center" }}>
                                                                <label htmlFor="icon-button-file">
                                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                                        <h6>Change photo</h6>
                                                                    </IconButton>
                                                                </label>
                                                                <label>
                                                                    <IconButton color="primary"
                                                                        onClick={Deleteuserimagemethod}
                                                                    >
                                                                        {profilecheck ? <h6>Remove Photo</h6> : ''}
                                                                    </IconButton>
                                                                </label>
                                                            </div>
                                                        </Box>
                                                        {
                                                            (() => {
                                                                if (photoChnageMessage) {
                                                                    return <div>
                                                                        <Box pt={1}>
                                                                            <Alert severity="warning">Warning ! without save your photo changes will be discard ..!</Alert>
                                                                        </Box>
                                                                    </div>
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                    <div class="profile-field">
                                                        <div class="row cls-mobile-show">
                                                            <div class="col-lg-6 col-md-12 cursorSet">
                                                                <ul class="walet-section">
                                                                    <li>
                                                                        <span class="walet-img"><img className="img-fluid" src={walet} /></span>
                                                                        <h6>wallet Money <span>$&nbsp;{userbalance.toFixed(2)}</span></h6>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-12 cursorSet">
                                                                <div class="textinput cursorSet">
                                                                    <input type="text" id="Userid" value={userName} className="filled cursorSet" disabled={true} />
                                                                    <label for="Userid" className="cursorSet">USER ID</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-12">
                                                                <div class="textinput">
                                                                    <input type="text" id="Username" value={name} onChange={nameUpdate} className={filledUnFiled} />
                                                                    <label for="Username">NAME</label>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-12 cursorSet">
                                                                <div class="textinput cursorSet">
                                                                    <input type="text" id="emailid" value={email} className="filled cursorSet" disabled={true} />
                                                                    <label for="emailid" className="cursorSet">EMAIL ID</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-12">
                                                                <div class="textinput">
                                                                    <input type="number" id="phonenumber" value={phone} onChange={phoneUpdate} className={filledUnFiledPhone} />
                                                                    <label for="phonenumber">PHONE NUMBER</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="gamepoins-section">
                                                                    <ul class="poins-sec">
                                                                        <li>
                                                                            <p>Total <br /> Bingos <span>{userLastCard?.totalBingos ? userLastCard?.totalBingos : 0}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="gamepoins-section">
                                                                    <ul class="poins-sec">
                                                                        <li>
                                                                            <p>Games <br />Played <span>{userLastCard?.count ? userLastCard?.count : 0}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="gamepoins-section">
                                                                    <ul class="poins-sec">
                                                                        <li>
                                                                            <p>Total <br />Winnings <span>${userLastCard?.totalWinnings ? userLastCard?.totalWinnings.toFixed(2) : 0}</span></p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="edit-section">
                                                                    <ul>
                                                                        <li>
                                                                            <a data-bs-toggle="modal" data-bs-target="#changepassword" className={classes.inputSetting}>
                                                                                <span><img src={lock} /></span>
                                                                                Change Password
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="edit-section">
                                                                    <ul>
                                                                        <li>
                                                                            <a data-bs-toggle="modal" data-bs-target="#venmo" className={classes.inputSetting}>
                                                                                <span><img src={vicon} /></span>
                                                                                Change Venmo handle
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-12">
                                                                <div class="edit-section">
                                                                    <ul>
                                                                        <li>
                                                                            <button className="buttonHide " data-bs-toggle="modal" data-bs-target="#getreferral" id="refferalmodal" onClick={(e) => { e.preventDefault(); }}>hideButton</button>
                                                                            <a data-bs-toggle="modal" onClick={Generaterefferalcode} className={classes.inputSetting}>
                                                                                <span><img src={accounticon} /></span>
                                                                                Generate referral
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button class="buttonsignup" onClick={updateProfile}>SAVE CHANGES</button>
                                                    </div>

                                                    <div class="delete" data-bs-toggle="modal" data-bs-target="#deleteconfirmation">
                                                        <span>
                                                            DELETE ACCOUNT</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-lg-2 col-md-12 cls-hide-mobile">
                                        <ul class="walet-section">
                                            <li>
                                                <span class="walet-img"><img className="img-fluid" src={walet} /></span>
                                                <h6>wallet Money <span>$&nbsp;{userbalance.toFixed(2)}</span></h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="history-modal modal fade" id="changepassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                </div>
                                <div class="modal-body">
                                    <h2>Change password</h2>
                                    <p className="ErrorNew">{errorMessagePasswrod}</p>
                                    <form onSubmit={e => e.preventDefault()}>
                                        <div class="textinput">
                                            <input type="password" id="password" value={oldPasswrod} className={filledUnFiledoldPasswrod} onChange={(e) => oldPasswrodMethod(e.target.value)} />
                                            <label for="password">Old Password</label>
                                        </div>
                                        <div class="textinput">
                                            <input type="password" id="newpassword" value={nawPasswrod} className={filledUnFilednawPasswrod} onChange={(e) => newPasswrodMethod(e.target.value)} />
                                            <label for="newpassword">new Password</label>
                                        </div>
                                        <div class="textinput">
                                            <input type="password" id="confirmpassword" className={filledUnFiledconformPasswrod} value={conformPasswrod} onChange={(e) => conformPasswrodMethodSet(e.target.value)} />
                                            <label for="confirmpassword">Confirm Password</label>
                                        </div>
                                        <button class="yes-submit" onClick={changePasswrod}>YES, SUBMIT</button>
                                        <button class="no-submit" aria-hidden="false" type="button" id="closeModal" data-bs-dismiss="modal" onClick={resetAll}>NO, GO BACK</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="history-modal modal fade" id="venmo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                </div>
                                <div class="modal-body">
                                    <h2>Change venmo</h2>
                                    <p className="ErrorNew">{errorMessageVenmo}</p>
                                    <form onSubmit={e => e.preventDefault()}>
                                        <div class="textinput">
                                            <input type="text" id="oldvenmo" value={oldVenmo} className='filled cursorSet' disabled={true} />
                                            <label for="oldvenmo">Old Venmo</label>
                                        </div>
                                        <div class="textinput">
                                            <input type="text" id="newvenmo" value={newVenmo} className={filledUnFilednewPasswrod} onChange={(e) => setNewVenmoMethod(e.target.value)} />
                                            <label for="newvenmo">new Venmo</label>
                                        </div>
                                        <button class="yes-submit" onClick={newVenmoChangeSubmit}>YES, SUBMIT</button>
                                        <button class="no-submit" id="venmochangeclose" data-bs-dismiss="modal" onClick={resetVenmoMethod}>NO, GO BACK</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="history-modal modal fade" id="deleteconfirmation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                </div>
                                <div class="modal-body">
                                    <h2>Delete Account</h2>
                                    <p className="ErrorNew">{errorMessageVenmo}</p>
                                    <form onSubmit={e => e.preventDefault()}>
                                        <p>Are you sure you want to delete your account ?</p>
                                        <button class="yes-submit" onClick={DeleteuserApi}>YES, SUBMIT</button>
                                        <button class="no-submit" id="deleteActId" data-bs-dismiss="modal">NO, GO BACK</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="history-modal modal fade" id="getreferral" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                </div>
                                <div class="modal-body">
                                    <h2>Refferal Code</h2>
                                    <form>
                                        <div className="textinput">
                                            <input type="text" id="confirmpassword" disabled="disabled" className='filled cursorSet' value={referralcodedata}
                                            />
                                        </div>
                                        <CopyToClipboard text={referralcodedata}
                                            onCopy={() => setreferralcodedata(referralcodedata)}>
                                            <button class="yes-submit">Copy to clipboard</button>
                                        </CopyToClipboard>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="margin"></div>
                </div>
            </section >
        </div >

    );
}





