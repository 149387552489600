import React, { useState, useEffect } from "react";
import { Customjavascript } from "../../../../custom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { notifyService,errorHandleService} from "../../../common";
import { loaderStatus } from "../../../redux/Actions/LoaderAction";
import jwtDecode from 'jwt-decode';
import Navbar from "../../../common/headers/Navbar";
import { UserMessage } from "../../../constants/MessageConfig";
import{ Url } from "../../../Api/api_setting/apiConstants"

export default function EnterCode(props) {
  const dispatch = useDispatch();
  // state manage for Verification code fields
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  // state for store registration data for send otp 
  const [userData, setUser] = useState(null);
  // getting registration details for verify otp for user
  const RegDetails = props.location.state;
  // getting details of inactive user and using it for decode token
  const logindata = props.location.state


  // function for resend otp 
  const resentMethod = () => {
    Sendotp()
  }

  // for enable custom jquery
  useEffect(() => {
    Customjavascript();
    if(logindata?.data){
      setUser(logindata?.data)
    }
    if(RegDetails?.data){
      setUser(RegDetails?.data)
    }
  }, []);


  // Send otp api(Getting hit on resend code button on click)
  const Sendotp = () => {
    let decodedata='';
    if(userData?.token){
      decodedata = jwtDecode(userData?.token);
    }else{
      props.history.push("/login");
    }

    let otpdata = {
      email: RegDetails?.data?.email || decodedata.email,
      _id: RegDetails?.data?._id || decodedata._id
    }
    const headers = {
      'x-access-token': userData?.token
    };
    dispatch(loaderStatus(true));
    axios.post(Url?.OTP_SEND_API, otpdata, { headers })
      .then(response => {
        dispatch(loaderStatus(false));
        if (response?.status) {
          notifyService.success(UserMessage.OTP_SENT)
        } else {
          notifyService.error(UserMessage.SOMETHING_WRONG)
        }
      })
      .catch((error) => {
        props.history.push("/login");
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      });

  }

  // api for verify otp (we have to call the api with axios method because we need to send token in header)
  const EntercodeSuccess = () => {
    let decodedata='';
    if(userData?.token){
      decodedata = jwtDecode(userData?.token);
    }else{
      props.history.push("/login");
    }
    let codeval = code.trim()
    if (!codeval) {
      setCodeError(UserMessage.ENTER_CODE)
      return
    }
    let data = {
      otp: code,
      _id: userData?._id || decodedata?._id
    };
    const headers = {
      'x-access-token': userData?.token 
    };

    dispatch(loaderStatus(true));
    axios.post(Url?.OTP_MATCH, data, { headers })
      .then(response => {
        dispatch(loaderStatus(false));
        if (response?.data?.result) {
          props.history.push("/login");
          notifyService.success(UserMessage.OTP_MATCH)
        }
        else {
          notifyService.error(response?.data?.message)
        }
      })
      .catch((error) => {
        props.history.push("/login");
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      });

  }

  // Validation handles for Verification Code fields
  const verificationCodeValidation = (event) => {
    setCode(event.target.value);
    setCodeError('')
  };


  return (
    <div className="home">
      <Navbar />
      <section class="banner">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col col-lg-4 col-md-12">
              <div class="signup-section">
                <h2>Enter Code</h2>
                <p class="message-info">
                  Please enter code sent to your mail
                </p>
                <p className="ErrorNew">{codeError}</p>
                <form>
                  <div class="textinput">
                    <input type="number" id="entercode" onChange={verificationCodeValidation} /><br />
                    <label for="entercode">Enter Code</label>
                  </div>
                  <button
                    class="buttonsignup"
                    onClick={(e) => {
                      e.preventDefault();
                      EntercodeSuccess();
                    }}
                  >
                    SUBMIT
                  </button>
                  <div class="already-text">
                    <p>
                      <span> Didn’t receive a code? </span>
                      <Link to="/entercode" onClick={resentMethod} >Resend code</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}