import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import LogoutEvn from '../../../common/LogoutEvn';
import { errorHandleService } from '../../../common';
import { Bar } from 'react-chartjs-2';
import { getUserDetails } from '../../../Api/api_calls/dashboard';

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    scale: {
        ticks: {
          precision: 0
        }
    },
    plugins: {
        legend: false 
    }
};


export default function Today() {
    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const [oneMonthData,setOneMonthData] = useState([])
    const [lableD,setLableD] =useState([])
    const [dataD,setDataD] =useState([])
    const [total,setTotalCount] = useState(0)

    useEffect(() => {
        getMonthWiseUsers();
    }, []);

    function getMonthWiseUsers() {
        dispatch(loaderStatus(true));
        getUserDetails().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if(res?.data?.last1dayInfo.length>0){
                    setOneMonthData(res.data.last1dayInfo)
                    let allData= res.data.last1dayInfo
                    let letLable=[];
                    let letData=[]
                    let totalNumber=0;
                    allData?.map(obj=>{
                        totalNumber=totalNumber+obj.count;
                        letLable.push(obj?.dateDMY)
                        letData.push(obj?.count)
                    })
                    setDataD(letData)
                    setLableD(letLable)
                    setTotalCount(totalNumber)
                }else{
                    setOneMonthData([])  
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    const data = {
        labels: lableD,
        datasets: [
            {
                data: dataD,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <>
            <div className='header'>
                <h1 className='mainHeading textCenter'>New users by last 24 hours</h1>
                <h1 className='mainHeading textCenter'>{total}</h1>
            </div>
            {dataD.length>0? <Bar data={data} options={options}/>: <></>}
        </>
    )
}
