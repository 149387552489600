import axios from "./axiosConfig";

export async function wrappedFetch(url, method, data, headers) {
  let config = {
    url,
    method,
    headers: {},
    data,
  };
  if (headers) config.headers = headers;
  try {
    return await axios(config);
  } catch (err) {
    console.log("Error Caught in urlFuncs.js", err);
    return err
  }
}
export async function wrappedGet(url, method,params) {
  let config = {
    url,
    method,
    headers: {},
    params
  };
  try {
    return await axios(config);
  } catch (err) {
    console.log("Error Caught in urlFuncs.js", err);
    return err
  }
}
