import { wrappedFetch } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const AddUserBalance = (uservenmo, userid, data) => {
    return wrappedFetch(Url.USER_BALANCE_ADD + uservenmo + "&uid=" + userid, "PATCH", data)
}

export const Getuserbyvenmoandusername = (uservenmo, username) => {
    let url = Url.GET_USERNAME_AND_VENMO
    if (uservenmo) {
        url = Url.GET_USERNAME_AND_VENMO + "venmo=" + uservenmo
    }
    if (username) {
        url = Url.GET_USERNAME_AND_VENMO + "userName=" + username
    }
    if (uservenmo && username) {
        url = Url.GET_USERNAME_AND_VENMO + "venmo=" + uservenmo + "&userName=" + username
    }
    return wrappedFetch(url, "GET")
}