import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";
import { Url } from "../api_setting/apiConstants";


export const AddContactus = (data) => {
    return wrappedFetch(Url.CONTACT_US, "POST", data);
};

export const GetContactus = () => {
    return wrappedGet(Url.GET_CONTACT_US, "GET");
};


