import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Customjavascript } from "../../../../custom";
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { useDispatch } from 'react-redux';
import { notifyService, errorHandleService } from '../../../common'
import { signUp } from '../../../Api/api_calls/authApis';
import { ROLE } from '../../../constants/constants';
import axios from 'axios';
import Navbar from '../../../common/headers/Navbar';
import { UserMessage } from '../../../constants/MessageConfig';
import { AddContactus } from '../../../Api/api_calls/contactusApi';
import{ Url } from "../../../Api/api_setting/apiConstants"

export default function Register(props) {

  // state manage for sign up fields
  const [email, setemail] = useState('')
  const [name, setname] = useState('')
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')
  const [venomhandle, setvenomhandle] = useState('')
  const [referalcode, setreferalcode] = useState('')
  const [number, setnumber] = useState('')

  // contact us query state manage
  const [contactemail, setcontactemail] = useState('')
  const [contactusername, setcontactusername] = useState('')
  const [contactquery, setcontactquery] = useState('')

  const resetall = () => {
    setcontactemail('')
    setcontactusername('')
    setcontactquery('')
  }

  const [Errormesseges, setErrormesseges] = useState('')
  const [contactErrormesseges, setcontactErrormesseges] = useState('')

  const dispatch = useDispatch();

  // function for hit register api
  const RegisterUser = () => {
    let registerdata = {};
    registerdata['email'] = email.trim();
    registerdata['name'] = name.trim();
    registerdata['userName'] = username.trim();
    registerdata['password'] = password
    registerdata['venmo'] = venomhandle.trim();
    registerdata['refferalCode'] = referalcode.trim();
    registerdata['phone'] = number
    registerdata['role'] = ROLE[0]

    let emailValue = email
    let namevalue = name
    let usernamevalue = username
    let passwordvalue = password
    let venmovalue = venomhandle
    let refferalcodevalue = referalcode
    let phonevalue = number

    // validation for email field
    if (!emailValue) {
      setErrormesseges(UserMessage.REGISTER_MAIL)
      return
    }

    // validations for name field
    if (!namevalue) {
      setErrormesseges(UserMessage.REGISTER_NAME)
      return
    }


    // let alphaNumWithSpecialChar = /^(?=\D*\d)(?=[^!-\/:-@[-`{-~]*[!-\/:-@[-`{-~])/
    // if (!usernamevalue.match(alphaNumWithSpecialChar)) {
    //   setErrormesseges(UserMessage.ALPHANUMARIC_SPECIAL_CHAR)
    //   return
    // }

    let alphaExp = /^[a-zA-Z\s]*$/
    if (!namevalue.match(alphaExp)) {
      setErrormesseges(UserMessage.REGISTER_ALPHABAT)
      return
    }

    // validation for username field
    if (!usernamevalue) {
      setErrormesseges(UserMessage.REGISTER_USERNAME)
      return
    }

    // validation for password input field
    if (!passwordvalue) {
      setErrormesseges(UserMessage.REGISTER_PASSWORD)
      return
    }

    let passwordformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
    if (!passwordvalue.match(passwordformat)) {
      setErrormesseges(UserMessage.REGISTER_PASSWORD_VALIDATION)
      return
    }
    // venmo handle validations
    if (!venmovalue) {
      setErrormesseges(UserMessage.REGISTER_VENMO)
      return
    }
    if (venmovalue.length < 5) {
      setErrormesseges(UserMessage.REGISTER_VENMO_VALIDATION)
      return
    }
    if (venmovalue.length > 16) {
      setErrormesseges(UserMessage.REGISTER_VENMO_DETAILS)
      return
    }

    if (!venmovalue.match(/^[a-zA-Z0-9_@-]+$/)) {
      setErrormesseges(UserMessage.REGISTER_VENMO_CHARACTRS)
      return
    }

    // referal code validations
    let referalformat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (refferalcodevalue) {
      if (refferalcodevalue.match(referalformat)) {
        setErrormesseges(UserMessage.REGISTER_CHARACTRS)
        return
      }
    }

    // phone number validations
    if (phonevalue) {
      if ((phonevalue.length < 10) || (phonevalue.length > 10)) {
        setErrormesseges(UserMessage.REGISTER_PHONE_ERROR)
        return
      }
    }

    dispatch(loaderStatus(true));
    signUp(registerdata).then(response => {
      dispatch(loaderStatus(false));
      if (response.data.result) {
        Sendotp(response?.data?.data)
        notifyService.success(UserMessage.REGISTER_SUCCESS)
        props.history.push({
          pathname: "/entercode",
          state: { data: response?.data?.data }
        });
      }
      else {
        notifyService.error(response.data.message)
      }
    })
      .catch(err => {
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      })
  }

  // api for send otp (api getting requested on the onsuccess of registration Api )
  const Sendotp = (data) => {
    let otpdata = {
      email: data?.email,
      _id: data?._id,
    }
    const headers = {
      'x-access-token': data?.token,
    };
    axios.post(Url?.OTP_SEND_API, otpdata, { headers })
      .then(response => console.log(response));
  }

  // handle for email input
  const emailvalidation = (event) => {
    setemail(event.target.value);
    setErrormesseges('')
  };
  // handle for name input
  const namevalidation = (event) => {
    setname(event.target.value);
    setErrormesseges('')
  };
  // handle for username input
  const usernamevalidation = (event) => {
    setusername(event.target.value);
    setErrormesseges('')
  };
  // handle for password input
  const passwordvalidation = (event) => {
    setpassword(event.target.value);
    setErrormesseges('')
  };
  // handle for venmo input
  const venmovalidation = (event) => {
    setvenomhandle(event.target.value);
    setErrormesseges('')
  };
  // handle for referal input
  const referalvalidation = (event) => {
    setreferalcode(event.target.value);
    setErrormesseges('')
  };
  // handle for phone input
  const phonevalidation = (event) => {
    setnumber(event.target.value);
    setErrormesseges('')
  };

  // for enable custom jquery
  useEffect(() => {
    Customjavascript();
  }, []);


  // contact us api method hit 
  const contactusmethod = () => {
    let contactusdata = {};
    contactusdata['userName'] = contactusername
    contactusdata['email'] = contactemail
    contactusdata['query'] = contactquery

    let Contactnamevalue = contactusername

    if (!Contactnamevalue) {
      setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_USERNAME)
      return
    }
    let alphaExp = /^[a-zA-Z\s]*$/
    if (!Contactnamevalue.match(alphaExp)) {
      setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_NAME_VALIDATION)
      return
    }
    if (!contactusdata.email) {
      setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_EMAIL)
      return
    }
    if (!contactusdata.query) {
      setcontactErrormesseges(UserMessage.REGISTER_CONTACTUS_QUERY)
      return
    }

    dispatch(loaderStatus(true));
    AddContactus(contactusdata).then(response => {
      dispatch(loaderStatus(false));
      if (response.data.result) {
        notifyService.success(UserMessage.QUERY_SUCCESS)
        document.querySelector('#contactUsGeg').click()
        resetall()
      }
      else {
        notifyService.error(response.data.message)
      }
    })
      .catch(err => {
        dispatch(loaderStatus(false));
        errorHandleService.onErrorHandle()
      })

  }

  const contactusernamevalidation = (event) => {
    setcontactusername(event.target.value);
    setcontactErrormesseges('')
  };
  const contactemailvalidation = (event) => {
    setcontactemail(event.target.value);
    setcontactErrormesseges('')
  };
  const contactqueryvalidation = (event) => {
    setcontactquery(event.target.value);
    setcontactErrormesseges('')
  };

  return (
    <div className="home">
      {/* Commom header for all pages */}
      <Navbar />
      <section className="banner">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-4 col-md-12">
              <div className="signup-section">
                <h2>SIGN UP</h2>
                <p className="ErrorNew">{Errormesseges}</p>
                <form>
                  <div className="textinput">
                    <input type="email" id="email" value={email} onChange={emailvalidation} required /><br />
                    <label for="email">Email*</label>
                  </div>
                  <div className="textinput">
                    <input type="text" id="name" value={name} onChange={namevalidation} /><br />
                    <label for="name">Name*</label>
                  </div>

                  <div className="textinput">
                    <input type="text" id="username" value={username} onChange={usernamevalidation} /><br />
                    <label for="username">User Id*</label>
                  </div>

                  <div className="textinput">
                    <input type="password" id="password" value={password} onChange={passwordvalidation} /><br />
                    <label for="password">Password*</label>
                  </div>

                  <div className="textinput">
                    <input type="text" id="VenmoHandle" value={venomhandle} onChange={venmovalidation} /><br />
                    <label for="VenmoHandle">Venmo Handle*</label>
                  </div>

                  <div className="textinput">
                    <input type="text" id="ReferralCode" value={referalcode} onChange={referalvalidation} /><br />
                    <label for="ReferralCode">Referral Code</label>
                  </div>

                  <div className="textinput">
                    <input type="number" id="PhoneNumber" value={number} onChange={phonevalidation} /><br />
                    <label for="PhoneNumber">Phone Number</label>
                  </div>

                  <button className="buttonsignup" onClick={(e) => { e.preventDefault(); RegisterUser() }}>SIGN UP</button>
                  <div className="already-text">
                    <p>Already have an account? <Link to="/login">LOG IN</Link></p>
                    <p style={{ paddingTop: 3 }} ><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#contactLogReg">Contact us</a></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="history-modal modal fade" id="contactLogReg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body">
              <h2>Contact Us</h2>
              <p className="ErrorNew">{contactErrormesseges}</p>
              <form>
                <div class="textinput">
                  <input type="text" id="adminmail" value="Inquiries@stampchampbingo.com"  className='filled cursorSet' disabled={true}/>
                  <label for="adminmail">Inquiry To</label>
                </div>
                <div class="textinput">
                  <input type="text" id="userNameReg" value={contactusername} onChange={contactusernamevalidation} />
                  <label for="userNameReg">Name</label>
                </div>
                <div class="textinput">
                  <input type="text" id="emailReg" value={contactemail} onChange={contactemailvalidation} />
                  <label for="emailReg">Email</label>
                </div>
                <div class="textinput textheight">
                  <textarea rows="3" id="queryReg" value={contactquery} onChange={contactqueryvalidation}></textarea>
                  <label for="queryReg">Query</label>
                </div>
                <button class="yes-submit" onClick={(e) => { e.preventDefault(); contactusmethod() }}>YES, SUBMIT</button>
                <button class="no-submit" type="button" data-bs-dismiss="modal" id="contactUsGeg">NO, GO BACK</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

