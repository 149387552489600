import React, { useState, useEffect } from 'react'
import home from '../../../../Assets/images/home.png'
import ball from '../../../../Assets/images/ball.png'
import card from '../../../../Assets/images/Card.png'
import cardinactive from '../../../../Assets/images/Card-inactive.png'
import wallet from '../../../../Assets/images/white-walet.png'
import Navbar from '../../../common/headers/Navbar'
import '../../../../index.css'
import { ChooseroomApi } from '../../../Api/api_calls/chooseroomApi'
import { useDispatch, useSelector } from 'react-redux';
import { loaderStatus } from '../../../redux/Actions/LoaderAction'
import { errorHandleService } from '../../../common'
import LogoutEvn from '../../../common/LogoutEvn'
import dateFormat from 'dateformat';
import { getDashbaordDetailsUserMethod } from '../../../Api/api_calls/dashboard'
import stampchamp from '../../../../Assets/images/stampchamp.png'
import { getUserProfile } from '../../../Api/api_calls/profileApi'
import { balanceAction } from '../../../redux/Actions/BalanceAction'
import InfoIcon from '@mui/icons-material/Info';
import { getRoomAndPropsOfUserMethod } from '../../../Api/api_calls/cardApis'

export default function Account(props) {

    const dispatch = useDispatch();
    const logoutEvents = LogoutEvn()
    const userId = useSelector((state) => state?.Auth?._id);
    const auth = useSelector((state) => state.Auth);
    // state manage for show room data
    const [roomdata, setroomdata] = useState([])
    const [userLastCard, setLastCardDetial] = useState({})
    const [, setLastCardGame] = useState([])
    const [playerInfo, setPlayersInfo] = useState([])
    const userbalance = useSelector((state) => state?.userBalance?.venmoBalance);

    const [buttonStatus, setButtonStatus] = useState(null)

    // Card history code start -
    // row 1
    const [r1c1, set_r1c1] = useState({})
    const [r1c2, set_r1c2] = useState({})
    const [r1c3, set_r1c3] = useState({})
    const [r1c4, set_r1c4] = useState({})
    const [r1c5, set_r1c5] = useState({})
    // row 2
    const [r2c1, set_r2c1] = useState({})
    const [r2c2, set_r2c2] = useState({})
    const [r2c3, set_r2c3] = useState({})
    const [r2c4, set_r2c4] = useState({})
    const [r2c5, set_r2c5] = useState({})
    // row 3
    const [r3c1, set_r3c1] = useState({})
    const [r3c2, set_r3c2] = useState({})
    const [r3c3, set_r3c3] = useState({})
    const [r3c4, set_r3c4] = useState({})
    const [r3c5, set_r3c5] = useState({})
    // row 4
    const [r4c1, set_r4c1] = useState({})
    const [r4c2, set_r4c2] = useState({})
    const [r4c3, set_r4c3] = useState({})
    const [r4c4, set_r4c4] = useState({})
    const [r4c5, set_r4c5] = useState({})
    // row 5
    const [r5c1, set_r5c1] = useState({})
    const [r5c2, set_r5c2] = useState({})
    const [r5c3, set_r5c3] = useState({})
    const [r5c4, set_r5c4] = useState({})
    const [r5c5, set_r5c5] = useState({})
    // Card history code close -

    // handle for go to profile page
    const Profilehandle = () => {
        props.history.push("/user_profile")
    }

    // handle for go to chooseroom page
    const roomhandle = () => {
        props.history.push("/chooseroom")
    }

    // need to use useeffect beacause at the time of page loading we getting room data from api
    useEffect(() => {
        Getroom()
    }, [])

    // getting room and props details by id
    useEffect(() => {
        getDashbaordDetailsUser(userId)
        // eslint-disable-next-line
    }, [])

    //Get user dashboard details --
    function getDashbaordDetailsUser(userId) {
        dispatch(loaderStatus(true));
        getDashbaordDetailsUserMethod(userId).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if (res?.data?.data.length > 0) {
                    setLastCardDetial(res?.data?.data[0])
                    setLastCardGame(res?.data?.data[0]?.highest?.cardDetails)
                    setPlayerInCard(res?.data?.data[0]?.highest?.cardDetails)
                    setPlayersInfo(res?.data?.data[0]?.highest?.PlayersInfo)
                    getUserDetails();
                } else {
                    setLastCardDetial({})
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    // get user details -
    function getUserDetails() {
        let userIdSend = userId
        dispatch(loaderStatus(true));
        getUserProfile(userIdSend).then(res => {
            dispatch(loaderStatus(false));
            let venmoBalance = res.data?.data[0]?.venmoBalance;
            let reduxObject = {}
            if (venmoBalance) {
                reduxObject['venmoBalance'] = venmoBalance
            } else {
                reduxObject['venmoBalance'] = 0
            }
            dispatch(balanceAction(reduxObject))
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }


    // api for get room detais
    const Getroom = () => {
        var today = new Date((new Date()).toDateString())
        dispatch(loaderStatus(true));
        ChooseroomApi(today).then(res => {
            dispatch(loaderStatus(false));
            if (res?.data?.result) {
                setroomdata(res?.data?.data)
                enterRoom(res?.data?.data)
            }

        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()

        })
    }

    const payoutCount = (entryFee, maxPlayer) => {
        if (entryFee && maxPlayer) {
            return (entryFee * maxPlayer) * 0.9
        } else {
            return 0
        }
    }


    // handle for go to userhistory page
    const gotohistory = () => {
        props.history.push("/userhistory")
    }

    const setPlayerInCard = (dataGet) => {
        set_r1c1(dataGet[0])
        set_r1c2(dataGet[1])
        set_r1c3(dataGet[2])
        set_r1c4(dataGet[3])
        set_r1c5(dataGet[4])
        // row 2
        set_r2c1(dataGet[5])
        set_r2c2(dataGet[6])
        set_r2c3(dataGet[7])
        set_r2c4(dataGet[8])
        set_r2c5(dataGet[9])
        // row 3
        set_r3c1(dataGet[10])
        set_r3c2(dataGet[11])
        set_r3c4(dataGet[12])
        set_r3c5(dataGet[13])
        // row 4
        set_r4c1(dataGet[14])
        set_r4c2(dataGet[15])
        set_r4c3(dataGet[16])
        set_r4c4(dataGet[17])
        set_r4c5(dataGet[18])
        // row 5
        set_r5c1(dataGet[19])
        set_r5c2(dataGet[20])
        set_r5c3(dataGet[21])
        set_r5c4(dataGet[22])
        set_r5c5(dataGet[23])
    }

    // Taking name  -
    const getInfo = (playerId) => {
        let Info = playerInfo.filter(obj => obj?._id === playerId)
        if (Info.length > 0) {
            return Info[0]
        } else {
            return {}
        }
    }

    // Taking Type  -
    const getType = (playerId) => {
        let Info = playerInfo.filter(obj => obj?._id === playerId)
        if (Info.length > 0) {
            return Info[0]?.period
        } else {
            return ''
        }
    }

    const rejoinCode = (info) => {
        let userIdGet = auth?._id
        let roomIdGet = info._id
        props.history.push("/cards_play/" + roomIdGet + "/" + userIdGet)
    }

    // enter to room -
    const enterRoom = (data) => {
        let userIdGet = auth?._id
        let roomIdGet = data[0]?._id
        dispatch(loaderStatus(true));
        getRoomAndPropsOfUserMethod(userIdGet, roomIdGet).then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                if (res?.data?.code == 401) {
                    setButtonStatus(<button className="vewbtn" onClick={roomhandle}>GO TO ROOM</button>)
                } else if (res?.data?.data?.length > 0) {
                    setButtonStatus(<button className="vewbtn" onClick={() => rejoinCode(data[0])}>REJOIN</button>)
                } else {
                    setButtonStatus(<button className="vewbtn" onClick={roomhandle}>GO TO ROOM</button>)
                }
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }



    const checkingTooltipText = (propsName) => {
        if (propsName?.length > 12) {
            return <span class="tooltiptext">{propsName}</span>
        } else {
            return ''
        }
    }

    const checkingTooltipTexttype = (propstype) => {
        if (propstype.length > 12) {
            return <span class="tooltiptext">{propstype}</span>
        } else {
            return ''
        }
    }


    const getInfotype = (playerId) => {
        let Info = playerInfo.filter(obj => obj?._id === playerId)
        if (Info.length > 0) {
            if (Info[0]?.type.length > 12) {
                return <><span class="tooltiptext">{Info[0]?.type}</span>{Info[0]?.type.substring(0, 12) + '...'}</>
            } else {
                return Info[0]?.type
            }
        } else {
            return ''
        }
    }

    return (
        <div className="accounts">
            <Navbar />
            <div className="accounts-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <a href="#" className="accounts-heading">
                                <img className="img-fluid" src={home} /> Dashboard
                            </a>
                        </div>
                        <div className="col">
                            <ul className="accounts-ul">
                                <li onClick={Profilehandle} className="deposite">VIEW PROFILE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="banner create-card">
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col col-lg-12 col-md-12">
                            <div className="row">
                                <div className="col col-lg-4 col-md-12">
                                    <div className="home-divition">
                                        <h2>Last Card</h2>
                                        {
                                            userLastCard?.highest ?
                                                <div className="choose-room-card">
                                                    <div className="inner-card">
                                                        <div className="inner-logo">
                                                            <a href="#" className="choose-room-logo">
                                                                <img className="img-fluid" src={ball} /> {userLastCard?.highest?.RoomsInfo[0]?.matchType}
                                                            </a>
                                                        </div>
                                                        <div className="inner-vs">
                                                            {
                                                                userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0].length > 8 || userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1].length > 8 ?
                                                                    <div class="vs-section bigger-text">
                                                                        <div className='first-logo'><h3>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0]}</h3></div>
                                                                        <div className="vs-text">VS</div>
                                                                        <div className='second-logo'><h3>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1]}</h3>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div class="vs-section">
                                                                        <div className='first-logo'><h3>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0]}</h3></div>
                                                                        <div className="vs-text">VS</div>
                                                                        <div className='second-logo'><h3>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1]}</h3></div>
                                                                    </div>
                                                            }
                                                            <div className="vs-content">
                                                                <p><span> {dateFormat(userLastCard?.highest?.RoomsInfo[0]?.startDateTime, "mmmm dS, yyyy")}</span>{dateFormat(userLastCard?.highest?.RoomsInfo[0]?.startDateTime, "h:MM TT")}</p>
                                                            </div>
                                                        </div>

                                                        <div className="bottom-card">
                                                            <table className="bottom-table">
                                                                <tr>
                                                                    <td>Place<span>{userLastCard?.highest?.winningPotion > 0 ? userLastCard?.highest?.winningPotion : 'N/A'}</span></td>
                                                                    <td>Points<span>{userLastCard?.highest?.points}</span></td>
                                                                    <td>Bingos<span>{userLastCard?.highest?.bingoCount}</span></td>
                                                                    <td>Won<span>${userLastCard?.highest?.winningAmt}</span></td>
                                                                </tr>
                                                            </table>
                                                            <div className="inner-checs bingoaccounts">
                                                                <ul>
                                                                    <li>
                                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r1c1).length != 0 ? <>
                                                                                {r1c1?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null

                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r1c2).length != 0 ? <>
                                                                                {r1c2?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null

                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r1c3).length != 0 ? <>
                                                                                {r1c3?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null

                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r1c4).length != 0 ? <>
                                                                                {r1c4?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r1c5).length != 0 ? <>
                                                                                {r1c5?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null

                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r2c1).length != 0 ? <>
                                                                                {r2c1?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r2c2).length != 0 ? <>
                                                                                {r2c2?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r2c3).length != 0 ? <>
                                                                                {r2c3?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r2c4).length != 0 ? <>
                                                                                {r2c4?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r2c5).length != 0 ? <>
                                                                                {r2c5?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r3c1).length != 0 ? <>
                                                                                {r3c1?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r3c2).length != 0 ? <>
                                                                                {r3c2?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        
                                                                        <img class="img-fluid" src={card} />
                                                                    </li>
                                                                    <li >
                                                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r3c4).length != 0 ? <>
                                                                                {r3c4?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r3c5).length != 0 ? <>
                                                                                {r3c5?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r4c1).length != 0 ? <>
                                                                                {r4c1?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r4c2).length != 0 ? <>
                                                                                {r4c2?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r4c3).length != 0 ? <>
                                                                                {r4c3?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r4c4).length != 0 ? <>
                                                                                {r4c4?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                                                        {
                                                                            Object.keys(r4c5).length != 0 ? <>
                                                                                {r4c5?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {
                                                                            Object.keys(r5c1).length != 0 ? <>
                                                                                {r5c1?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {
                                                                            Object.keys(r5c2).length != 0 ? <>
                                                                                {r5c2?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {
                                                                            Object.keys(r5c3).length != 0 ? <>
                                                                                {r5c3?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                                                        {
                                                                            Object.keys(r5c4).length != 0 ? <>
                                                                                {r5c4?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                    <li >
                                                                        {
                                                                            Object.keys(r5c5).length != 0 ? <>
                                                                                {r5c5?.isMatch === true ? <img className="img-fluid" src={card} /> : <img className="img-fluid" src={cardinactive} />}</> : null
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <button class="vewbtn" data-bs-toggle="modal" data-bs-target="#exampleModal">VIEW</button>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="choose-room-card">
                                                    <div className="inner-card">
                                                        <div className="inner-logo">
                                                            <a href="#" className="choose-room-logo">
                                                                <img className="img-fluid" src={ball} /> {userLastCard?.highest?.RoomsInfo[0]?.matchType}
                                                            </a>
                                                        </div>
                                                        <div className="inner-vs">
                                                            <div className="vs-section">
                                                                <div className="first-logo">
                                                                </div>
                                                                <div className="vs-text">
                                                                    Sorry
                                                                </div>
                                                                <div className="second-logo">
                                                                </div>
                                                            </div>
                                                            <div className="vs-content">
                                                                <p>No History Available</p>
                                                            </div>
                                                        </div>

                                                        <div className="bottom-card">
                                                            <table className="bottom-table">
                                                                <tr>
                                                                    <td>Place<span>{'---'}</span></td>
                                                                    <td>Points<span>{'---'}</span></td>
                                                                    <td>Bingos<span>{'---'}</span></td>
                                                                    <td>Won<span>{'---'}</span></td>
                                                                </tr>
                                                            </table>
                                                            <div className="inner-checs">
                                                                <ul>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img class="img-fluid" src={card} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li>
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                    <li >
                                                                        <img className="img-fluid" src={cardinactive} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <button class="vewbtn vewbtnNotAllow">VIEW</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            userLastCard?.highest ? <button className="vewbtn card-history" onClick={gotohistory}>CARD HISTORY</button> :
                                                <button className="vewbtn card-history vewbtnNotAllow mt-4" >CARD HISTORY</button>
                                        }

                                    </div>
                                </div>
                                <div className="col col-lg-4 col-md-12">
                                    <div className="home-divition">
                                        <h2>Your Stats </h2>
                                        <div className="gamepoins-section">
                                            <ul className="poins-sec">
                                                <li>
                                                    <p>Total <br /> Bingos <span>{userLastCard?.totalBingos ? userLastCard?.totalBingos : 0}</span></p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gamepoins-section">
                                            <ul className="poins-sec">
                                                <li>
                                                    <p>Games <br />Played <span>{userLastCard?.count ? userLastCard?.count : 0}</span></p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gamepoins-section">
                                            <ul className="poins-sec">
                                                <li>
                                                <p>Total <br />Winnings <span>${userLastCard?.totalWinnings ? userLastCard?.totalWinnings.toFixed(2) : 0}</span></p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="walet-sectiion">
                                            <div className="inner-walet">
                                                <div className="waletimg">
                                                    <img className="img-fluid" src={wallet} />
                                                </div>
                                                {/* <h3>${userLastCard?.highest?.UsersInfo[0]?.venmoBalance ? (userLastCard?.highest?.UsersInfo[0]?.venmoBalance.toFixed(2)) : 0}<span>wallet Money</span></h3> */}
                                                <h3>${(userbalance.toFixed(2))}<span>wallet Money</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-lg-4 col-md-12">
                                    <div className="home-divition">
                                        <h2>Rooms</h2>
                                        <div className="choose-room-card">
                                            <div className="inner-card">
                                                {roomdata?.length > 0 ? roomdata?.map((row) => (
                                                    <>

                                                        <div className="inner-logo">
                                                            <a href="#" className="choose-room-logo">
                                                                <img className="img-fluid" src={ball} />&nbsp;{row?.matchType}
                                                            </a>
                                                        </div>
                                                        <div className="inner-vs">
                                                            {
                                                                row?.title?.split("v-s")[0].length > 8 || row?.title?.split("v-s")[1].length > 8 ?
                                                                    <div class="vs-section bigger-text">
                                                                        <div className='first-logo'><h3>{row?.title?.split("v-s")[0]}</h3></div>
                                                                        <div className="vs-text">VS</div>
                                                                        <div className='second-logo'><h3>{row?.title?.split("v-s")[1]}</h3></div>
                                                                    </div>
                                                                    :
                                                                    <div class="vs-section">
                                                                        <span className='first-logo'><h3>{row?.title?.split("v-s")[0]}</h3></span>
                                                                        <div className="vs-text">VS</div>
                                                                        <div className='second-logo'><h3>{row?.title?.split("v-s")[1]}</h3></div>
                                                                    </div>
                                                            }
                                                    
                                                            <div className="vs-content">
                                                                <p><span>{dateFormat(row?.startDateTime, "mmmm dS, yyyy")}</span>{dateFormat(row.startDateTime, "h:MM TT")}</p>
                                                            </div>
                                                        </div>
                                                        <div className="bottom-card">
                                                            <table className="bottom-table">
                                                                <tr>
                                                                    <td>Entry<span>${row?.entryFee}</span></td>
                                                                    <td>Max Payout<p title="Max payout vary according to total players in room" className='ptagDispayInline'><InfoIcon className='infoicon' /></p><span>${payoutCount(row?.entryFee, row?.maxPlayer).toFixed(2)}</span></td>
                                                                    <td>Max Players<span>{row?.uid?.length + "/" + row?.maxPlayer}</span></td>
                                                                </tr>
                                                            </table>
                                                            {row?.isOver ? <button className="vewbtn vewbtnNotAllow">GAME OVER</button> : buttonStatus}
                                                        </div>
                                                    </>
                                                )) : <div class="inner-card">
                                                    <div class="inner-logo">
                                                        <a href="#" class="choose-room-logo">
                                                            <img className="img-fluid" src={ball} /> 
                                                        </a>
                                                    </div>
                                                    <div class="inner-vs">
                                                        <div class="vs-section">
                                                            <div class="first-logo">
                                                            </div>
                                                            <div className="vs-text">
                                                                Sorry
                                                            </div>
                                                            <div class="second-logo">
                                                            </div>
                                                        </div>
                                                        <div class="vs-content">
                                                            <p>No Rooms Available</p>
                                                        </div>
                                                    </div>
                                                    <div class="bottom-card">
                                                        <table class="bottom-table">
                                                            <tr>
                                                                <td>Entry<span>---</span></td>
                                                                <td>Payout<span>---</span></td>
                                                                <td>Players<span>---</span></td>
                                                            </tr>
                                                        </table>
                                                        <button class="vewbtn vewbtnNotAllow">Go To Room</button>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="margin"></div>
                </div>
            </section>
            {/* <!-- Modal --> */}
            <div class="history-modal modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <span class="first-heading">
                                    {dateFormat(userLastCard?.highest?.RoomsInfo[0]?.startDateTime, "mmmm dS, yyyy")}&nbsp;
                                    {dateFormat(userLastCard?.highest?.RoomsInfo[0]?.startDateTime, "h:MM TT")}
                                </span>

                                {
                                    userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0].length > 8 || userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1].length > 8 ?
                                        <span class="second-heading bigger-text">
                                            <span className='lfttitle'>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0]}</span>&nbsp;
                                            <span className="">VS</span>&nbsp;
                                            <span className='rgttitle'> {userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1]}
                                            </span>
                                        </span>
                                        :
                                        <span class="second-heading">
                                            <span className='lfttitle'>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[0]}</span>&nbsp;
                                            <span className="">VS</span>&nbsp;
                                            <span className='rgttitle'>{userLastCard?.highest?.RoomsInfo[0]?.title?.split("v-s")[1]} </span>
                                        </span>
                                }
                                <span class="third-heading">PAYOUT: ${userLastCard?.highest?.winningAmt}</span>
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="inner-checs">
                                <ul>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c1).length != 0 ?
                                                <div className={r1c1?.isMatch === true ? "modal-box active-join " : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r1c1?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r1c1?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r1c1?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r1c1?.playerId)?.name?.length > 12 ? getInfo(r1c1?.playerId)?.name.substring(0, 12) + '...' : getInfo(r1c1?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r1c1?.playerId)?.name)
                                                            }
                                                        </div>


                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c2).length != 0 ?
                                                <div className={r1c2.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r1c2?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r1c2?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r1c2?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r1c2?.playerId)?.name?.length > 12 ? getInfo(r1c2?.playerId)?.name.substring(0, 12) + '...' : getInfo(r1c2?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r1c2?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c3).length != 0 ?
                                                <div className={r1c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r1c3?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r1c3?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r1c3?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r1c3?.playerId)?.name?.length > 12 ? getInfo(r1c3?.playerId)?.name.substring(0, 12) + '...' : getInfo(r1c3?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r1c3?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r1c2.isMatch === true && r1c3?.isMatch === true && r1c4?.isMatch === true && r1c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c4).length != 0 ?
                                                <div className={r1c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r1c4?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r1c4?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r1c4?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r1c4?.playerId)?.name?.length > 12 ? getInfo(r1c4?.playerId)?.name.substring(0, 12) + '...' : getInfo(r1c4?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r1c4?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r1c5).length != 0 ?
                                                <div className={r1c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r1c5?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r1c5?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r1c5?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r1c5?.playerId)?.name?.length > 12 ? getInfo(r1c5?.playerId)?.name.substring(0, 12) + '...' : getInfo(r1c5?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r1c5?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c1).length != 0 ?
                                                <div className={r2c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r2c1?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r2c1?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r2c1?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r2c1?.playerId)?.name?.length > 12 ? getInfo(r2c1?.playerId)?.name.substring(0, 12) + '...' : getInfo(r2c1?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r2c1?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest' >
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c2).length != 0 ?
                                                <div className={r2c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r2c2?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r2c2?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r2c2?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r2c2?.playerId)?.name?.length > 12 ? getInfo(r2c2?.playerId)?.name.substring(0, 12) + '...' : getInfo(r2c2?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r2c2?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c3).length != 0 ?
                                                <div className={r2c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r2c3?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r2c3?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r2c3?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r2c3?.playerId)?.name?.length > 12 ? getInfo(r2c3?.playerId)?.name.substring(0, 12) + '...' : getInfo(r2c3?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r2c3?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r2c1?.isMatch === true && r2c2.isMatch === true && r2c3?.isMatch === true && r2c4?.isMatch === true && r2c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c4).length != 0 ?
                                                <div className={r2c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r2c4?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r2c4?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r2c4?.playerId)?.points} <span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r2c4?.playerId)?.name?.length > 12 ? getInfo(r2c4?.playerId)?.name.substring(0, 12) + '...' : getInfo(r2c4?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r2c4?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r2c5).length != 0 ?
                                                <div className={r2c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r2c5?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r2c5?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r2c5?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r2c5?.playerId)?.name?.length > 12 ? getInfo(r2c5?.playerId)?.name.substring(0, 12) + '...' : getInfo(r2c5?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r2c5?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c1).length != 0 ?
                                                <div className={r3c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r3c1?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r3c1?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r3c1?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r3c1?.playerId)?.name?.length > 12 ? getInfo(r3c1?.playerId)?.name.substring(0, 12) + '...' : getInfo(r3c1?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r3c1?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c2).length != 0 ?
                                                <div className={r3c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r3c2?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r3c2?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r3c2?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r3c2?.playerId)?.name?.length > 12 ? getInfo(r3c2?.playerId)?.name.substring(0, 12) + '...' : getInfo(r3c2?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r3c2?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        <div className="modal-box active-join center-bingo" style={{ lineHeight: 'inherit' }}>
                                            {/* center */}
                                            <div className="textdiv">
                                                <div className="middle-img">
                                                    <h3>1 PTS</h3>
                                                    <img className="img-fluid" src={stampchamp} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='cardTest'>
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-left"></span> : ''}
                                        {r3c1?.isMatch === true && r3c2.isMatch === true && r3c4?.isMatch === true && r3c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c4).length != 0 ?
                                                <div className={r3c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r3c4?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r3c4?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r3c4?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r3c4?.playerId)?.name?.length > 12 ? getInfo(r3c4?.playerId)?.name.substring(0, 12) + '...' : getInfo(r3c4?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r3c4?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r3c5).length != 0 ?
                                                <div className={r3c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r3c5?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r3c5?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r3c5?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r3c5?.playerId)?.name?.length > 12 ? getInfo(r3c5?.playerId)?.name.substring(0, 12) + '...' : getInfo(r3c5?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r3c5?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c1?.isMatch === true && r2c1.isMatch === true && r3c1?.isMatch === true && r4c1?.isMatch === true && r5c1?.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c1).length != 0 ?
                                                <div className={r4c1?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r4c1?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r4c1?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r4c1?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r4c1?.playerId)?.name?.length > 12 ? getInfo(r4c1?.playerId)?.name.substring(0, 12) + '...' : getInfo(r4c1?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r4c1?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-left-angle"></span> : ''}
                                        {r1c5?.isMatch === true && r2c4.isMatch === true && r4c2?.isMatch === true && r5c1.isMatch === true ? <span class="green-line-righttp-angle"></span> : ''}
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c2?.isMatch === true && r2c2.isMatch === true && r3c2?.isMatch === true && r4c2?.isMatch === true && r5c2.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c2).length != 0 ?
                                                <div className={r4c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r4c2?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r4c2?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r4c2?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r4c2?.playerId)?.name?.length > 12 ? getInfo(r4c2?.playerId)?.name.substring(0, 12) + '...' : getInfo(r4c2?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r4c2?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {r1c3?.isMatch === true && r2c3.isMatch === true && r4c3?.isMatch === true && r5c3.isMatch === true ? <span class="green-line-top"></span> : ''}
                                        {
                                            Object.keys(r4c3).length != 0 ?
                                                <div className={r4c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r4c3?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r4c3?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r4c3?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r4c3?.playerId)?.name?.length > 12 ? getInfo(r4c3?.playerId)?.name.substring(0, 12) + '...' : getInfo(r4c3?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r4c3?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right-angle"></span> : ''}
                                        {r1c1?.isMatch === true && r2c2.isMatch === true && r4c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-lefttp-angle"></span> : ''}
                                        {r4c1?.isMatch === true && r4c2.isMatch === true && r4c3?.isMatch === true && r4c4?.isMatch === true && r4c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {r1c4?.isMatch === true && r2c4.isMatch === true && r3c4?.isMatch === true && r4c4?.isMatch === true && r5c4.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c4).length != 0 ?
                                                <div className={r4c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r4c4?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r4c4?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r4c4?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r4c4?.playerId)?.name?.length > 12 ? getInfo(r4c4?.playerId)?.name.substring(0, 12) + '...' : getInfo(r4c4?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r4c4?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r1c5?.isMatch === true && r2c5.isMatch === true && r3c5?.isMatch === true && r4c5?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-bottom"></span> : ''}
                                        {
                                            Object.keys(r4c5).length != 0 ?
                                                <div className={r4c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r4c5?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r4c5?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r4c5?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r4c5?.playerId)?.name?.length > 12 ? getInfo(r4c5?.playerId)?.name.substring(0, 12) + '...' : getInfo(r4c5?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r4c5?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c1).length != 0 ?
                                                <div className={r5c1.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r5c1?.playerId)
                                                            }
                                                        </div>
                                                    </h5>

                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r5c1?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r5c1?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r5c1?.playerId)?.name?.length > 12 ? getInfo(r5c1?.playerId)?.name.substring(0, 12) + '...' : getInfo(r5c1?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r5c1?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c2).length != 0 ?
                                                <div className={r5c2?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r5c2?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r5c2?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r5c2?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r5c2?.playerId)?.name?.length > 12 ? getInfo(r5c2?.playerId)?.name.substring(0, 12) + '...' : getInfo(r5c2?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r5c2?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c3).length != 0 ?
                                                <div className={r5c3?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r5c3?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r5c3?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r5c3?.playerId)?.points} <span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r5c3?.playerId)?.name?.length > 12 ? getInfo(r5c3?.playerId)?.name.substring(0, 12) + '...' : getInfo(r5c3?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r5c3?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {r5c1?.isMatch === true && r5c2.isMatch === true && r5c3?.isMatch === true && r5c4?.isMatch === true && r5c5.isMatch === true ? <span class="green-line-right"></span> : ''}
                                        {
                                            Object.keys(r5c4).length != 0 ?
                                                <div className={r5c4?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r5c4?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r5c4?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">

                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r5c4?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r5c4?.playerId)?.name?.length > 12 ? getInfo(r5c4?.playerId)?.name.substring(0, 12) + '...' : getInfo(r5c4?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r5c4?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                    <li className='cardTest'>
                                        {
                                            Object.keys(r5c5).length != 0 ?
                                                <div className={r5c5?.isMatch === true ? "modal-box active-join" : "modal-box"}>
                                                    <h5>
                                                        <div class="tooltipp">
                                                            {
                                                                getInfotype(r5c5?.playerId)
                                                            }
                                                        </div>
                                                    </h5>
                                                    <div className="textdiv">
                                                        <div className="irst-text">
                                                            <h6>{getInfo(r5c5?.playerId)?.period}</h6>
                                                        </div>
                                                        <div className="middle-img">
                                                        </div>
                                                        <div className="last-text">
                                                            {getInfo(r5c5?.playerId)?.points}<span>PTS</span>
                                                        </div>
                                                    </div>
                                                    <div className="bootom-text redbox">
                                                        <div class="tooltipp">
                                                            {getInfo(r5c5?.playerId)?.name?.length > 12 ? getInfo(r5c5?.playerId)?.name.substring(0, 12) + '...' : getInfo(r5c5?.playerId)?.name}
                                                            {
                                                                checkingTooltipText(getInfo(r5c5?.playerId)?.name)
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
