import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import BarChart from './BarChart';
import UserTable from './UserTable';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch, useSelector } from "react-redux";
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { errorHandleService } from '../../../common';
import { generaterefferal } from '../../../Api/api_calls/generatereferalApi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Getalluserandreferrals } from "../../../Api/api_calls/dashboard";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LogoutEvn from '../../../common/LogoutEvn';
import { REFEREL_STRING } from "../../../Api/config";
import FreeOffer from "./freeOffer";
import TableRoomDetails from './Table';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));


export default function Admindashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state?.Auth?._id);
    const role = useSelector((state) => state?.Auth?.role);
    const [open, setOpen] = React.useState(false);
    const [referralcodedata, setreferralcodedata] = useState('')
    const [dashboarddetails, setdashboarddetails] = useState({})
    const logoutEvents = LogoutEvn()
    const characters = REFEREL_STRING;

    const handleClose = () => {
        setOpen(false);
    };

    function generateString(length) {
        let result = '';
        const charactersLength = REFEREL_STRING.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // Generate refferal code api method
    const Generaterefferalcode = () => {
        let referraldata = {};
        referraldata['sentBy'] = userId
        referraldata['referalCode'] = (generateString(4)).trim()
        referraldata['role'] = role
        dispatch(loaderStatus(true));
        generaterefferal(referraldata).then(res => {
            if (res?.data?.result) {
                setreferralcodedata(res?.data?.data?.referalCode)
                setOpen(true);
                dispatch(loaderStatus(false));
            }
            else {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }

    function Getdashboarddetails() {
        dispatch(loaderStatus(true));
        Getalluserandreferrals().then(res => {
            dispatch(loaderStatus(false));
            if (res?.status == 200) {
                setdashboarddetails(res?.data?.data)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
            logoutEvents()
        })
    }
    useEffect(() => {
        Getdashboarddetails();
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8} className={classes.mainSection}>
                    <Item className={classes.mainSection}>
                        <UserTable dashInfo={dashboarddetails} />
                    </Item>
                    <Box pt={2} />
                    <Item className={classes.mainSection}>
                        <TableRoomDetails/>
                    </Item>
                    <Box pt={1} />
                    <Item className={classes.chartHight}>
                        <span className="mainHeading">Free Offer</span>
                        <FreeOffer />
                    </Item>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Grid container>
                        <Grid xs={12} className='textCenter'>
                            <Button variant="contained" className='buttonTheme' endIcon={<ShareIcon />} onClick={Generaterefferalcode}>
                                Referral Code
                            </Button>
                        </Grid>
                    </Grid>
                    <Box pt={2} />
                    <Item>
                        <Grid container>
                            <Grid xs={10}>
                                <div className='mainHeading'>Total Users</div>
                            </Grid>
                            <Grid xs={2}>
                                <div className='mainHeading'>{dashboarddetails?.userCount}</div>
                            </Grid>
                        </Grid>
                    </Item>
                    <Box pt={2} />
                    <Item>
                        <Grid container>
                            <Grid xs={10}>
                                <div className='mainHeading'>Admin Referrals</div>
                            </Grid>
                            <Grid xs={2}>
                                <div className='mainHeading'>{dashboarddetails?.adminRef}</div>
                            </Grid>
                        </Grid>
                    </Item>
                    <Box pt={2} />
                    <Item>
                        <Grid container>
                            <Grid xs={10}>
                                <div className='mainHeading'>User Referrals</div>
                            </Grid>
                            <Grid xs={2}>
                                <div className='mainHeading'>{dashboarddetails?.userRef}</div>
                            </Grid>
                        </Grid>
                    </Item>
                    <Box pt={2} />
                    <Item className={classes.chartHight}>
                        <BarChart />
                    </Item>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className='mainHeading'>
                    {"Your referral code is"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box pt={2}>
                            <TextField id="outlined-basic" label="Referral" disabled={true} value={referralcodedata} size="small" />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="modalCommonButton" size="small">Cancel</Button>
                    <CopyToClipboard text={referralcodedata}
                        onCopy={() => setreferralcodedata(referralcodedata)}>
                        <Button onClick={handleClose} autoFocus className="modalCommonButton" size="small">
                            Copy
                        </Button>
                    </CopyToClipboard>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
