import React from 'react'
import { Route } from 'react-router-dom'
import { GrantPermission } from './grantPermission';
import Error404Page from '../module/Errors/error404page';

function RoleBasedRouting({
  component: Component, roles, ...rest
}) {
  return (
    <>
      {GrantPermission(roles) && (
        <Route
          {...rest}
          render={(props) => (
            <Component {...props} />
          )}
        />
      )}
      {
        // !GrantPermission(roles) && (
        //   <Route
        //     render={() => (
        //       <>
        //         <Error404Page />
        //       </>
        //     )}
        //   />
        // )
      }
    </>
  );
}

export default RoleBasedRouting

