import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { AddTermAndCondition, GetTermAndCondition } from '../../../Api/api_calls/termandconditionApi';
import { loaderStatus } from '../../../redux/Actions/LoaderAction';
import { errorHandleService, notifyService } from '../../../common';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    inputLabel: {
        fontWeight: 450
    },
    errorColor: {
        color: 'red'
    },
}));

export default function Termsndconditions() {
    const classes = useStyles();
    const [addData, setval] = useState('')
    const dispatch = useDispatch();
    const [checkpdate, checkUpdateSet] = useState(false)

    const handleEditor = (e, editor) => {
        const data = editor.getData();
        checkUpdateSet(true)
        setval(data)
    }

    const submitForm = () => {
        let AddpolicySet = addData && addData.trim()
        if (!AddpolicySet) {
            notifyService.info('Please add terms and conditions')
            return
        }
        let finalObject = {};
        finalObject['termsAndCondition'] = AddpolicySet
        dispatch(loaderStatus(true));
        AddTermAndCondition(finalObject)
            .then((response) => {
                dispatch(loaderStatus(false));
                notifyService.info('Terms and conditions updated successfully')
                getTermAndConditionMethod()
            })
            .catch((error) => {
                dispatch(loaderStatus(false));
                errorHandleService.onErrorHandle()
            });
    }

    function getTermAndConditionMethod() {
        dispatch(loaderStatus(true));
        GetTermAndCondition().then(res => {
            dispatch(loaderStatus(false));
            if (res.data.result) {
                let value = res?.data?.data?.termsAndCondition
                if (value) {
                    setval(res?.data?.data?.termsAndCondition)
                    checkUpdateSet(false)
                } else {
                    setval("")
                    checkUpdateSet(false)
                }
                checkUpdateSet(false)
            }
        }).catch(err => {
            dispatch(loaderStatus(false));
            errorHandleService.onErrorHandle()
        })
    }

    // Get request to get all interest list -
    useEffect(() => {
        getTermAndConditionMethod()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <Grid container spacing={3} direction="row">
                <Grid item xs={7}>
                    <Box component="div" style={{fontWeight:700}} display="inline" mt={3} className="mainHeading">Term & condition</Box>
                </Grid>
                <Grid item xs={12} className="setPadding"><Divider /></Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <CKEditor editor={ClassicEditor} data={addData} onChange={handleEditor} />
                        </Table>
                    </TableContainer>
                    <Grid item xs={12} direction="row" className="textEnd">
                    {(() => {
                            if (checkpdate) {
                                return (
                                    <Button variant="outlined" color="primary"
                            style={{ marginTop: '20px' }}
                            onClick={() => submitForm()}
                            className='modalCommonButton'
                        >
                            Update
                        </Button>
                                )
                            } else {
                                return (
                                    <Button variant="outlined" color="primary"
                                        style={{ marginTop: '20px' }}
                                        className='buttonDisablethem'
                                    >
                                        Update
                                    </Button>
                                )
                            }
                        })()}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}



