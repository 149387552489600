import { LOADER_STATUS } from '../Actions/ActionTypes'

export const loaderStatus = (data) => {
    return {
        type: LOADER_STATUS,
        payload: data
    }
}


