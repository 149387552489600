import React from 'react'
import HightOrderCom from '../Hoc/HightOrderCom'
import Termsndconditions from './termandcondition'

export default function TermandconditionHoc(props) {
    return (
        <div>
            <HightOrderCom cmp={Termsndconditions}/>
        </div>
    )
}